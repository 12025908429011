import React from "react";
import { gotoDirections } from "../../common/Helpers";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import {
  DirectionsBike,
  DirectionsCar,
  DirectionsRun,
  DirectionsTransit,
} from "@mui/icons-material";

const BestRoute = ({
  open,
  toggleRoute,
  majorLocation,
  directions,
  latlng,
}) => {
  return (
    <Dialog
      open={open}
      onClose={toggleRoute}
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: "800px", // Custom max width
          borderRadius: "16px", // Optional: Customize corner radius
        },
      }}
    >
      <DialogContent>
        <div className="flex ">
          <div className="relative w-[30%] ">
            <img
              src={majorLocation?.image}
              alt="major"
              className="w-[200px] h-[200px] rounded-10"
            />
            <div className="absolute bottom-2 text-[#fff] text-[18px] z-20 w-full text-center">
              {majorLocation?.locationName}
            </div>
          </div>
          <div className="w-[70%]">
            <div className="flex justify-between">
              <div className="flex items-center">
                <DirectionsRun sx={{ fontSize: 20, color: "#0C5EC4" }} />{" "}
                <span className="font-sans font-light text-[14px]">
                  {directions?.WALKING?.routes?.[0]?.legs?.[0]?.duration?.text}
                </span>
              </div>
              <div className="flex items-center">
                <DirectionsCar sx={{ fontSize: 20, color: "#0C5EC4" }} />{" "}
                <span className="font-sans font-light text-[14px]">
                  {directions?.DRIVING?.routes?.[0]?.legs?.[0]?.duration?.text}
                </span>
              </div>
              <div className="flex items-center">
                <DirectionsTransit sx={{ fontSize: 20, color: "#0C5EC4" }} />{" "}
                <span className="font-sans font-light text-[14px]">
                  {directions?.TRANSIT?.routes?.[0]?.legs?.[0]?.duration?.text}
                </span>
              </div>
              <div className="flex items-center">
                <DirectionsBike sx={{ fontSize: 20, color: "#0C5EC4" }} />{" "}
                <span className="font-sans font-light text-[14px]">
                  {
                    directions?.BICYCLING?.routes?.[0]?.legs?.[0]?.duration
                      ?.text
                  }
                </span>
              </div>
            </div>
            <div>
              <div className="flex flex-col  mt-3 w-full ">
                {/* Top Section */}
                <div className="flex items-center space-x-4">
                  {/* Bus Icon */}
                  <div className="text-primary">
                    <DirectionsTransit className="text-4xl text-blue-600" />
                  </div>
                  {/* Timing */}
                  <div>
                    <Typography
                      variant="subtitle1"
                      className="font-bold text-gray-900"
                    >
                      {
                        directions?.TRANSIT?.routes?.[0]?.legs?.[0]
                          ?.departure_time?.text
                      }{" "}
                      —{" "}
                      {
                        directions?.TRANSIT?.routes?.[0]?.legs?.[0]
                          ?.arrival_time?.text
                      }
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="body2"
                      className="text-gray-500 font-light text-sm"
                    >
                      {
                        directions?.TRANSIT?.routes?.[0]?.legs?.[0]?.duration
                          ?.text
                      }
                    </Typography>
                  </div>
                </div>

                <Divider className="my-4" />

                {/* Route Details */}
                <div className="flex items-center space-x-2 text-sm">
                  {/* Walk */}
                  <div className="flex items-center space-x-1">
                    <DirectionsRun className="text-gray-500" />
                  </div>

                  {/* Arrow Icon */}
                  <span>›</span>

                  {/* Bus Station */}
                  <div className="px-2 py-1 bg-blue-100 text-blue-800 font-semibold rounded-md">
                    Az
                  </div>

                  {/* Arrow Icon */}
                  <span>›</span>

                  {/* Walk */}
                  <div className="flex items-center space-x-1">
                    <DirectionsRun className="text-gray-500" />
                  </div>
                </div>

                {/* Timing Details */}
                <div className="mt-3 text-gray-500 text-sm">
                  {directions?.TRANSIT?.routes?.[0]?.legs?.[0]?.steps?.length >
                    0 &&
                    directions?.TRANSIT?.routes?.[0]?.legs?.[0]?.steps.map(
                      (step) => (
                        <Typography variant="body2">
                          {step?.instructions} - {step.distance?.text} -{" "}
                          {step?.duration?.text}
                        </Typography>
                      )
                    )}
                </div>

                {/* Details Button */}
                <div className="mt-4">
                  <div
                    onClick={() =>
                      gotoDirections(
                        [majorLocation.latitude, majorLocation.longitude],
                        latlng
                      )
                    }
                    className="text-[#0C5EC4] text-sm font-medium cursor-pointer "
                  >
                    Details
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleRoute} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BestRoute;

import React, { useEffect } from "react";
import ListingOverview from "./ListingOverview";
import MostPopularFacilities from "../../components/Listing/MosPopularFacilities";
import { useDispatch } from "react-redux";
import { getListingOverviewAction } from "../../store/Listing/listingAction";
import CustomChip from "../../components/CustomChip";
import AirbnbRatings from "../../components/Listing/AirbnbRating";
import ThingsToKnow from "../../components/Listing/ThingsToKnow";
import { cubeClrs } from "../../common/Helpers";

// Reusable Modal Component
const DetailContentAirbnb = ({
  property,
  majorLocation,
  myLocation,
  listingDetail,
  listingMedia,
  isFullScreen = false,
  toggleFullscreen,
  isMobile,
  toggleImageView,
  toggleTypeSwitch,
  activeType,
}) => {
  const { sections } = listingDetail ?? "";
  const dispatch = useDispatch();
  const listingOverview =
    sections?.descriptionDefault?.descriptionSummary?.htmlText;
  const highlightsChip = sections?.highlights?.highlights;

  useEffect(() => {
    if (
      property?.properties?.id !== listingOverview?.listingId &&
      (property?.properties?.accommodationType === "Hotel" ||
        property?.properties?.accommodationType === "Shortterm")
    ) {
      dispatch(
        getListingOverviewAction(
          {
            listingId: property?.properties?.id,
          },
          property?.properties?.accommodationType
        )
      );
    }
  }, []);
  return (
    <React.Fragment>
      <ListingOverview
        property={property}
        majorLocation={majorLocation}
        myLocation={myLocation}
        listingDetail={listingDetail}
        listingMedia={listingMedia}
        toggleFullscreen={toggleFullscreen}
        isFullScreen={isFullScreen}
        isMobile={isMobile}
        toggleImageView={toggleImageView}
        toggleTypeSwitch={toggleTypeSwitch}
        activeType={activeType}
      />
      <div className="flex items-center">
        <div className={`flex justify-center items-center flex-wrap w-full`}>
          <div
            className={`${
              isMobile ? "w-full flex-wrap" : "w-full "
            } flex gap-2 justify-center`}
          >
            {highlightsChip?.length > 0 &&
              highlightsChip.map((chip, i) => (
                <CustomChip
                  text={chip?.title}
                  chipBgClr={cubeClrs?.[i] || ""}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="mt-[34px]">
        <div className="mt-[20px]">
          <div
            className="!text-[14px] font-sans font-light"
            dangerouslySetInnerHTML={{
              __html: listingOverview || "",
            }}
            style={{ whiteSpace: "pre-wrap" }}
          ></div>
        </div>
      </div>
      <div className="mt-[34px]">
        <div className="text-[18px] font-sans font-medium text-[#063292]">
          Most popular facilities
        </div>
        <MostPopularFacilities
          listingDetail={{
            facility: listingDetail?.sections?.amenities?.seeAllAmenitiesGroups,
          }}
        />
      </div>
      <div>
        <AirbnbRatings listingDetail={listingDetail} isMobile={isMobile} />
      </div>
      <div>
        <ThingsToKnow sections={sections} isMobile={isMobile} />
      </div>
    </React.Fragment>
  );
};

export default DetailContentAirbnb;

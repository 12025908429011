import React, { useState, useEffect, useCallback } from "react";
import { useMapsLibrary, useMap } from "@vis.gl/react-google-maps";
import "./location-autocomplete.css";
import ClearIcon from "@mui/icons-material/Clear";

const LocationAutocomplete = ({
  onPlaceSelect,
  handleSearchChange,
  onSaveSearchLocation,
}) => {
  const map = useMap();
  const places = useMapsLibrary("places");
  const [sessionToken, setSessionToken] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPlaceSelected, setIsPlaceSelected] = useState(false);

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => {
      setAutocompleteService(null);
      setPlacesService(null);
    };
  }, [places, map]);

  const fetchPredictions = useCallback(
    (input) => {
      if (!autocompleteService || !input) return;

      setLoading(true);

      autocompleteService.getPlacePredictions(
        { input, sessionToken },
        (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPredictions(results || []);
          } else {
            setPredictions([]);
          }
          setLoading(false);
        }
      );
    },
    [autocompleteService, sessionToken]
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsPlaceSelected(false);
    if (handleSearchChange) {
      handleSearchChange(value);
    }
    if (value) {
      fetchPredictions(value);
    } else {
      setPredictions([]); // Clear predictions if input is empty
    }
  };

  const handleClear = () => {
    setInputValue("");
    setPredictions([]);
    setIsPlaceSelected(false);
    if (handleSearchChange) {
      handleSearchChange(""); // Optional: notify parent of empty search input
    }
  };

  const handleSelect = (prediction) => {
    if (!placesService) return;

    setLoading(true);

    const request = {
      placeId: prediction.place_id,
      fields: ["geometry", "name", "formatted_address", "types", "photos"],
      sessionToken,
    };

    placesService.getDetails(request, (place) => {
      if (place) {
        onPlaceSelect(place);
        setInputValue(place.formatted_address || "");
        setPredictions([]); // Clear predictions when a place is selected
        setIsPlaceSelected(true);
        setSessionToken(new places.AutocompleteSessionToken());
      }
      setLoading(false);
    });
  };

  return (
    <div className="location-autocomplete fancy-input-container w-full relative">
      <div className="input-wrapper">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Search for a place"
          className="fancy-location-input"
        />
        {inputValue && (
          <button className="clear-button" onClick={handleClear}>
            <ClearIcon className="text-gray-600" style={{ fontSize: "20px" }} />
          </button>
        )}
        <button className="search-button" onClick={onSaveSearchLocation}>
          Save
        </button>
      </div>
      {!isPlaceSelected && inputValue && predictions.length > 0 && (
        <ul className="autocomplete-suggestions absolute top-8 left-1">
          {predictions.map((prediction) => (
            <li
              key={prediction.place_id}
              onClick={() => handleSelect(prediction)}
              className="autocomplete-suggestion text-xs"
            >
              {prediction.description}
            </li>
          ))}
        </ul>
      )}
      {loading && <div className="loading-spinner">Loading...</div>}
    </div>
  );
};

export default LocationAutocomplete;

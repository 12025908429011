import React, { useEffect, useState } from "react";
import { UTurnLeft } from "@mui/icons-material";
import DetailContent from "../../views/Listings/DetailContent";
import DetailContentAirbnb from "../../views/Listings/DetailContentAirbnb";
import DetailContentIdealista from "../../views/Listings/DetailContentIdealista";
import ListingMyLocation from "../../views/Listings/ListingMyLocation";
import NeighborhoodTab from "../../views/Listings/NeighborhoodTab";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import BirdEyeTab from "../../views/Listings/BirdEyeTab";
import StreetTab from "../../views/Listings/StreetTab";
import PlacesTab from "../../views/Listings/PlacesTab";
import PlacesView from "../../views/Area/Places/PlacesView";
import MobileNeighborhoodTab from "../../views/Listings/MobileNeighborhoodTab";
import MobileListingMyLocation from "../../views/Listings/MobileListingMyLocation";
import { listType } from "../../common/Helpers";

const MobileDetailTabs = (props) => {
  const { onClose, property, isMobile, isImageView } = props;
  const [selectedTab, setSelectedTab] = useState(1);
  const { neighborhood, fetchNeighborhood } = useGooglePlaces();
  const geometry = property?.geometry;

  useEffect(() => {
    fetchNeighborhood(geometry?.coordinates?.[0], geometry?.coordinates?.[1]);
  }, [geometry]);
  const handleChange = (event, newValue) => {
    if (newValue === 0 && !isMobile) {
      onClose();
    }
    setSelectedTab(newValue);
  };

  const tabData = [
    { id: "back", label: "" },
    { id: "overview", label: "Overview" },
    { id: "myLocation", label: "My locations" },
    { id: "neighbr", label: "The neighborhood" },
    { id: "places", label: "Places 360" },
    { id: "bird", label: "Bird’s eye" },
    { id: "street", label: "Walk in the streets" },
  ];
  console.log("detailTabssss", property);
  return (
    <div className="w-full h-full relative">
      <div
        style={{
          background: isMobile
            ? listType?.[
                property?.properties?.accommodationType ||
                  property?.properties?.propertyType
              ]?.color
            : "",
        }}
      >
        <div
          onClick={onClose}
          className="flex cursor-pointer gap-1 text-[15px] text-[#fff] items-center p-2"
        >
          <UTurnLeft className="rotate-90" sx={{ fontSize: "20px" }} />
        </div>
        <div
          className={` w-full flex items-center gap-4  !shadow-lg overflow-x-auto hide-scrollbar text-[#fff] ${
            isMobile ? "" : "bg-gradient-to-r from-[#1B3FFC] to-[#1BD8FE]"
          }`} // Tailwind styling for bottom border
        >
          {tabData.map((tab, index) => (
            <div
              key={tab.id}
              onClick={() => handleChange(null, index)}
              style={{
                borderBottom: selectedTab === index ? "3px solid #fff" : "",
              }}
              className={`px-2 flex items-center justify-center whitespace-nowrap ${
                isMobile ? "!text-[14px]" : "!text-[18px]"
              } !normal-case !font-medium pb-2 !text-[#fff] `}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>
      {isImageView ? (
        <div className="flex justify-center">
          <div className={"w-[95%] h-[300px] mt-2 relative"}>
            <div
              onClick={props.toggleImageView}
              className="absolute top-1 left-0 bg-[#000]/20"
            >
              <UTurnLeft className="rotate-90 text-[#fff]" />
            </div>
            <div
              className="w-full flex flex-col gap-[6px] overflow-y-auto hide-scrollbar"
              style={{ height: "calc(100vh - 160px)" }}
            >
              {props.listingMedia?.length > 0 &&
                props.listingMedia.map((image, index) => (
                  <div key={index} className="cursor-pointer  rounded-[4px]">
                    <img
                      src={image?.url_1440 || image?.url || image?.baseUrl}
                      alt={`Thumbnail ${index + 1}`}
                      className="w-full h-[300px]  object-cover rounded-lg"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center">
          <div
            className={
              tabData[selectedTab]?.id === "overview"
                ? "flex justify-center py-4 overflow-y-auto hide-scrollbar h-full w-full"
                : "flex justify-center h-[82%] w-full"
            }
            style={{
              height:
                tabData[selectedTab]?.id === "overview"
                  ? `calc(100vh - 170px)`
                  : "h-full",
            }}
          >
            <div
              className={
                tabData[selectedTab]?.id === "overview"
                  ? isMobile
                    ? "w-[95%]"
                    : "w-[70%]"
                  : "w-full"
              }
            >
              {["rent", "buy"].includes(
                property?.properties?.listingKey ||
                  property?.properties?.accommodationType
              ) ? (
                <React.Fragment>
                  {tabData[selectedTab]?.id === "overview" && (
                    <DetailContentIdealista {...props} isMobile={isMobile} />
                  )}
                  {tabData[selectedTab]?.id === "myLocation" &&
                    (isMobile ? (
                      <MobileListingMyLocation cityCenter={props.cityCenter} />
                    ) : (
                      <ListingMyLocation isMobile={isMobile} />
                    ))}
                  {tabData[selectedTab]?.id === "neighbr" &&
                    (isMobile ? (
                      <MobileNeighborhoodTab placeName={neighborhood || ""} />
                    ) : (
                      <NeighborhoodTab placeName={neighborhood || ""} />
                    ))}
                  {tabData[selectedTab]?.id === "bird" && (
                    <BirdEyeTab isMobile={isMobile} activeItem={property} />
                  )}
                  {tabData[selectedTab]?.id === "street" && (
                    <StreetTab isMobile={isMobile} activeItem={property} />
                  )}
                  {tabData[selectedTab]?.id === "places" && (
                    <PlacesTab isMobile={isMobile} activeItem={property} />
                  )}
                </React.Fragment>
              ) : property?.properties?.listingKey === "monthlyStay" ||
                property?.properties?.accommodationType === "monthlyStay" ? (
                <React.Fragment>
                  {tabData[selectedTab]?.id === "overview" && (
                    <DetailContentAirbnb {...props} isMobile={isMobile} />
                  )}
                  {tabData[selectedTab]?.id === "myLocation" &&
                    (isMobile ? (
                      <MobileListingMyLocation cityCenter={props.cityCenter} />
                    ) : (
                      <ListingMyLocation isMobile={isMobile} />
                    ))}
                  {tabData[selectedTab]?.id === "neighbr" &&
                    (isMobile ? (
                      <MobileNeighborhoodTab placeName={neighborhood || ""} />
                    ) : (
                      <NeighborhoodTab placeName={neighborhood || ""} />
                    ))}
                  {tabData[selectedTab]?.id === "bird" && (
                    <BirdEyeTab isMobile={isMobile} activeItem={property} />
                  )}
                  {tabData[selectedTab]?.id === "street" && (
                    <StreetTab isMobile={isMobile} activeItem={property} />
                  )}
                  {tabData[selectedTab]?.id === "places" && (
                    <PlacesTab isMobile={isMobile} activeItem={property} />
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {tabData[selectedTab]?.id === "overview" && (
                    <DetailContent {...props} isMobile={isMobile} />
                  )}
                  {tabData[selectedTab]?.id === "myLocation" &&
                    (isMobile ? (
                      <MobileListingMyLocation cityCenter={props.cityCenter} />
                    ) : (
                      <ListingMyLocation isMobile={isMobile} />
                    ))}
                  {tabData[selectedTab]?.id === "neighbr" &&
                    (isMobile ? (
                      <MobileNeighborhoodTab placeName={neighborhood || ""} />
                    ) : (
                      <NeighborhoodTab placeName={neighborhood || ""} />
                    ))}
                  {tabData[selectedTab]?.id === "bird" && (
                    <BirdEyeTab isMobile={isMobile} activeItem={property} />
                  )}
                  {tabData[selectedTab]?.id === "street" && (
                    <StreetTab isMobile={isMobile} activeItem={property} />
                  )}
                  {tabData[selectedTab]?.id === "places" && (
                    // <PlacesTab activeItem={property} />
                    <PlacesView activeItem={property} isMobileScreen={false} />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileDetailTabs;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useMapsLibrary,
  Map,
  AdvancedMarker,
  useMap,
} from "@vis.gl/react-google-maps";
import PropTypes from "prop-types";
import {
  getCurrentUser,
  getDistanceFromLatLonInKm,
  mapTypeProperties,
  trimString,
} from "../../../common/Helpers";
import { getPlaceTypesAction } from "../../../store/User/userAction";
import { Home } from "@mui/icons-material";
import CustomMarker from "../../../components/CustomMarker";

const StarView = ({
  isMyLocation = false,
  typePlaces,
  cityCenter,
  handlePlaceClick,
}) => {
  const dispatch = useDispatch();
  const activeItem = useSelector(
    (state) => state.common?.commonStore?.activeList
  );
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const placeTypes = useSelector(
    (state) => state.user?.userStore?.userStarList
  );
  const [radius, setRadius] = useState(5);
  const [places, setPlaces] = useState([]);
  const [locations, setLocations] = useState(myLocation);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const currentUser = getCurrentUser();
  const mapCore = useMapsLibrary("core");
  const map = useMap();

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(getPlaceTypesAction(currentUser?.id));
    }
  }, []);

  useEffect(() => {
    setLocations(myLocation);
  }, [myLocation]);

  useEffect(() => {
    if (mapCore && map && window.google && activeItem) {
      if (window.google.maps && window.google.maps.places) {
        getPlaces();
      }
      const bounds = getCircleBounds(
        activeItem?.properties?.latitude,
        activeItem?.properties?.longitude,
        isMyLocation ? 30 : radius
      );
      if (bounds && !isMyLocation) {
        const filtered = myLocation.filter((loc) =>
          bounds.contains({ lat: loc.latitude, lng: loc.longitude })
        );

        setLocations(filtered);
        map && !isMyLocation && map.fitBounds(bounds);
      }
    }
  }, [mapCore, map, activeItem, radius, placeTypes]);

  async function getPlaces() {
    let types = isMyLocation ? typePlaces : placeTypes?.placeTypes;
    let result = [];
    const service = new window.google.maps.places.PlacesService(map);
    const center = {
      lat: activeItem?.properties?.latitude,
      lng: activeItem?.properties?.longitude,
    };
    if (types?.length > 0) {
      const placePromises = types.map((type) => {
        return new Promise((resolve, reject) => {
          service.nearbySearch(
            {
              location: center,
              radius: radius * 1000,
              type: type?.type,
            },
            (results, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const place = getClosestPlace(results);
                resolve({ ...place, placeType: type?.type });
              } else {
                resolve(null);
              }
            }
          );
        });
      });
      result = await Promise.all(placePromises);
      result = result.filter((place) => place !== null);
    }
    setPlaces(result);
  }

  function getClosestPlace(places) {
    if (!places || places.length === 0) return null;

    let closestPlace = null;
    let minDistance = Infinity;

    places.forEach((place) => {
      const distance = getDistanceFromLatLonInKm(
        activeItem?.properties?.latitude,
        activeItem?.properties?.longitude,
        place?.geometry?.location?.lat(),
        place?.geometry?.location?.lng()
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestPlace = place;
      }
    });

    return closestPlace;
  }

  function getCircleBounds(centerLat, centerLng, radiusInKm) {
    if (!mapCore || !window.google || !window.google.maps) {
      return null;
    }
    const EARTH_RADIUS = 6371; // Earth's radius in kilometers

    // Helper function to convert degrees to radians
    function toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    // Helper function to convert radians to degrees
    function toDegrees(radians) {
      return radians * (180 / Math.PI);
    }

    // Latitude boundaries
    const latRadius = radiusInKm / EARTH_RADIUS;
    const minLat = centerLat - toDegrees(latRadius);
    const maxLat = centerLat + toDegrees(latRadius);

    // Longitude boundaries
    const lngRadius =
      radiusInKm / (EARTH_RADIUS * Math.cos(toRadians(centerLat)));
    const minLng = centerLng - toDegrees(lngRadius);
    const maxLng = centerLng + toDegrees(lngRadius);

    // Create bounds object (using Google Maps LatLngBounds)

    if (isNaN(minLat) || isNaN(maxLat) || isNaN(minLng) || isNaN(maxLng))
      return null;

    const bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(minLat, minLng), // Southwest corner
      new window.google.maps.LatLng(maxLat, maxLng) // Northeast corner
    );
    return bounds;
  }

  return (
    <div className="w-full h-full rounded-lg z-10">
      {mapCore && activeItem && (
        <Map
          disableDefaultUI={true}
          fullscreenControl={false}
          defaultZoom={12}
          zoomControlOptions={null}
          zoomControl={true}
          mapId={"map"}
          defaultCenter={{
            lat: activeItem?.properties?.latitude,
            lng: activeItem?.properties?.longitude,
          }}
          streetViewControl={false}
          className="h-full"
        >
          {(locations || []).length &&
            locations.map((item, index) => (
              <AdvancedMarker
                key={item._id}
                position={{ lat: item.latitude, lng: item.longitude }}
              >
                {/* Perfect heart shape using Tailwind */}
                <div className="relative flex items-center justify-center">
                  <div className="relative w-10 h-10">
                    {/* Top left circle */}
                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-5 h-5 bg-red-500 rounded-full"></div>
                    {/* Top right circle */}
                    <div className="absolute top-0 right-1/2 transform translate-x-1/2 w-5 h-5 bg-red-500 rounded-full"></div>
                    {/* Bottom triangle */}
                    <div className="absolute top-1/4 left-0 w-10 h-10 bg-red-500 rotate-45 transform origin-bottom-left"></div>
                  </div>
                  {/* Image overlay inside the heart */}
                  <img
                    className="absolute w-10 h-10 rounded-md object-cover"
                    src={item.image}
                    alt="location"
                  />
                </div>

                {/* Distance display */}
                <div className="ml-[2px] w-[92%] text-[10px] flex flex-col items-center font-bold text-[#fff] absolute bottom-[10px] rounded-bl-full rounded-br-full left-[0px] bg-[#000]">
                  {getDistanceFromLatLonInKm(
                    item?.latitude,
                    item?.longitude,
                    activeItem?.properties?.latitude,
                    activeItem?.properties?.longitude
                  ).toFixed(1)}
                  km
                </div>
                {/* Location name */}
                <div className=" text-[10px] font-bold text-[#fff] bg-[#000] text-center rounded-10 p-1">
                  {trimString(item.locationName, 10)}
                </div>
              </AdvancedMarker>
            ))}

          {places.map((place, index) => (
            <AdvancedMarker
              key={`${place?.name}${index}`}
              position={
                place?.geometry?.location || {
                  lat: place?.latitude,
                  lng: place?.longitude,
                }
              }
              title={place.name}
              onClick={() => handlePlaceClick(place)}
            >
              <CustomMarker
                bgcolr={mapTypeProperties(place?.placeType)?.color}
                label={`${
                  mapTypeProperties(place?.placeType)?.text
                }: ${getDistanceFromLatLonInKm(
                  activeItem?.properties?.latitude,
                  activeItem?.properties?.longitude,
                  place?.geometry?.location?.lat() || place?.latitude,
                  place?.geometry?.location?.lng() || place?.longitude
                ).toFixed(1)} km`}
              />
            </AdvancedMarker>
          ))}
          {isMyLocation && cityCenter && (
            <AdvancedMarker
              position={{
                lat: cityCenter?.lat,
                lng: cityCenter?.lng,
              }}
            >
              <CustomMarker
                bgcolr={"#FF82C9"}
                label={`City center: ${getDistanceFromLatLonInKm(
                  activeItem?.properties?.latitude,
                  activeItem?.properties?.longitude,
                  cityCenter?.lat,
                  cityCenter?.lng
                ).toFixed(1)} km`}
                icon={<Home fontSize="small" className="text-[#fff]" />}
              />
            </AdvancedMarker>
          )}
          {activeItem && (
            <AdvancedMarker
              position={{
                lat: activeItem?.properties?.latitude,
                lng: activeItem?.properties?.longitude,
              }}
            >
              <CustomMarker
                bgcolr={"#E71919"}
                label={"Property is here"}
                icon={<Home fontSize="small" className="text-[#fff]" />}
              />
            </AdvancedMarker>
          )}
        </Map>
      )}
    </div>
  );
};

StarView.propTypes = {
  isMobileScreen: PropTypes.bool,
};

export default StarView;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useMapsLibrary,
  Map,
  AdvancedMarker,
  useMap,
} from "@vis.gl/react-google-maps";
import { getDistanceFromLatLonInKm } from "../../common/Helpers";
import MapAutoSearch from "../../components/Search/MapAutoSearch";
import MylocationList from "../../components/Listing/MylocationList";
import AddLocationModal from "../../components/Listing/AddLocationModal";
import PlacesListView from "../../components/Listing/PlaceListView";
import { Home } from "@mui/icons-material";
import StarView from "../Area/StarView/StarView";
import MobileMyLocationList from "../../components/Listing/MobileMyLocationList";
import MyLocationDetail from "./MyLocationDetail";
import useGooglePlaces from "../../hooks/useGooglePlaces";

const MobileListingMyLocation = ({ isMobile = false, cityCenter }) => {
  const { fetchDirections, directions } = useGooglePlaces();
  const activeItem = useSelector(
    (state) => state.common?.commonStore?.activeList
  );
  const placeTypes = useSelector(
    (state) => state.user?.userStore?.userStarList
  );
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const [radius, setRadius] = useState(0);
  const [places, setPlaces] = useState([]);
  const [defaultRadius, setDefaultRadius] = useState(1.6); // Default value of 3 km
  const [locations, setLocations] = useState(myLocation);
  const [selectedListLocation, setSelectedListLocation] = useState(null);
  const [searchLocation, setSearchLocation] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [isOpenAddModal, setToggleAddModal] = useState(false);
  const mapCore = useMapsLibrary("core");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [activePlace, setActivePlace] = useState(null);
  const map = useMap();

  useEffect(() => {
    if (!directions) {
      fetchDirections(
        {
          lat: activeItem?.properties?.latitude,
          lng: activeItem?.properties?.longitude,
        },
        {
          lat: selectedListLocation?.latitude,
          lng: selectedListLocation?.longitude,
        }
      );
    }
  }, [selectedListLocation]);

  function getCircleBounds(centerLat, centerLng, radiusInKm) {
    if (!mapCore || !window.google || !window.google.maps) {
      return null;
    }
    const EARTH_RADIUS = 6371;
    function toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    function toDegrees(radians) {
      return radians * (180 / Math.PI);
    }

    const latRadius = radiusInKm / EARTH_RADIUS;
    const minLat = centerLat - toDegrees(latRadius);
    const maxLat = centerLat + toDegrees(latRadius);
    const lngRadius =
      radiusInKm / (EARTH_RADIUS * Math.cos(toRadians(centerLat)));
    const minLng = centerLng - toDegrees(lngRadius);
    const maxLng = centerLng + toDegrees(lngRadius);
    if (isNaN(minLat) || isNaN(maxLat) || isNaN(minLng) || isNaN(maxLng))
      return null;
    const bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(minLat, minLng),
      new window.google.maps.LatLng(maxLat, maxLng)
    );
    return bounds;
  }

  useEffect(() => {
    if (mapCore && window.google) {
      if (window.google.maps && window.google.maps.places) {
        getPlaces();
      }
      const bounds = getCircleBounds(
        activeItem?.properties?.latitude,
        activeItem?.properties?.longitude,
        radius
      );
      if (bounds) {
        const filtered = myLocation.filter((loc) =>
          bounds.contains({ lat: loc.latitude, lng: loc.longitude })
        );

        setLocations(filtered);
        map && map.fitBounds(bounds);
      }
    }
  }, [radius]);

  useEffect(() => {
    setLocations(myLocation);
  }, [myLocation]);

  async function getPlaces() {
    let result = [];
    const service = new window.google.maps.places.PlacesService(map);
    const center = {
      lat: activeItem?.properties?.latitude,
      lng: activeItem?.properties?.longitude,
    };
    if (placeTypes?.placeTypes?.length > 0) {
      const placePromises = placeTypes.placeTypes.map((type) => {
        return new Promise((resolve, reject) => {
          service.nearbySearch(
            {
              location: center,
              radius: (radius || defaultRadius) * 1000,
              type: type?.type,
            },
            (results, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const place = getClosestPlace(results);
                resolve({ ...place, placeType: type?.type });
              } else {
                resolve(null);
              }
            }
          );
        });
      });
      result = await Promise.all(placePromises);
      result = result.filter((place) => place !== null);
    }
    setPlaces(result);
  }

  function getClosestPlace(places) {
    if (!places || places.length === 0) return null;

    let closestPlace = null;
    let minDistance = Infinity;

    places.forEach((place) => {
      const distance = getDistanceFromLatLonInKm(
        activeItem?.properties?.latitude,
        activeItem?.properties?.longitude,
        place?.geometry?.location?.lat(),
        place?.geometry?.location?.lng()
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestPlace = place;
      }
    });

    return closestPlace;
  }

  const toggleMyLocation = (location) => {
    if (!location) {
      setSelectedListLocation(location);
      return;
    }
    if (!mapCore || !window.google) {
      return;
    }

    const dis = getDistanceFromLatLonInKm(
      activeItem?.properties?.latitude,
      activeItem?.properties?.longitude,
      location.latitude,
      location.longitude
    );
    setRadius(dis.toFixed(1));
    setDefaultRadius(dis.toFixed(1));
    setSelectedListLocation(location);
  };

  function handleSearchLocation(value) {
    setSearchLocation(value);
  }

  function onSearchChange(value) {
    setSearchValue(value);
  }

  function onPlaceClick(value) {
    setActivePlace(value);
  }

  const handleMarkerClick = (location) => {
    if (location) {
      setSelectedLocation({
        ...location,
        latitude: location?.latitude || location?.geometry?.location.lat(),
        longitude: location?.longitude || location?.geometry?.location.lng(),
        image: location.image || location?.photos?.[0]?.getUrl(),
      });
    } else {
      setSelectedLocation(location);
    }
  };
  const hotelPlaceTypes = [
    { type: "subway_station", color: "#9A0909", text: "Metro" },
  ];
  const rentBuyPlaceTypes = [
    { type: "gym", color: "#CE5B08", text: "Gym" },
    { type: "subway_station", color: "#9A0909", text: "Metro" },
    { type: "bank", color: "#42BD27", text: "Bank" },
    { type: "school", color: "#FFC05A", text: "School" },
    { type: "beach", color: "#1FFFEC", text: "Beach" },
  ];

  const typePlaces = ["rent", "buy"].includes(
    activeItem?.properties?.accommodationType
  )
    ? rentBuyPlaceTypes
    : hotelPlaceTypes;

  return (
    <div className="flex w-full h-full relative">
      {selectedListLocation && (
        <div className="w-full flex justify-center">
          <MyLocationDetail
            returnBack={toggleMyLocation}
            directions={directions}
            selectedListLocation={selectedListLocation}
            distance={getDistanceFromLatLonInKm(
              activeItem?.properties?.latitude,
              activeItem?.properties?.longitude,
              selectedListLocation?.latitude,
              selectedListLocation?.longitude
            ).toFixed(1)}
            activeList={activeItem}
          />
        </div>
      )}
      {selectedLocation && (
        <div className="flex justify-center w-full">
          <div className="w-[98%]">
            <PlacesListView
              isMobile={true}
              isMyLocation={true}
              place={selectedLocation}
              handleMarkerClick={handleMarkerClick}
              distance={getDistanceFromLatLonInKm(
                activeItem?.properties?.latitude,
                activeItem?.properties?.longitude,
                selectedLocation?.latitude,
                selectedLocation?.longitude
              ).toFixed(1)}
            />
          </div>
        </div>
      )}{" "}
      {!selectedListLocation && !selectedLocation && (
        <React.Fragment>
          <div className="w-full absolute bottom-[-40px] z-[200]">
            <MobileMyLocationList
              myLocation={myLocation}
              toggleLocation={toggleMyLocation}
              setToggleAddModal={setToggleAddModal}
              isOpenAddModal={isOpenAddModal}
            />
          </div>
          <div
            className={`flex justify-center items-center  bg-secondary-l0 ${
              selectedLocation ? "w-[60%]" : "w-[100%]"
            } h-full rounded-t-lg z-10 relative`}
          >
            <div className="w-[80%] left-[10%] absolute z-30 top-[30px]">
              <MapAutoSearch
                onHandleChange={onSearchChange}
                value={searchValue}
                map={map}
                onSelectValue={handleSearchLocation}
              />
            </div>
            <StarView
              cityCenter={cityCenter}
              isMyLocation={true}
              typePlaces={typePlaces}
              handlePlaceClick={handleMarkerClick}
            />
            {isOpenAddModal && (
              <AddLocationModal
                map={map}
                open={isOpenAddModal}
                handleClose={() => setToggleAddModal(!isOpenAddModal)}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
export default MobileListingMyLocation;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePosition from "../../hooks/usePosition";
import { setCoordinates } from "../../store/Filter/filterReducers";
import LMMap from "../../components/LeftCol/Map/Map";
import useFilter from "../../hooks/useFilter";
import {
  setActiveStepAction,
  setCenterAction,
} from "../../store/Map/mapActions";
import useSupabase from "../../hooks/useSupabase";
import { getCurrentUser } from "../../common/Helpers";
import {
  getBlackList,
  getUserLocationsAction,
} from "../../store/User/userAction";
import AccommodationFilter from "../../components/LeftCol/AccommodationFilter";
import ListingsView from "../Listings/ListingsView";
import ListingMenu from "../../components/ListingMenu/ListingMenu";
import CustomDrawer from "../../components/CustomDrawer";
import { toggleDrawerAction } from "../../store/Common/commonActions";
import ListingDetailView from "../Listings/ListingDetailView";
import useListing from "../../hooks/useListing";
import ListingSort from "../../components/Sort/ListingSort";
import SearchMode from "../../components/SearchMode";

function ListingScreen() {
  const { showDetail } = useListing();
  const [activeList, setActiveList] = useState(null);
  const [searchMode, setSearchMode] = useState(1);
  const isDrawerOpen = useSelector(
    (state) => state.common?.isDrawerOpen || false
  );
  const { fetchUser } = useSupabase();
  const [isOpenDetailView, setDetailView] = useState(false);
  const currentUser = getCurrentUser();
  const dispatch = useDispatch();
  useFilter();

  const { latitude, longitude } = usePosition(false, {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 600000,
  });

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(getBlackList(currentUser?.id));
      dispatch(getUserLocationsAction(currentUser?.id));
    }
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
      dispatch(setCoordinates({ lat: latitude, lng: longitude }));
      setCenterAction(dispatch, { lat: latitude, lng: longitude });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (!currentUser) {
      fetchUser();
    }
  }, []);

  function toggleDetailView(selectedList = null) {
    if (selectedList) {
      setActiveStepAction(dispatch, selectedList?.id);
      showDetail(selectedList);
    }
    setDetailView(!isOpenDetailView);
  }

  function toggleDrawer() {
    dispatch(toggleDrawerAction());
  }
  function switchSearchMode(id) {
    setSearchMode(id);
  }

  return (
    <React.Fragment>
      <div className="bg-[#fff] w-full h-full ">
        <ListingMenu searchMode={searchMode} isListingScreen={true} />
        <div className="flex h-screen bg-[#fff] w-full">
          <div className="flex flex-col w-[60%] h-full  bg-secondary-l0">
            <div className="flex justify-center items-center my-2 gap-2">
              <SearchMode
                searchMode={searchMode}
                switchSearchMode={switchSearchMode}
              />
              <ListingSort />
            </div>
            <ListingsView
              toggleAvailabilityView={toggleDetailView}
              isFreeSearch={searchMode === 1}
            />
          </div>
          <div className="flex flex-col w-[40%] h-[89%] gap-6 bg-secondary-l0">
            <LMMap isListingScreen={true} isShowRadius={searchMode === 2} />
          </div>
        </div>
      </div>
      <CustomDrawer open={isDrawerOpen} onClose={toggleDrawer}>
        <AccommodationFilter />
      </CustomDrawer>
      {isOpenDetailView && (
        <ListingDetailView
          open={isOpenDetailView}
          onClose={toggleDetailView}
          activeList={activeList}
        />
      )}
    </React.Fragment>
  );
}

export default ListingScreen;

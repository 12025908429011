import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePosition from "../../hooks/usePosition";
import { setCoordinates } from "../../store/Filter/filterReducers";
import LMMap from "../../components/LeftCol/Map/Map";
import useFilter from "../../hooks/useFilter";
import {
  setActiveStepAction,
  setCenterAction,
} from "../../store/Map/mapActions";
import useSupabase from "../../hooks/useSupabase";
import { getCurrentUser } from "../../common/Helpers";
import {
  getBlackList,
  getUserLocationsAction,
} from "../../store/User/userAction";
import AccommodationFilter from "../../components/LeftCol/AccommodationFilter";
import CustomDrawer from "../../components/CustomDrawer";
import { toggleDrawerAction } from "../../store/Common/commonActions";
import MapSearch from "../../components/LeftCol/MapSearch";
import ListingDetailView from "../Listings/ListingDetailView";
import useListing from "../../hooks/useListing";
import MobileListingsView from "../Listings/MobileListingView";
import MobileListingMenu from "../../components/ListingMenu/MobileListingMenu";
import { PinDrop, Search } from "@mui/icons-material";

function MobileListingScreen() {
  const { showDetail } = useListing();
  const isMapView = useSelector((state) => state.common?.isMapView);
  const [activeList, setActiveList] = useState(null);
  const [searchMode, setSearchMode] = useState(1);
  const isDrawerOpen = useSelector(
    (state) => state.common?.isDrawerOpen || false
  );
  const { fetchUser } = useSupabase();
  const [isOpenDetailView, setDetailView] = useState(false);
  const currentUser = getCurrentUser();
  const dispatch = useDispatch();
  useFilter();

  const { latitude, longitude } = usePosition(false, {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 600000,
  });

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(getBlackList(currentUser?.id));
      dispatch(getUserLocationsAction(currentUser?.id));
    }
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
      dispatch(setCoordinates({ lat: latitude, lng: longitude }));
      setCenterAction(dispatch, { lat: latitude, lng: longitude });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (!currentUser) {
      fetchUser();
    }
  }, []);

  function toggleDetailView(selectedList = null) {
    if (selectedList) {
      setActiveStepAction(dispatch, selectedList?.id);
      showDetail(selectedList);
    }
    setDetailView(!isOpenDetailView);
  }

  function toggleDrawer() {
    dispatch(toggleDrawerAction());
  }

  function switchSearchMode(id) {
    setSearchMode(id);
  }

  const menuItems = [
    {
      id: 1,
      label: "Free search",
      icon: <Search sx={{ fontSize: 18 }} />,
    },
    {
      id: 2,
      label: "By radius",
      icon: <PinDrop sx={{ fontSize: 18 }} />,
    },
  ];

  return (
    <React.Fragment>
      <div className="bg-[#fff] w-full h-full flex flex-row ">
        <div className="flex h-screen bg-[#fff] w-full">
          <div className="flex flex-col w-full h-full  bg-secondary-l0">
            <div className="w-full">
              <MobileListingMenu
                searchMode={searchMode}
                isMapView={isMapView}
              />
            </div>
            <div className="flex justify-center">
              <div className="W-full flex bg-[#fff]  border border-solid border-[#CBB9B9] shadow-2xl rounded-full mb-3">
                {menuItems.map((item) => (
                  <div
                    onClick={() => switchSearchMode(item.id)}
                    key={item.id}
                    style={{
                      background: searchMode === item.id ? "#2A3CE2" : "#fff",
                      color: searchMode === item.id ? "#fff" : "#000",
                    }}
                    className={`flex gap-1 items-center cursor-pointer font-sans text-[16px] font-light py-[6px] px-[20px] rounded-full ${
                      searchMode === item.id
                        ? "shadow-lg border border-solid border-[#FFF0F0]"
                        : ""
                    }`}
                  >
                    <div className="flex items-center">{item.icon}</div>
                    <div>{item.label}</div>
                  </div>
                ))}
              </div>
            </div>
            {isMapView ? (
              <div className="flex flex-col w-full h-full gap-6 bg-secondary-l0 relative ">
                <div className="absolute left-4 top-[15px] z-50 w-full">
                  <div className="w-full max-w-[400px]">
                    <MapSearch
                      onPlaceChanged={() => console.log("Place changed")}
                    />
                  </div>
                </div>
                <LMMap
                  isListingScreen={true}
                  isShowRadius={searchMode === 2}
                  isMobile={true}
                />
              </div>
            ) : (
              <MobileListingsView toggleAvailabilityView={toggleDetailView} />
            )}
          </div>
        </div>
      </div>
      <CustomDrawer open={isDrawerOpen} onClose={toggleDrawer} isMobile={true}>
        <AccommodationFilter />
      </CustomDrawer>
      {isOpenDetailView && (
        <ListingDetailView
          open={isOpenDetailView}
          onClose={toggleDetailView}
          activeList={activeList}
          isMobile={true}
        />
      )}
    </React.Fragment>
  );
}

export default MobileListingScreen;

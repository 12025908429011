import React, { useEffect, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Bedtime, CalendarMonth, Hotel } from "@mui/icons-material";
import keysIcon from "../../assets/icons/svg/keys.svg";
import { useSelector } from "react-redux";
import ListingRentBuyItem from "./ListingRentBuyItem";
import ListingHotelItem from "./ListingHotelItem";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import ListingAirbnbItem from "./ListingAirbnbItem";

const ListingsView = ({ toggleAvailabilityView, isFreeSearch }) => {
  const {
    cityCenter,
    fetchCityCenter,
    fetchPlaces,
    places: metroStation,
  } = useGooglePlaces();
  const [propertyList, setProperty] = useState(null);
  const filterState = useSelector((state) => state.filter);
  const radiusBounds = useSelector((state) => state.map.radiusBounds);
  const listings = useSelector((state) => state.hotels?.hotels);
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const majorLocation =
    myLocation?.length > 0
      ? myLocation.filter((loc) => loc.isMajor)?.[0]
      : null;

  useEffect(() => {
    if (!isFreeSearch && radiusBounds) {
      setProperty(filterBaseOnBounds());
    } else {
      setProperty(listings);
    }
  }, [radiusBounds, isFreeSearch, listings]);
  useEffect(() => {
    if (filterState?.searchText?.text) {
      fetchCityCenter(filterState?.searchText?.text);
    }
    if (filterState?.latitude && filterState?.longitude) {
      fetchPlaces(
        "",
        { lat: filterState?.latitude, lng: filterState?.longitude },
        50,
        "subway_station"
      );
    }
  }, [filterState?.searchText, filterState?.latitude]);

  function filterBaseOnBounds() {
    let updatedListing = [];
    updatedListing = (listings.features || []).filter((feature) => {
      const [lat, lng] = feature.geometry.coordinates;
      const position = new window.google.maps.LatLng(lat, lng);
      return radiusBounds.contains(position); // Filter based on bounds
    });
    return { features: updatedListing };
  }

  const typeMapData = {
    hotels: {
      text: "Per night",
      icon: (
        <Hotel
          sx={{
            color: "#1D29D9",
            fontSize: 20,
          }}
        />
      ),
      color: "#C3EAFF",
    },
    Hotel: {
      text: "Per night",
      icon: (
        <Hotel
          sx={{
            color: "#1D29D9",
            fontSize: 20,
          }}
        />
      ),
      color: "#C3EAFF",
    },
    monthlyStay: {
      text: "Short term",
      icon: (
        <Bedtime
          sx={{
            color: "#1D29D9",
            fontSize: 20,
          }}
        />
      ),
      color: "#FCBBBD",
    },
    rent: {
      text: "Rent",
      icon: (
        <CalendarMonth
          sx={{
            color: "#1D29D9",
            fontSize: 20,
          }}
        />
      ),
      color: "#FCC671",
    },
    buy: {
      text: "Buy",
      icon: <img src={keysIcon} alt="key" />,
      color: "#DBFFD5",
    },
  };
  console.log("propertylistttt", propertyList);
  return (
    <div className="flex-1 overflow-y-auto  mb-[5rem]">
      {propertyList?.features?.length > 0 &&
        propertyList.features.map((list, index) => (
          <div
            key={`${list?.id}${index}`}
            className="flex bg-[#fff] mb-2 rounded-lg shadow-lg border border-solid border-[#E7E7E7] overflow-hidden"
          >
            {["rent", "buy"].includes(list?.properties?.listingKey) ? (
              <ListingRentBuyItem
                toggleAvailabilityView={toggleAvailabilityView}
                property={list}
                typeMapData={typeMapData}
                majorLocation={majorLocation}
                cityCenter={cityCenter}
                metroStation={metroStation}
              />
            ) : list?.properties?.listingKey === "monthlyStay" ? (
              <ListingAirbnbItem
                toggleAvailabilityView={toggleAvailabilityView}
                property={list}
                typeMapData={typeMapData}
                majorLocation={majorLocation}
                cityCenter={cityCenter}
                metroStation={metroStation}
              />
            ) : (
              <ListingHotelItem
                toggleAvailabilityView={toggleAvailabilityView}
                property={list}
                typeMapData={typeMapData}
                majorLocation={majorLocation}
                cityCenter={cityCenter}
                metroStation={metroStation}
              />
            )}
          </div>
        ))}
    </div>
  );
};
export default ListingsView;

import React from "react";
import { Button } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionIcon from "@mui/icons-material/Directions";
import IconCircular from "../../components/IconCircular/IconCircular";
import { getDistanceFromLatLonInKm, listType } from "../../common/Helpers";
import CustomChip from "../CustomChip";
import glassIcon from "../../assets/icons/svg/360-icon.svg";
import { Room } from "@mui/icons-material";

const IdealistaInfo = ({
  property,
  majorLocation,
  listingDetail,
  isMobile,
}) => {
  const { propertyName, detailedType, address } = property?.properties || "";
  const { priceInfo } = listingDetail || "";
  const { constructedArea, floor, lift } =
    listingDetail?.moreCharacteristics || "";

  return (
    <div className="w-full">
      {isMobile ? (
        <div>
          <div className="flex justify-between mt-3">
            <div className="text-[#0C3897] text-[18px] font-medium">
              {propertyName}
            </div>
            <div>
              <div className="text-[18px] font-bold text-[#0C6AD6] font-sans">
                {Math.round(priceInfo?.amount)} {priceInfo?.currencySuffix}
              </div>
              <div className="text-[18px] font-sans text-[#0C3897]">
                For rent
              </div>
            </div>
          </div>
          <div className="flex justify-center gap-2 my-4">
            <CustomChip text={`${constructedArea} m²`} chipBgClr="#F0C0C0" />
            {detailedType?.subTypology && (
              <CustomChip
                text={detailedType?.subTypology}
                chipBgClr="#E4F2BD"
              />
            )}
            <CustomChip
              text={`${floor ? `${floor} floor` : ""} ${
                lift ? "with" : "without"
              } lift`}
              chipBgClr="#C1E1E5"
            />
          </div>
          <div className="flex justify-center items-center gap-0 text-[14px] font-light">
            <Room className="text-[#f00]" />
            <div className="text-[#0C6AD6]">{address}</div>
          </div>
          <div className="w-full h-[1px] bg-[#E3D7D7] my-[30px]"></div>
        </div>
      ) : (
        <React.Fragment>
          <div
            className={`flex justify-between items-center mt-3 ${
              isMobile ? "flex-wrap gap-2" : ""
            }`}
          >
            <CustomChip text={`${constructedArea} m²`} chipBgClr="#F0C0C0" />
            {detailedType?.subTypology && (
              <CustomChip
                text={detailedType?.subTypology}
                chipBgClr="#E4F2BD"
              />
            )}
            <CustomChip
              text={`${floor ? `${floor} floor` : ""} ${
                lift ? "with" : "without"
              } lift`}
              chipBgClr="#C1E1E5"
            />
            {!isMobile && (
              <div className="text-[24px] font-bold text-[#0A2ECC] font-sans">
                {Math.round(priceInfo?.amount)} {priceInfo?.currencySuffix}
              </div>
            )}
          </div>
          <div className="flex justify-between mt-4">
            <div className="flex justify-center items-center gap-0 text-[14px] font-light">
              <Room className="text-[#f00]" />
              <div className="text-[#0C6AD6]">{address}</div>
            </div>
            <div className="text-[24px] font-sans text-[#0C3897]">For rent</div>
          </div>
          <div className="w-full h-[1px] bg-[#E3D7D7] my-[30px]"></div>
          <div className="text-[#0A56C6] text-[18px] font-medium mt-5">
            {propertyName}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
export default IdealistaInfo;

import React, { useEffect, useState } from "react";
import { styled, Slider, SliderThumb, Button } from "@mui/material";
import { useSelector } from "react-redux";
import {
  useMapsLibrary,
  Map,
  AdvancedMarker,
  useMap,
} from "@vis.gl/react-google-maps";
import PropTypes from "prop-types";
import IconCircular from "../../components/IconCircular/IconCircular";
import {
  getDistanceFromLatLonInKm,
  mapTypeProperties,
} from "../../common/Helpers";
import MapAutoSearch from "../../components/Search/MapAutoSearch";
import MylocationList from "../../components/Listing/MylocationList";
import AddLocationModal from "../../components/Listing/AddLocationModal";
import PlacesListView from "../../components/Listing/PlaceListView";
import { Home } from "@mui/icons-material";
import CustomMarker from "../../components/CustomMarker";
import MyLocationLeftSideInfo from "./MyLocationLeftSideInfo";

const StyledSlider = styled(Slider)({
  color: "#9FE330", // Track color (yellow)
  height: 8,
  "& .MuiSlider-thumb": {
    height: 24,
    width: 50,
    top: "15px",
    backgroundColor: "#29ABD4", // Blue background color for the thumb
    borderRadius: "18px", // Rounded capsule shape
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow effect
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "inherit",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#DFD3D3", // Light gray rail color
  },
});

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <div>
      {React.cloneElement(children, {
        style: {
          ...children.props.style,
          position: "relative",
        },
      })}
      <div
        className="text-primary"
        style={{
          position: "absolute",
          fontWeight: "bold",
          top: "-25px",
          right: 0,
          textShadow: "1px 1px 2px black",
          whiteSpace: "nowrap",
        }}
      >
        {`${value} km`}
      </div>
    </div>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

function SliderC({ valueChange, defaultRadius, activeItem }) {
  const [value, setValue] = useState(1.6); // Default value of 3 km

  useEffect(() => {
    setValue(defaultRadius);
  }, [defaultRadius]);

  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <div className="mt-2">
        <SliderThumb {...other} max={10} min={0}>
          {children}
          <span className="text-[#fff] text-[12px]">{value}km</span>
        </SliderThumb>
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSliderChangeCommited = (event, newValue) => {
    console.log(newValue);
    valueChange(newValue);
  };

  return (
    <div className="w-40 absolute z-10 top-[5%] left-[20%]">
      <div
        className={
          "max-w-10 max-h-10 w-10 h-10 mb-2 cursor-pointer z-20 shadow-lg rounded-full absolute"
        }
      >
        <IconCircular
          className={"max-w-10 max-h-10 w-10 h-10"}
          alt={"language"}
          key={"language"}
          src={activeItem?.thumbnail || "https://picsum.photos/200"}
        />
      </div>
      <StyledSlider
        className="top-2 left-14"
        value={value}
        onChange={handleChange}
        onChangeCommitted={onSliderChangeCommited}
        min={0}
        max={100}
        step={0.1}
        valueLabelDisplay="off" // Auto display the value inside the thumb
        slots={{
          thumb: AirbnbThumbComponent, // Custom value label component
        }}
      />
    </div>
  );
}

const ListingMyLocation = ({ isMobile = false }) => {
  const activeItem = useSelector(
    (state) => state.common?.commonStore?.activeList
  );
  const placeTypes = useSelector(
    (state) => state.user?.userStore?.userStarList
  );
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const [radius, setRadius] = useState(5);
  const [places, setPlaces] = useState([]);
  const [defaultRadius, setDefaultRadius] = useState(1.6); // Default value of 3 km
  const [locations, setLocations] = useState(myLocation);
  const [searchLocation, setSearchLocation] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [isOpenAddModal, setToggleAddModal] = useState(false);
  const mapCore = useMapsLibrary("core");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [activeLeftSideLocation, setActiveLeftSideLocation] = useState(null);
  const map = useMap();

  function getCircleBounds(centerLat, centerLng, radiusInKm) {
    if (!mapCore || !window.google || !window.google.maps) {
      return null;
    }
    const EARTH_RADIUS = 6371;
    function toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    function toDegrees(radians) {
      return radians * (180 / Math.PI);
    }

    const latRadius = radiusInKm / EARTH_RADIUS;
    const minLat = centerLat - toDegrees(latRadius);
    const maxLat = centerLat + toDegrees(latRadius);
    const lngRadius =
      radiusInKm / (EARTH_RADIUS * Math.cos(toRadians(centerLat)));
    const minLng = centerLng - toDegrees(lngRadius);
    const maxLng = centerLng + toDegrees(lngRadius);
    if (isNaN(minLat) || isNaN(maxLat) || isNaN(minLng) || isNaN(maxLng))
      return null;
    const bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(minLat, minLng),
      new window.google.maps.LatLng(maxLat, maxLng)
    );
    return bounds;
  }

  useEffect(() => {
    loadPlaces();
  }, []);

  useEffect(() => {
    setLocations(myLocation);
  }, [myLocation]);

  function loadPlaces() {
    if (
      map &&
      mapCore &&
      window.google &&
      activeItem &&
      activeItem?.properties
    ) {
      if (window.google.maps && window.google.maps.places) {
        getPlaces();
      }
      const bounds = getCircleBounds(
        activeItem?.properties?.latitude,
        activeItem?.properties?.longitude,
        radius
      );
      if (bounds) {
        const filtered = myLocation.filter((loc) =>
          bounds.contains({ lat: loc.latitude, lng: loc.longitude })
        );

        setLocations(filtered);
        map && map.fitBounds(bounds);
      }
    }
  }

  async function getPlaces() {
    let result = [];
    const service = new window.google.maps.places.PlacesService(map);
    const center = {
      lat: activeItem?.properties?.latitude,
      lng: activeItem?.properties?.longitude,
    };
    if (placeTypes?.placeTypes?.length > 0 && service) {
      const placePromises = placeTypes.placeTypes.map((type) => {
        return new Promise((resolve, reject) => {
          service.nearbySearch(
            {
              location: center,
              radius: (radius || defaultRadius) * 1000,
              type: type?.type,
            },
            (results, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const place = getClosestPlace(results);
                resolve({ ...place, placeType: type?.type });
              } else {
                resolve(null);
              }
            }
          );
        });
      });
      result = await Promise.all(placePromises);
      result = result.filter((place) => place !== null);
    }
    setPlaces(result);
  }

  function getClosestPlace(places) {
    if (!places || places.length === 0) return null;

    let closestPlace = null;
    let minDistance = Infinity;
    places.forEach((place) => {
      const distance = getDistanceFromLatLonInKm(
        activeItem?.properties?.latitude,
        activeItem?.properties?.longitude,
        place?.geometry?.location?.lat(),
        place?.geometry?.location?.lng()
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestPlace = place;
      }
    });

    return closestPlace;
  }

  const toggleMyLocation = (location) => {
    if (!mapCore || !window.google) {
      return;
    }

    const dis = getDistanceFromLatLonInKm(
      activeItem?.properties?.latitude,
      activeItem?.properties?.longitude,
      location.latitude,
      location.longitude
    );
    setRadius(dis.toFixed(1));
    setDefaultRadius(dis.toFixed(1));
  };

  function handleSearchLocation(value) {
    setSearchLocation(value);
  }

  function onSearchChange(value) {
    setSearchValue(value);
  }

  const handleMarkerClick = (location) => {
    if (location) {
      setSelectedLocation({
        ...location,
        latitude: location?.latitude || location?.geometry?.location.lat(),
        longitude: location?.longitude || location?.geometry?.location.lng(),
        image: location.image || location?.photos?.[0]?.getUrl(),
      });
    } else {
      setSelectedLocation(location);
    }
  };
  const handleLocationMarker = (location) => {
    setActiveLeftSideLocation(location);
  };

  return (
    <div className="flex w-full h-full">
      {selectedLocation && (
        <div className="w-[26%] bg-[#1C0A0A]">
          <PlacesListView
            place={selectedLocation}
            handleMarkerClick={handleMarkerClick}
            distance={getDistanceFromLatLonInKm(
              activeItem?.properties?.latitude,
              activeItem?.properties?.longitude,
              selectedLocation?.latitude,
              selectedLocation?.longitude
            ).toFixed(1)}
          />
        </div>
      )}
      {activeLeftSideLocation && (
        <div className="w-[26%] bg-[#fff]">
          <MyLocationLeftSideInfo
            location={activeLeftSideLocation}
            handleMarkerClick={handleLocationMarker}
            activeItem={activeItem}
            distance={getDistanceFromLatLonInKm(
              activeItem?.properties?.latitude,
              activeItem?.properties?.longitude,
              activeLeftSideLocation?.latitude,
              activeLeftSideLocation?.longitude
            ).toFixed(1)}
          />
        </div>
      )}
      <div
        className={`flex justify-center items-center  bg-secondary-l0 ${
          selectedLocation ? "w-[64%]" : "w-[90%]"
        } h-full rounded-t-lg z-10 relative`}
      >
        {/* <SliderC
        className={"absolute  left-[50%] z-30"}
        defaultRadius={defaultRadius}
        valueChange={(radius) => setRadius(radius)}
        activeItem={activeItem?.properties}
      /> */}

        <div className="w-[30%] left-[10%] absolute z-30 top-[30px]">
          <MapAutoSearch
            onHandleChange={onSearchChange}
            value={searchValue}
            map={map}
            onSelectValue={handleSearchLocation}
          />
        </div>
        {mapCore && activeItem && (
          <Map
            disableDefaultUI={true}
            fullscreenControl={false}
            defaultZoom={15}
            zoomControlOptions={null}
            zoomControl={true}
            mapId={"map"}
            defaultCenter={{
              lat: activeItem?.properties?.latitude,
              lng: activeItem?.properties?.longitude,
            }}
            streetViewControl={false}
            className="h-full w-full"
          >
            {activeItem?.properties && (
              <AdvancedMarker
                position={{
                  lat: activeItem?.properties?.latitude || 0,
                  lng: activeItem?.properties?.longitude || 0,
                }}
              >
                <div className="rounded-full p-2 bg-[#F37485]/50">
                  <Home
                    sx={{ fontSize: 50, color: "#FF0000" }}
                    className="w-12 h-12   cursor-pointer"
                  />
                </div>
              </AdvancedMarker>
            )}
            {searchLocation && (
              <AdvancedMarker
                key={"search"}
                position={{
                  lat: searchLocation.latitude,
                  lng: searchLocation.longitude,
                }}
                onClick={() => handleMarkerClick(searchLocation)}
              ></AdvancedMarker>
            )}
            {(locations || []).length &&
              locations.map((item, index) => (
                <AdvancedMarker
                  key={item._id}
                  position={{ lat: item.latitude, lng: item.longitude }}
                  onClick={() => handleLocationMarker(item)}
                >
                  <div className="flex  flex-col items-center w-14 h-14 rounded-full relative z-50">
                    <img
                      className="rounded-full"
                      width={"100%"}
                      height={"100%"}
                      src={item.image}
                      alt="locaton"
                    />
                  </div>
                  <div className="font-sans text-[14px] min-w-[110px] rounded-full py-[4px] pl-3 pr-[2px] text-[#0A237E] border border-solid border-[#C6D4FF] absolute top-[17px] left-[53px] bg-[#fff]">
                    {item?.locationName}{" "}
                    {getDistanceFromLatLonInKm(
                      item?.latitude,
                      item?.longitude,
                      activeItem?.properties?.latitude,
                      activeItem?.properties?.longitude
                    ).toFixed(1)}
                    km
                  </div>
                </AdvancedMarker>
              ))}
            {places?.length > 0 &&
              places.map((place, index) => (
                <AdvancedMarker
                  key={index}
                  position={
                    place?.geometry?.location || {
                      lat: place?.latitude,
                      lng: place?.longitude,
                    }
                  }
                  title={place.name}
                  onClick={() => handleMarkerClick(place)}
                >
                  <CustomMarker
                    bgcolr={mapTypeProperties(place?.placeType)?.color}
                    label={`${
                      mapTypeProperties(place?.placeType)?.text
                    }: ${getDistanceFromLatLonInKm(
                      activeItem?.properties?.latitude,
                      activeItem?.properties?.longitude,
                      place?.geometry?.location?.lat() || place?.latitude,
                      place?.geometry?.location?.lng() || place?.longitude
                    ).toFixed(1)} km`}
                  />
                </AdvancedMarker>
              ))}
            {isOpenAddModal && (
              <AddLocationModal
                map={map}
                open={isOpenAddModal}
                handleClose={() => setToggleAddModal(!isOpenAddModal)}
              />
            )}
          </Map>
        )}
      </div>
      <div className="w-[10%] h-full">
        <MylocationList
          isMobile={isMobile}
          myLocation={myLocation}
          toggleLocation={toggleMyLocation}
          setToggleAddModal={setToggleAddModal}
          isOpenAddModal={isOpenAddModal}
        />
      </div>
    </div>
  );
};
export default ListingMyLocation;

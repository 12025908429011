import React from "react";
import { Chat, PinDrop, Search } from "@mui/icons-material";

function SearchMode({ searchMode, switchSearchMode }) {
  const menuItems = [
    {
      id: 1,
      label: "Free search",
      icon: <Search sx={{ fontSize: 18 }} />,
    },
    {
      id: 2,
      label: "By radius",
      icon: <PinDrop sx={{ fontSize: 18 }} />,
    },
    {
      id: 3,
      label: "Chat",
      icon: <Chat sx={{ fontSize: 18 }} />,
    },
  ];

  return (
    <div className="W-[30%] relative flex items-center bg-[#fff] p-1  border border-solid border-[#D9D9D9] shadow-2xl rounded-[6px]">
      {menuItems.map((item, index) => (
        <React.Fragment>
          {index === 1 && (
            <div
              className={`w-[1px] h-[60%] bg-[#000] ${
                searchMode === 2 && "mr-[11px]"
              } ${searchMode === 1 && "ml-[11px]"}`}
            ></div>
          )}
          <div
            onClick={() => switchSearchMode(item.id)}
            key={item.id}
            style={{
              background: searchMode === item.id ? "#01A3FE" : "#fff",
              color: searchMode === item.id ? "#fff" : "#1A1E92",
            }}
            className={`flex gap-1 items-center cursor-pointer font-sans text-[16px] font-light py-[6px] px-[20px] rounded-full ${
              searchMode === item.id
                ? "shadow-lg border border-solid border-[#FFF0F0] !font-bold"
                : ""
            }`}
          >
            <div className="flex items-center">{item.icon}</div>
            <div>{item.label}</div>
          </div>
          {index === 1 && (
            <div
              className={`w-[1px] h-[60%] bg-[#000] ${
                searchMode === 2 && "ml-[11px]"
              } ${searchMode === 3 && "mr-[11px]"}`}
            ></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default SearchMode;

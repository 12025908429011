import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import metroIcon from "../../assets/icons/svg/metro-station.svg";
import DirectionIcon from "@mui/icons-material/Directions";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import IconCircular from "../../components/IconCircular/IconCircular";
import {
  getClosestMetroDistance,
  getDistanceFromLatLonInKm,
  parseReviewString,
  trimString,
} from "../../common/Helpers";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import dayjs from "dayjs";
import BestRoute from "./BestRoute";
import {
  Delete,
  Favorite,
  LocationOn,
  OpenInBrowser,
} from "@mui/icons-material";

const ListingAirbnbItem = ({
  property,
  typeMapData,
  majorLocation,
  cityCenter,
  toggleAvailabilityView,
  metroStation,
}) => {
  const { fetchDirections, directions } = useGooglePlaces();
  const [isOpenRoute, setRoute] = useState(false);
  const {
    thumbnail,
    propertyName,
    propertyClass,
    priceInfoContent,
    listingKey,
    review_score_word,
    price,
    listing,
    listingParamOverrides,
  } = property.properties;
  const geometry = property?.geometry || "";
  const { checkin, checkout } = listingParamOverrides || "";

  useEffect(() => {
    fetchDirections(
      { lat: geometry?.coordinates?.[0], lng: geometry?.coordinates?.[1] },
      { lat: majorLocation?.latitude, lng: majorLocation?.longitude }
    );
  }, []);

  function toggleRoute() {
    setRoute(!isOpenRoute);
  }

  const daysBetween =
    checkout && checkin ? dayjs(checkout).diff(dayjs(checkin), "day") : 0;
  const actualPrice = price ? price.replace(/\D/g, "") : price;
  const pricePerDay = Number(actualPrice) / daysBetween;
  const review = review_score_word ? parseReviewString(review_score_word) : "";

  return (
    <React.Fragment>
      {/* Details Section */}
      <div className=" flex  w-full relative mb-2">
        <div className="absolute top-3 left-3">
          <div className="absolute top-5 w-full flex justify-center">
            <div className="bg-[#fff] rounded-full py-1 px-2 text-[#0067E8] text-[12px] font-sans font-medium flex gap-1 items-center">
              <LocationOn />
              <div>Alfama, Lisbon, Portugal</div>
            </div>
          </div>
          <img
            src={thumbnail}
            alt={propertyName}
            className="rounded-lg h-[250px] w-[250px] max-w-[250px] max-h-[250px]"
          />
          <div className="flex justify-between items-center w-full absolute bottom-0 z-50 bg-[#fff] text-[#000] font-sans rounded-b-lg border border-solid border-[#B29D9D]">
            <div className="ml-2 text-[10px]">
              <Delete sx={{ fontSize: 18, color: "red" }} />
            </div>
            <div className="flex px-3 py-1 justify-center w-full items-center gap-1 ">
              <div>{typeMapData?.[listingKey]?.icon}</div>
              <div className="text-[18px]">
                {typeMapData?.[listingKey]?.text}
              </div>
            </div>
            <div className="mr-2 text-[10px]">
              <Favorite sx={{ fontSize: 18, color: "#3AF910" }} />
            </div>
          </div>
        </div>
        <div
          className="w-[30%] h-[56px]"
          style={{ background: typeMapData?.[listingKey]?.color }}
        ></div>
        <div className="w-[68%] flex flex-col">
          <div
            style={{ background: typeMapData?.[listingKey]?.color }}
            className="py-[15px] px-2 flex w-full "
          >
            <div className="flex justify-between items-center w-full ">
              <div className="flex items-center">
                <div className="text-[18px] text-[#000] font-bold">
                  {trimString(propertyName, 25)}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div>
                  <div className=" !text-[#000] px-2 py-1  text-sm font-semibold rounded-md h-full">
                    {review?.score}
                  </div>
                  {propertyClass && (
                    <div className="flex items-center">
                      {[...Array(propertyClass)].map((_, i) => (
                        <StarIcon
                          key={i}
                          fontSize="small"
                          style={{ color: "#FFD700" }}
                        />
                      ))}
                    </div>
                  )}
                </div>
                <div>
                  <div className="!text-[#000]  text-sm font-semibold">
                    {review?.review_nr}
                  </div>
                  <div className="text-[#000] text-xs">Reviews</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between text-[#07287B] px-2 mt-3">
            <div className="text-[18px] font-sans font-normal">
              {listing?.name}
            </div>
            <div className="border border-solid border-[#AEA3A3] rounded-10 p-2 shadow-md text-[16px] font-medium">
              {Math.round(pricePerDay)} $/night
            </div>
          </div>

          {/* Icons and Description */}
          <div className="px-2 flex flex-col gap-10 mt-5">
            <div className="flex items-center space-x-4 text-[14px] text-[#050505] font-light">
              <div className="flex items-center">
                <div className="w-10 h-10 ">
                  <IconCircular
                    alt={"locaton"}
                    key={"location"}
                    src={
                      majorLocation?.image ||
                      "https://picsum.photos/200?random=5"
                    }
                  />
                </div>
                <span>
                  {getDistanceFromLatLonInKm(
                    majorLocation?.latitude,
                    majorLocation?.longitude,
                    geometry?.coordinates?.[0],
                    geometry?.coordinates?.[1]
                  ).toFixed(1)}{" "}
                  km to {majorLocation?.locationName}
                </span>
                <DirectionIcon
                  onClick={toggleRoute}
                  className="text-primary cursor-pointer"
                />
              </div>
              {metroStation?.length > 0 && (
                <div className="text-[14px] text-[#050505] font-light flex items-center">
                  <div className="rounded-full w-6 h-6 mr-2 flex items-center">
                    <img
                      alt="metro"
                      src={metroIcon}
                      className="rounded-full  w-4 h-4"
                    />
                  </div>
                  {getClosestMetroDistance(metroStation, {
                    lat: geometry?.coordinates?.[0],
                    lng: geometry?.coordinates?.[1],
                  }).toFixed(1)}{" "}
                  km Metro
                </div>
              )}
              <div className="text-[14px] text-[#050505] font-light flex items-center">
                <LocationOnIcon
                  sx={{
                    color: "#8B0C0C",
                    fontSize: 20,
                  }}
                />
                {getDistanceFromLatLonInKm(
                  cityCenter?.lat,
                  cityCenter?.lng,
                  geometry?.coordinates?.[0],
                  geometry?.coordinates?.[1]
                ).toFixed(1)}{" "}
                km from city center
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between text-[#07287B] gap-1 px-2 mt-3">
            <div className="flex gap-1 items-center">
              <LocationOn className="text-[#B73030]" />
              <div className="text-[18px] font-sans font-normal">
                Pinhal do Concelho, Praia da Falésia, Albufeira
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center  px-2 mt-3">
            <div className="flex w-full items-center gap-2">
              <div className="text-[14px] text-[#050505] font-light">
                {priceInfoContent}
              </div>
              <div className="text-[18px] font-medium text-[#050505]">
                {price}
              </div>
            </div>

            <div className=" flex">
              <div
                onClick={() => toggleAvailabilityView(property)}
                className="flex w-[112px] cursor-pointer items-center no-underline p-2 gap-2 border shadow border-solid border-[#fff] bg-[#015EFF] rounded-[5px] text-[#fff] text-[12px]"
              >
                <div>See availability</div>
                <OpenInBrowser />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenRoute && (
        <BestRoute
          open={isOpenRoute}
          toggleRoute={toggleRoute}
          majorLocation={majorLocation}
          directions={directions}
          latlng={geometry?.coordinates}
        />
      )}
    </React.Fragment>
  );
};
export default ListingAirbnbItem;

import dayjs from "dayjs";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { isGreaterDate, listType } from "../../common/Helpers";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({
  initialValue = [null, null],
  onChange,
  filterKey,
}) => {
  const [startDate, setStartDate] = useState(formatDate(initialValue?.[0]));
  const [endDate, setEndDate] = useState(formatDate(initialValue?.[1]));

  function formatDate(date) {
    return dayjs(date).format("ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)");
  }
  const handleChange = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
    if (onChange) {
      onChange({ startDate: date[0], endDate: date[1] });
    }
  };

  function SetRange(newDate, name) {
    if (onChange) {
      if (name === "startDate") {
        let end = endDate;
        if (isGreaterDate(newDate, end)) {
          end = formatDate(dayjs(newDate).add(1, "day"));
        }
        onChange({ startDate: newDate, endDate: end });
      } else {
        onChange({ startDate: startDate, endDate: newDate });
      }
    }
  }

  return (
    <div className=" w-full mb-1">
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        isClearable={true}
        minDate={new Date()}
        wrapperClassName="w-full flex justify-center"
        customInput={
          <input
            style={{ borderColor: listType[filterKey].color }}
            className="w-full p-[10px] rounded-[10px] border border-solid"
            placeholder="Select Date Range"
          />
        }
      />
    </div>
  );
};

export default DateRangePicker;

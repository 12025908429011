import React, { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import metroIcon from "../../assets/icons/svg/metro-station.svg";
import DirectionIcon from "@mui/icons-material/Directions";
import mapTypeIcon from "../../assets/icons/svg/map-type.svg";
import IconCircular from "../../components/IconCircular/IconCircular";
import {
  getClosestMetroDistance,
  getDistanceFromLatLonInKm,
  trimString,
} from "../../common/Helpers";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import BestRoute from "./BestRoute";
import {
  Delete,
  DeleteOutline,
  Favorite,
  FavoriteBorder,
  OpenInBrowser,
} from "@mui/icons-material";

const MobileListingHotelItem = ({
  property,
  typeMapData,
  majorLocation,
  cityCenter,
  toggleAvailabilityView,
  metroStation,
}) => {
  const {
    thumbnail,
    propertyName,
    propertyClass,
    listingKey,
    review_score,
    price,
    priceBreakdown,
    checkinDate,
    checkoutDate,
    review_score_word,
    review_nr,
    proposedAccommodation,
  } = property.properties || "";
  const geometry = property?.geometry || {
    coordinates: property.properties?.latlng,
  };
  const { fetchDirections, directions } = useGooglePlaces();
  const daysBetween = dayjs(checkoutDate).diff(dayjs(checkinDate), "day");
  const totalPrice =
    property.properties?.totalPrice ||
    priceBreakdown?.grossPrice?.value * daysBetween;
  const [isOpenRoute, setRoute] = useState(false);

  useEffect(() => {
    fetchDirections(
      { lat: geometry?.coordinates?.[0], lng: geometry?.coordinates?.[1] },
      { lat: majorLocation?.latitude, lng: majorLocation?.longitude }
    );
  }, []);
  function toggleRoute() {
    setRoute(!isOpenRoute);
  }

  return (
    <React.Fragment>
      {/* Details Section */}
      <div
        className="mb-3 w-full"
        onClick={() => toggleAvailabilityView(property)}
      >
        <div className="flex  w-full relative ">
          <div className="w-full flex flex-col">
            <div
              style={{ background: typeMapData?.[listingKey]?.color }}
              className="py-[15px] px-2 flex w-full "
            >
              <div className="flex justify-between items-center w-full ">
                <div className="flex flex-col gap-1">
                  <div className="text-[16px] text-[#FBFCFF]">
                    {trimString(propertyName, 40)}
                  </div>
                  {propertyClass && (
                    <div className="flex">
                      {[...Array(propertyClass)].map((_, i) => (
                        <StarIcon
                          key={i}
                          fontSize="small"
                          style={{ color: "#FFD700" }}
                        />
                      ))}
                    </div>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  <div className="text-[#fff]">
                    <div className="text-[14px] font-medium font-sans">
                      {review_score_word}
                    </div>
                    <div className="text-[12px] font-light font-sans">
                      {review_nr} reviews
                    </div>
                  </div>
                  <div className="!bg-[#2AA8E2] !text-[#fff] px-2 py-1  text-sm font-bold rounded-md h-full mr-2">
                    {review_score}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-[240px] relative">
              <img
                src={thumbnail}
                alt={propertyName}
                className="h-[240px] w-full "
              />
              <div className="flex justify-center items-center w-full absolute top-2 z-50">
                <div className="flex w-[50%] px-2 py-1 justify-center items-center gap-1 border border-solid border-[#AEA3A3] shadow bg-[#fff] text-[#000] font-sans rounded-10">
                  <div className="text-[18px] font-sans font-medium text-[#093BA5]">
                    {Math.round(price)} $/night
                  </div>
                </div>
              </div>
              <div className="absolute top-3 right-3">
                <img alt="area" src={mapTypeIcon} />
              </div>
              <div className="absolute bottom-[5px] flex items-center w-full py-2 justify-between bg-[#170E0E]/50 text-[12px] text-[#fff] font-light">
                <div className="flex items-center ml-2">
                  <div className="w-[30px] h-[30px] ">
                    <IconCircular
                      alt={"locaton"}
                      key={"location"}
                      src={
                        majorLocation?.image ||
                        "https://picsum.photos/200?random=5"
                      }
                    />
                  </div>
                  <span>
                    {getDistanceFromLatLonInKm(
                      majorLocation?.latitude,
                      majorLocation?.longitude,
                      geometry?.coordinates?.[0],
                      geometry?.coordinates?.[1]
                    ).toFixed(1)}{" "}
                    km to {majorLocation?.locationName}
                  </span>
                  <DirectionIcon
                    onClick={toggleRoute}
                    className="text-primary cursor-pointer"
                  />
                </div>
                {metroStation?.length > 0 && (
                  <div className="text-[12px] flex items-center mr-2">
                    <div className="rounded-full w-6 h-6 mr-2 flex items-center">
                      <img
                        alt="metro"
                        src={metroIcon}
                        className="rounded-full  w-4 h-4"
                      />
                    </div>
                    {getClosestMetroDistance(metroStation, {
                      lat: geometry?.coordinates?.[0],
                      lng: geometry?.coordinates?.[1],
                    }).toFixed(1)}{" "}
                    km Metro
                  </div>
                )}
              </div>
            </div>

            <div className="flex text-[#0A2470] text-[16px]">
              <div className="p-2 bg-[#E4FFF4]">
                {proposedAccommodation?.[0]}
              </div>
              <div className="p-2 bg-[#94D5F7]">
                {proposedAccommodation?.[1]}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full items-center mt-2 shadow-md border-b border-b-solid border-[#EAE4E4]">
          <div className="ml-2 text-[10px]">
            <DeleteOutline sx={{ fontSize: 32, color: "red" }} />
          </div>
          <div className=" flex">
            <div
              onClick={() => toggleAvailabilityView(property)}
              className="flex  cursor-pointer items-center no-underline p-2 gap-2 border shadow border-solid border-[#fff] bg-[#015EFF] rounded-[5px] text-[#fff] text-[15px] font-sans font-medium"
            >
              <div>See availability</div>
              <OpenInBrowser />
            </div>
          </div>
          <div className="mr-2 text-[10px]">
            <FavoriteBorder sx={{ fontSize: 32, color: "#56B221" }} />
          </div>
        </div>
      </div>
      {isOpenRoute && (
        <BestRoute
          open={isOpenRoute}
          toggleRoute={toggleRoute}
          majorLocation={majorLocation}
          directions={directions}
          latlng={geometry?.coordinates}
        />
      )}
    </React.Fragment>
  );
};
export default MobileListingHotelItem;

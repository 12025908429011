import React, { useState } from "react";
import { Close, Delete } from "@mui/icons-material";
import { Favorite } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import CustomChip from "../../CustomChip";
import {
  getCurrentUser,
  getDistanceFromLatLonInKm,
} from "../../../common/Helpers";
import { addToFavoriteList } from "../../../store/User/userAction";
import dayjs from "dayjs";

const MapListAirbnbInfo = ({ cityCenter, showDetailToggle, details }) => {
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();
  const [selectedImage, setSelectedImage] = useState("");
  const listingDetail = useSelector((state) => state.hotels.listingDetail);
  const listingMedia = useSelector((state) => state.hotels.listingMedia);
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const majorLocation =
    myLocation?.length > 0
      ? myLocation.filter((loc) => loc.isMajor)?.[0]
      : null;
  const {
    id,
    price,
    thumbnail,
    propertyName,
    review_score,
    review_nr,
    priceInfoContent,
    listingKey,
    badge,
    latitude,
    longitude,
    priceBreakdown,
    checkinDate,
    checkoutDate,
    listing,
  } = details?.properties || {};
  const daysBetween = dayjs(checkoutDate).diff(dayjs(checkinDate), "day");
  const totalPrice = priceBreakdown?.grossPrice?.value * daysBetween;

  function handleSelected(updatedIndex) {
    setSelectedImage(
      listingMedia[updatedIndex]?.url_1440 ||
        listingMedia[updatedIndex]?.url ||
        listingMedia[updatedIndex]?.baseUrl
    );
  }

  function toggleDetailView() {
    showDetailToggle();
  }

  const handleFavorite = () => {
    dispatch(
      addToFavoriteList({
        userId: currentUser?.id,
        itemData: {
          isPinned: false,
          listingId: id,
          propertyName: propertyName,
          address: listingDetail?.address || "",
          phoneNumber: listingDetail?.phoneNumber || "",
          price,
          thumbnail: thumbnail,
          totalPrice: totalPrice,
          review_score: listing?.avgRatingLocalized,
          description: priceInfoContent,
          listingKey: listingKey,
          propertyType: listingKey,
          class: details?.properties?.class,
          lat: latitude,
          lng: longitude,
          url: details?.properties?.listing?.webURL || listingDetail?.url || "",
          country: details?.properties?.country,
          latlng: [latitude, longitude],
        },
      })
    );
  };

  const chipClrs = [
    "#a1d4f2",
    "#f54291",
    "#5bc41b",
    "#ff7d45",
    "#7635ff",
    "#1f8c9a",
    "#e3f716",
    "#ad3f21",
    "#5e87a6",
    "#d450f3",
  ];
  return (
    <React.Fragment>
      <div className="max-w-md mx-auto border rounded-lg shadow-lg overflow-hidden z-[36000] absolute top-0 bg-[#fff]">
        <div
          className="right-1 absolute top-1 z-[360000] cursor-pointer"
          onClick={showDetailToggle}
        >
          <Close className="text-[#fff]" />
        </div>
        {/* Image Section */}
        <div className="relative cursor-pointer" onClick={toggleDetailView}>
          <img
            src={
              selectedImage ||
              listingMedia?.[0]?.url_1440 ||
              listingMedia?.[0]?.url ||
              listingMedia?.[0]?.baseUrl
            }
            alt="Property"
            className="w-full h-56 object-cover"
          />
          <div className="absolute bottom-2 w-full flex justify-between items-center">
            {/* Delete Button */}
            <div className="text-[red] bg-[#fff] px-1 rounded-md shadow-md ml-2">
              <Delete />
            </div>
            {/* View Phone Button */}
            {/* <button className=" bg-[#2D6ADC] text-[#fff] border border-solid border-[#fff] flex items-center gap-2 px-4 py-2 rounded shadow-md">
            <Phone />
            View Phone
          </button> */}
            {/* Favorite Button */}
            <div
              className="text-[red] bg-[#fff] px-1 rounded-md shadow-md mr-2 cursor-pointer"
              onClick={handleFavorite}
            >
              <Favorite />
            </div>
          </div>
        </div>

        {/* Thumbnail Section */}
        <div className="flex gap-2 mt-2 overflow-x-auto hide-scrollbar">
          {listingMedia?.length > 0 &&
            listingMedia.map((image, idx) => (
              <img
                onClick={() => handleSelected(idx)}
                key={idx}
                src={image?.url_1440 || image?.url || image?.baseUrl}
                alt={`Thumbnail ${idx + 1}`}
                className="w-12 h-12 rounded object-cover cursor-pointer"
              />
            ))}
        </div>

        {/* Info Section */}
        <div className="px-4 py-2">
          <h2 className="text-lg font-bold">{listingDetail?.propertyName}</h2>
          <p className="text-sm text-gray-500">{listingDetail?.address}</p>

          {/* Feature Badges */}
          <div className="flex flex-wrap gap-2 mt-2">
            {listingDetail?.top_ufi_benefits?.length > 0 &&
              listingDetail?.top_ufi_benefits.map((chip, i) => (
                <CustomChip
                  key={i}
                  text={chip?.translated_name}
                  chipBgClr={chipClrs?.[i] || ""}
                />
              ))}
          </div>

          {/* Distance Info */}
          <div className="flex items-center justify-between mt-3">
            <span className="flex items-center gap-1 text-gray-600 text-sm">
              {getDistanceFromLatLonInKm(
                majorLocation?.latitude,
                majorLocation?.longitude,
                listingDetail?.latitude,
                listingDetail?.longitude
              ).toFixed(1)}{" "}
              km to {majorLocation?.locationName}
            </span>
            <span className="flex items-center gap-1 text-gray-600 text-sm">
              {getDistanceFromLatLonInKm(
                cityCenter?.lat,
                cityCenter?.lng,
                listingDetail?.latitude,
                listingDetail?.longitude
              ).toFixed(1)}{" "}
              km from city center
            </span>
          </div>

          {/* Price Section */}
          <div className="text-center my-4">
            <span className="bg-[#3F75FF] text-[#fff] px-4 py-2 rounded-10 text-[16px] font-sans">
              ${Math.round(listingDetail?.price)} per night
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MapListAirbnbInfo;

import React from "react";
import {
  ArrowBack,
  InsertPhoto,
  LocationOn,
  SmartDisplay,
} from "@mui/icons-material";
import StarView from "../Area/StarView/StarView";
import CustomChip from "../../components/CustomChip";
import { cubeClrs } from "../../common/Helpers";

// Reusable Modal Component
const ListingDetailFullscreen = (props) => {
  const { activeType, toggleTypeSwitch, listingDetail, property } = props;
  const { top_ufi_benefits } = listingDetail || "";
  const highlightsChip = listingDetail?.sections?.highlights?.highlights;
  const { constructedArea, floor, lift } =
    listingDetail?.moreCharacteristics || "";
  let topBenefits = top_ufi_benefits || [];

  if (
    property?.properties?.listingKey === "monthlyStay" ||
    property?.properties?.accommodationType === "monthlyStay"
  ) {
    topBenefits = highlightsChip;
  }

  if (
    ["rent", "buy"].includes(
      property?.properties?.listingKey ||
        property?.properties?.accommodationType
    )
  ) {
    topBenefits = [
      { title: `${constructedArea} m²` },
      {
        title: `${floor ? `${floor} floor` : ""} ${
          lift ? "with" : "without"
        } lift`,
      },
      { title: property?.properties?.detailedType?.subTypology || "" },
    ];
  }
  return (
    <div className="flex justify-center bg-[#080505] relative">
      <div
        onClick={props.toggleImageView}
        className="text-[#fff] text-[16px] font-sans w-[100px] absolute left-4 top-4 mx-8 cursor-pointer"
      >
        <ArrowBack className="" />
        <div>Back to swipe</div>
      </div>

      <div className={"w-[60%] h-full flex gap-6 pt-3 relative"}>
        <div className="w-full flex justify-center items-center gap-2 flex-wrap mt-[25px] absolute left-4 top-4">
          {topBenefits?.length > 0 &&
            topBenefits.map((chip, i) => (
              <CustomChip
                key={i}
                text={chip?.translated_name || chip?.title}
                chipBgClr={cubeClrs?.[i] || ""}
              />
            ))}
        </div>
        <div
          className="w-full flex flex-col items-center gap-[6px] overflow-y-auto hide-scrollbar"
          style={{ height: "calc(100vh - 160px)" }}
        >
          {activeType === "photo" &&
            props.listingMedia?.length > 0 &&
            props.listingMedia.map((image, index) => (
              <div
                key={index}
                className="cursor-pointer w-full rounded-[4px] h-[60vh]" // Ensure container height is explicitly defined
              >
                <img
                  src={image?.url_1440 || image?.url || image?.baseUrl}
                  alt={`Thumbnail ${index + 1}`}
                  className="w-full h-full object-cover rounded-lg" // Use h-full to match container height
                />
              </div>
            ))}
          {activeType === "video" &&
            props.listingMediaVideo?.length > 0 &&
            props.listingMediaVideo.map((video, index) => (
              <div
                key={index}
                className="cursor-pointer w-full rounded-[4px] h-[60vh]" // Ensure container height is explicitly defined
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={video?.url}
                  className="relative inline-block w-full h-full"
                >
                  <video
                    src={video?.url}
                    poster={video?.thumbnail}
                    className="w-full h-full"
                    autoPlay
                    controls
                    loop
                  />
                </a>
              </div>
            ))}
          {activeType === "map" && (
            <div className="w-full h-full">
              <StarView />
            </div>
          )}
        </div>
        <div className={`absolute  flex w-full justify-center bottom-18 gap-2`}>
          {props.listingMediaVideo?.length > 0 && (
            <div
              onClick={() => toggleTypeSwitch("video")}
              className={`cursor-pointer w-[119px] h-[33px] rounded-[10px] text-[18px]border border-solid font-medium font-sans ${
                activeType === "video"
                  ? "border-[#fff] bg-[#1560D0] text-[#fff]"
                  : "border-[#fff] bg-[#fff] text-[#1560D0]"
              } flex gap-2 justify-center items-center`}
            >
              <SmartDisplay />
              Video
            </div>
          )}
          <div
            onClick={() => toggleTypeSwitch("photo")}
            className={`cursor-pointer w-[99px] h-[33px] rounded-[10px] text-[18px]border border-solid ${
              activeType === "photo"
                ? "border-[#fff] bg-[#1560D0] text-[#fff]"
                : "border-[#fff] bg-[#fff] text-[#1560D0]"
            }  font-medium font-sans flex gap-2 justify-center items-center`}
          >
            <InsertPhoto />
            Photos
          </div>
          <div
            onClick={() => toggleTypeSwitch("map")}
            className={`cursor-pointer w-[99px] h-[33px] rounded-[10px] text-[18px]border border-solid font-medium font-sans ${
              activeType === "map"
                ? "border-[#fff] bg-[#1560D0] text-[#fff]"
                : "border-[#fff] bg-[#fff] text-[#1560D0]"
            }  flex gap-2 justify-center items-center`}
          >
            <LocationOn />
            Map
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingDetailFullscreen;

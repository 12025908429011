import React, { useState } from "react";
import { Chip, Box, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { cubeClrs } from "../../common/Helpers";

const MostPopularFacilities = ({ listingDetail }) => {
  const [currentPage, setCurrentPage] = useState(0);

  // Number of chips per page
  const itemsPerPage = 9;

  // Extract facilities and paginate them
  const facilities =
    listingDetail?.facility?.filter((chip) => chip?.title) || [];
  const totalPages = Math.ceil(facilities.length / itemsPerPage);

  // Get the chips for the current page
  const currentItems = facilities.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );

  // Navigation handlers
  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Box className="p-4 relative">
      {/* Chips Grid */}
      <Grid container spacing={2}>
        {currentItems.map((chip, index) => (
          <Grid item xs={4} key={index}>
            <Chip
              label={chip?.title || ""}
              sx={{
                margin: "5px 5px 5px 15px",
                borderRadius: "10px",
                border: "1px solid #E9E8E8",
                padding: "30px 15px",
                background: cubeClrs[index],
              }}
              className=" text-[#413D3D] text-[18px] font-sans shadow w-[233px] h-[82px]"
            />
          </Grid>
        ))}
      </Grid>

      {/* Navigation Buttons */}
      <Box className="absolute  top-1/2 flex justify-between items-center w-full">
        <div
          disabled={currentPage === 0}
          onClick={handlePrevious}
          style={{ border: "1px solid #E9E8E8" }}
          className=" bg-[#fff]  w-[28px] h-[28px] rounded-full flex justify-center items-center shadow-inner cursor-pointer"
        >
          <ArrowBackIosIcon className="ml-[7px]" />
        </div>
        <div
          onClick={handleNext}
          disabled={currentPage >= totalPages - 1}
          style={{ border: "1px solid #E9E8E8" }}
          className=" bg-[#fff] mr-[20px] w-[28px] h-[28px] rounded-full flex justify-center items-center shadow-2xl cursor-pointer"
        >
          <ArrowForwardIosIcon />
        </div>
      </Box>
    </Box>
  );
};

export default MostPopularFacilities;

import { Close } from "@mui/icons-material";
import React from "react";

const OpeningHours = ({ hours, onClose }) => {
  return (
    <div className="w-full mx-auto p-4 bg-[#fff] rounded-lg shadow-md">
      <div className="absolute top-2 right-6">
        <div
          onClick={onClose}
          className="flex items-center cursor-pointer justify-center rounded-full bg-[#fff] w-[20px] h-[20px]"
        >
          <Close sx={{ fontSize: 15 }} />
        </div>
      </div>
      <ul className="divide-y divide-gray-200 pl-0">
        {hours?.length > 0 &&
          hours.map((hour, index) => (
            <li key={index} className="flex justify-center py-2">
              <span className={`text-gray-600 text-[14px]`}>{hour}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default OpeningHours;

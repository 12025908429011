import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Header from "../components/Header";
import Content from "../components/Content";
import { APIProvider } from "@vis.gl/react-google-maps";
import { isMobile } from "react-device-detect";
import MobileHeader from "../components/Header/MobileHeader";

const apiKey = "AIzaSyDBfPZBgU_xAaRw_-0VU1hv1TyoYR_nV6E";
const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  if (loading) {
    return <div>loading...</div>;
  }
  console.log("apiiiiiii", process.env.REACT_APP_GOOGLE_API_KEY);
  return isAuthenticated ? (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      {isMobile ? <MobileHeader /> : <Header />}
      <Content>{children}</Content>
    </APIProvider>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;

import React from "react";
import { Divider, IconButton, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  DirectionsCar,
  DirectionsRun,
  DirectionsTransit,
  UTurnLeft,
} from "@mui/icons-material";
import { gotoDirections } from "../../common/Helpers";

const MyLocationDetail = ({
  directions,
  selectedListLocation,
  distance,
  activeItem,
  returnBack,
}) => {
  return (
    <div className="flex flex-col h-screen w-full items-center">
      {/* Top Section with Image */}
      <div className="relative w-full h-64">
        <img
          src={
            selectedListLocation?.image || "https://via.placeholder.com/600x400"
          } // Replace with your image URL
          alt="Location"
          className="w-full h-64 object-cover"
        />
        {/* Back Icon */}
        <IconButton
          onClick={() => returnBack(null)}
          className="!absolute z-30 top-0 left-4  rounded-full"
          aria-label="back"
          size="small"
        >
          <UTurnLeft fontSize="small" className="text-[#fff] rotate-90" />
        </IconButton>
      </div>

      {/* Location Details */}
      <div className="my-2 w-[98%]">
        <div className="flex justify-between">
          <div className="text-[18px] text-[#0A26A4]">
            {selectedListLocation?.locationName}
          </div>
          <div className="text-[14px] text-[#0A26A4]">{distance}km</div>
        </div>
      </div>

      {/* Best Route */}
      <div
        className="w-[98%] overflow-y-auto hide-scrollbar"
        style={{ height: "calc(32vh)" }}
      >
        <div className="flex justify-between">
          <div className="flex items-center">
            <DirectionsRun sx={{ fontSize: 20, color: "#0C5EC4" }} />{" "}
            <span className="font-sans font-light text-[14px]">
              {directions?.WALKING?.routes?.[0]?.legs?.[0]?.duration?.text}
            </span>
          </div>
          <div className="flex items-center">
            <DirectionsCar sx={{ fontSize: 20, color: "#0C5EC4" }} />{" "}
            <span className="font-sans font-light text-[14px]">
              {directions?.DRIVING?.routes?.[0]?.legs?.[0]?.duration?.text}
            </span>
          </div>
          <div className="flex items-center">
            <DirectionsTransit sx={{ fontSize: 20, color: "#0C5EC4" }} />{" "}
            <span className="font-sans font-light text-[14px]">
              {directions?.TRANSIT?.routes?.[0]?.legs?.[0]?.duration?.text}
            </span>
          </div>
        </div>
        <div>
          <div className="flex flex-col  mt-3 w-full ">
            {/* Top Section */}
            <div className="flex items-center space-x-4">
              {/* Bus Icon */}
              <div className="text-primary">
                <DirectionsTransit className="text-4xl text-blue-600" />
              </div>
              {/* Timing */}
              <div>
                <Typography
                  variant="subtitle1"
                  className="font-bold text-gray-900"
                >
                  {
                    directions?.TRANSIT?.routes?.[0]?.legs?.[0]?.departure_time
                      ?.text
                  }{" "}
                  —{" "}
                  {
                    directions?.TRANSIT?.routes?.[0]?.legs?.[0]?.arrival_time
                      ?.text
                  }
                </Typography>
              </div>
              <div>
                <Typography
                  variant="body2"
                  className="text-gray-500 font-light text-sm"
                >
                  {directions?.TRANSIT?.routes?.[0]?.legs?.[0]?.duration?.text}
                </Typography>
              </div>
            </div>

            <Divider className="my-4" />

            {/* Route Details */}
            <div className="flex items-center space-x-2 text-sm">
              {/* Walk */}
              <div className="flex items-center space-x-1">
                <DirectionsRun className="text-gray-500" />
              </div>

              {/* Arrow Icon */}
              <span>›</span>

              {/* Bus Station */}
              <div className="px-2 py-1 bg-blue-100 text-blue-800 font-semibold rounded-md">
                Az
              </div>

              {/* Arrow Icon */}
              <span>›</span>

              {/* Walk */}
              <div className="flex items-center space-x-1">
                <DirectionsRun className="text-gray-500" />
              </div>
            </div>

            {/* Timing Details */}
            <div className="mt-3 text-gray-500 text-sm">
              {directions?.TRANSIT?.routes?.[0]?.legs?.[0]?.steps?.length > 0 &&
                directions?.TRANSIT?.routes?.[0]?.legs?.[0]?.steps.map(
                  (step) => (
                    <Typography variant="body2">
                      {step?.instructions} - {step.distance?.text} -{" "}
                      {step?.duration?.text}
                    </Typography>
                  )
                )}
            </div>

            {/* Details Button */}
            <div className="mt-4">
              <div
                onClick={() =>
                  gotoDirections(
                    [
                      selectedListLocation.latitude,
                      selectedListLocation.longitude,
                    ],
                    [
                      activeItem?.properties?.latitude,
                      activeItem?.properties?.longitude,
                    ]
                  )
                }
                className="text-[#0C5EC4] text-sm font-medium cursor-pointer "
              >
                Details
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyLocationDetail;

import React from "react";
import Tooltip from "@mui/material/Tooltip";

const CustomTooltip = ({ title, children, placement = "bottom", ...props }) => {
  return (
    <Tooltip title={title} placement={placement} arrow {...props}>
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;

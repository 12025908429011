import React, { useEffect, useState } from "react";
import { Card, CardMedia } from "@mui/material";
import { trimString } from "../../common/Helpers";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import { AccessTime, Close, LocationOn, Reviews } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import OpeningHours from "../../views/Area/Places/OpeningHours";
import ReviewPlace from "./ReviewPlace";
import MapPlace from "./MapPlace";

const PlacesListView = ({
  place,
  handleMarkerClick,
  distance,
  isMobile,
  isMyLocation,
}) => {
  const { rating, name, user_ratings_total } = place;
  const { fetchPlaceDetails, placeDetails } = useGooglePlaces();
  const [showHoursPopup, setShowHoursPopup] = useState(false);
  const [showReviewPopup, setShowReviewPopup] = useState(false);
  const [showMapPopup, setShowMapPopup] = useState(false);

  useEffect(() => {
    if (place) {
      fetchPlaceDetails(place?.place_id, {
        latitude: place?.latitude,
        longitude: place?.longitude,
        propertyName: place?.name,
      });
    }
  }, [place]);

  const toggleHoursPopup = () => {
    setShowHoursPopup(!showHoursPopup);
  };
  const toggleReviewPopup = () => {
    setShowReviewPopup(!showReviewPopup);
  };
  const toggleMapPopup = () => {
    setShowMapPopup(!showMapPopup);
  };

  return (
    <div className="w-full h-full relative mt-2">
      {/* Close Button */}
      <div className="absolute top-2 right-6">
        <div
          onClick={() => handleMarkerClick(null)}
          className="flex items-center cursor-pointer justify-center rounded-full bg-[#fff] w-[20px] h-[20px]"
        >
          <Close sx={{ fontSize: 15 }} />
        </div>
      </div>

      {/* Name and Rating */}
      <div className="flex items-center absolute top-[30px] w-full justify-center">
        <div className="flex gap-2 items-center rounded-full bg-[#fff] p-2">
          <div className="font-sans font-medium text-[16px]">
            {trimString(name, 10)}
          </div>
          <div className="flex items-center font-sans font-normal text-[18px]">
            {rating} <StarIcon fontSize="small" style={{ color: "#FFD700" }} />
            <span className="text-[#0C224A] text-[14px] font-sans font-light">
              ({user_ratings_total})
            </span>
          </div>
        </div>
      </div>

      {/* Distance */}
      <div className="flex items-center absolute bottom-[100px] w-full justify-center">
        <div className="flex gap-2 items-center rounded-full bg-[#fff] p-2">
          <div className="font-sans text-[#1351D6] text-[14px]">
            {distance} km from the house
          </div>
        </div>
      </div>

      {/* Actions */}
      <div className="flex gap-2 items-center absolute bottom-[50px] w-full justify-center">
        <div
          onClick={toggleHoursPopup}
          className="flex cursor-pointer gap-2 items-center px-2 py-1 rounded-10 bg-[#137DE6] border border-solid border-[#fff] text-[14px] font-sans text-[#fff]"
        >
          <AccessTime />
          <div>Hours</div>
        </div>
        <div
          onClick={toggleMapPopup}
          className="flex cursor-pointer gap-2 items-center px-2 py-1 rounded-10 bg-[#137DE6] border border-solid border-[#fff] text-[14px] font-sans text-[#fff]"
        >
          <LocationOn />
          <div>Map</div>
        </div>
        <div
          onClick={toggleReviewPopup}
          className="flex cursor-pointer gap-2 items-center px-2 py-1 rounded-10 bg-[#137DE6] border border-solid border-[#fff] text-[14px] font-sans text-[#fff]"
        >
          <Reviews />
          <div>Reviews</div>
        </div>
      </div>

      {/* Opening Hours Popup */}
      {showHoursPopup && (
        <div className="absolute bottom-2 w-full flex justify-center">
          <OpeningHours
            hours={placeDetails?.opening_hours?.weekday_text}
            onClose={toggleHoursPopup}
          />
        </div>
      )}
      {showReviewPopup && (
        <div className="absolute bottom-2 w-full flex justify-center bg-[#fff]">
          <ReviewPlace
            reviews={placeDetails?.reviews}
            onClose={toggleReviewPopup}
          />
        </div>
      )}
      {showMapPopup && (
        <div className="absolute bottom-2 w-full flex justify-center bg-[#fff]">
          <MapPlace onClose={toggleMapPopup} />
        </div>
      )}

      {/* Place Photos */}
      <div
        className={`flex-1 h-full ${
          isMobile ? "" : "ml-[20px]"
        } items-center flex-col w-[100%] overflow-y-auto hide-scrollbar`}
      >
        <div
          className={`${
            isMobile ? "w-full" : "w-[90%]"
          } flex flex-col gap-1 items-center`}
        >
          {placeDetails?.photos?.length > 0 &&
            placeDetails.photos.map((photo, index) => (
              <div
                className={`mb-2 w-full ${
                  isMobile ? "max-h-[290px]" : "max-h-[300px] max-w-[390px]"
                }`}
                key={index}
              >
                <Card
                  sx={{
                    borderRadius: "5px",
                    border: "0.44px solid #fff",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: "390px",
                      height: "300px",
                    }}
                    image={photo?.getUrl()}
                    alt={`pl-${index}`}
                  />
                </Card>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PlacesListView;

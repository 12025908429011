import React, { useEffect, useState } from "react";
import nightIcon from "../../assets/icons/svg/night-new.svg";
import shorttermIcon from "../../assets/icons/svg/shortterm-new.svg";
import rentIcon from "../../assets/icons/svg/rent-new.svg";
import buyIcon from "../../assets/icons/svg/buy-new.svg";
import { useDispatch, useSelector } from "react-redux";
import { getSavedFilter, listType } from "../../common/Helpers";
import {
  clearHotelFilters,
  clearShortTermFilters,
  clearRentFilters,
  clearBuyFilters,
  setMainFilter,
  setHotelFilters,
  setRentFilters,
  setBuyFilters,
} from "@/store/Filter/filterReducers";
import {
  clearMonthlyFilters,
  setMonthlyFilters,
} from "@/store/Filter/filterReducers";
import { removeListingByType } from "@/store/Listing/listingAction";
import MapSearch from "../LeftCol/MapSearch";
import LMMap from "../LeftCol/Map/Map";

const MobileListingMenu = ({ searchMode, isMapView }) => {
  const dispatch = useDispatch();
  let cachedFilters = getSavedFilter();
  const selectedMainFilters = useSelector(
    (state) => state.filter.selectedMainFilters || []
  );
  const filters = useSelector((state) => state.filter.filters || {});
  const [activeListing, setActiveListing] = useState(() => {
    if (selectedMainFilters.includes("hotels")) {
      return { hotels: true };
    }
    return {};
  });

  const menuItems = [
    {
      filterKey: "hotels",
      label: "Night",
      icon: nightIcon,
    },
    {
      filterKey: "monthlyStay",
      label: "Short term",
      icon: shorttermIcon,
    },
    {
      filterKey: "rent",
      label: "Rent",
      icon: rentIcon,
    },
    {
      filterKey: "buy",
      label: "Buy",
      icon: buyIcon,
    },
  ];

  useEffect(() => {
    if (cachedFilters?.selectedMainFilters?.length > 0) {
      let predefineFilter = {};
      cachedFilters.selectedMainFilters.map((sF) => {
        predefineFilter = { ...predefineFilter, [sF]: true };
      });
      setActiveListing(predefineFilter);
    }
  }, []);

  function handleListingType(filterKey) {
    if (!activeListing?.[filterKey]) {
      handleApplyFilter(filterKey, filters?.[filterKey]);
    } else {
      handleClearFilter(filterKey);
    }
    setActiveListing({
      ...activeListing,
      [filterKey]: !activeListing?.[filterKey],
    });
  }

  const handleClearFilter = (filterKey) => {
    const actionMap = {
      hotels: clearHotelFilters,
      shortTerm: clearShortTermFilters,
      monthlyStay: clearMonthlyFilters,
      rent: clearRentFilters,
      buy: clearBuyFilters,
    };
    const action = actionMap[filterKey];
    dispatch(removeListingByType(filterKey));
    if (action) {
      dispatch(action());
    }
  };

  const handleApplyFilter = (filterKey, values) => {
    const actionMap = {
      hotels: setHotelFilters,
      monthlyStay: setMonthlyFilters,
      rent: setRentFilters,
      buy: setBuyFilters,
    };
    const action = actionMap[filterKey];
    if (action) {
      dispatch(action({ ...values, isRefatched: true }));
      dispatch(setMainFilter(filterKey));
    }
  };

  return (
    <div className="w-full">
      <div className=" flex justify-between bg-[#fff]  border border-solid border-[#CBB9B9] shadow-2xl mb-3">
        {menuItems.map((option, i) => (
          <div
            onClick={() => handleListingType(option.filterKey)}
            key={option.filterKey}
            style={{
              background: activeListing?.[option.filterKey]
                ? listType?.[option.filterKey]?.color
                : "#fff",
              color: activeListing?.[option.filterKey] ? "#fff" : "#000",
            }}
            className={`flex items-center cursor-pointer font-sans text-[16px] font-light py-[10px] px-[20px]`}
          >
            {option.label}
          </div>
        ))}
      </div>
      <div className="w-full flex justify-center mb-3">
        <div className={`w-[80%] ${searchMode === 2 && "h-[50px]"}`}>
          {searchMode === 1 ? (
            <MapSearch onPlaceChanged={() => console.log("Place changed")} />
          ) : isMapView ? null : (
            <LMMap
              isListingScreen={true}
              isShowRadius={true}
              isMobile={true}
              isShowOnlyRadius={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileListingMenu;

import React from "react";
import { Avatar, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Add } from "@mui/icons-material";

const MobileMyLocationList = ({
  myLocation,
  toggleLocation,
  setToggleAddModal,
  isOpenAddModal,
}) => {
  const toggleMyLocation = (location) => {
    toggleLocation(location);
  };

  return (
    <div className="w-full bg-[#fff] ">
      {/* Header */}
      <div className="flex justify-between items-center mb-4 bg-[#0773FF]">
        <div className="text-[#fff] text-[16px] font-sans ml-2">
          My personal locations
        </div>
        <IconButton aria-label="add location" className="text-[#fff]">
          <span className="ml-1 text-sm text-[#fff]">
            {myLocation?.length} locations
          </span>
        </IconButton>
      </div>

      {/* Horizontal Scrollable List */}
      <div className="flex overflow-x-auto space-x-4 pb-2 scrollbar-hide text-[#000] hide-scrollbar">
        <div
          className="flex flex-col items-center min-w-[60px] mt-3"
          onClick={() => setToggleAddModal(!isOpenAddModal)}
        >
          <div>
            <Add sx={{ fontSize: 32 }} className="text-[#0222B0]" />
          </div>
          <div className=" text-[#0222B0] text-sm truncate max-w-[70px] text-center mt-1">
            Add
          </div>
        </div>
        {myLocation?.length > 0 &&
          myLocation.map((location, index) => (
            <div
              key={index}
              className="flex flex-col items-center min-w-[80px] mb-[33px]"
              onClick={() => toggleMyLocation(location)}
            >
              <Avatar
                src={location.image}
                alt={location.locationName}
                className="w-16 h-16 border-2 border-solid border-[#090D42] "
              />
              <span className=" text-[#090D42] text-sm truncate max-w-[70px] text-center mt-2">
                {location.locationName}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MobileMyLocationList;

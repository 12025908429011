import React from "react";
import { Button } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { listType } from "../../common/Helpers";
import dayjs from "dayjs";
import { Room } from "@mui/icons-material";

const AirbnbInfo = ({ property, isMobile, listingDetail }) => {
  const {
    propertyName,
    propertyClass,
    listingKey,
    review_nr,
    review_score_word,
    price,
    listingParamOverrides,
    pricingQuote,
    listing,
  } = property?.properties || "";
  const { checkin, checkout } = listingParamOverrides || "";
  const daysBetween = dayjs(checkout).diff(dayjs(checkin), "day");
  const actualPrice = price ? price.replace(/\D/g, "") : "";
  const pricePerDay = Number(actualPrice) / daysBetween;
  const { sections } = listingDetail ?? "";
  const isMyCollectionRoute = window?.location?.pathname === "/my-collection";

  return (
    <React.Fragment>
      {/* Details Section */}
      <div className="flex-1 p-4 flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center">
            <div
              className={`${
                isMobile ? "text-[20px]" : "text-[32px]"
              } font-medium text-[#2D7EE3]`}
            >
              {propertyName}
            </div>
            <div
              className={`font-sans ${
                isMobile ? "text-[20px]" : "text-[24px]"
              }  font-bold text-[#077BFF]`}
            >
              {isMyCollectionRoute ? price : `${Math.round(pricePerDay)} $`}
              /night
            </div>
          </div>
          {isMobile && (
            <React.Fragment>
              <div className="text-[#0C3897] font-sans font-semibold text-[14px] text-right my-2">
                Short term
              </div>
              <div className="text-center">
                <div>{review_score_word}</div>
                <div>Reviews</div>
              </div>
            </React.Fragment>
          )}
          {!isMobile && (
            <div className="flex justify-between items-center my-3">
              <div>
                <div className="flex justify-center items-center gap-0 text-[20px] font-light">
                  <Room className="text-[#f00]" />
                  <div className="text-[#14488D]">
                    {sections?.location?.address}
                  </div>
                </div>
              </div>
              <div>
                <div>{review_score_word}</div>
                <div>Reviews</div>
              </div>
              <div className="text-[#0C3897] font-sans font-semibold text-[20px]">
                Short term
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default AirbnbInfo;

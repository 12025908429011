import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { toggleDrawerAction } from "../../../store/Common/commonActions";
import Sorting from "./Sorting";
import {
  hotelOptions,
  rentBuyOptions,
  shortStayOptions,
} from "./sortingOptions";

const FilterButton = ({
  label,
  filterKey,
  initialValues = {},
  children,
  onApply,
  onClear,
  applied = false,
  setAppliedFilters,
  isDisabled,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [tempValues, setTempValues] = useState(initialValues);
  const [activeTab, setTab] = useState("filter");
  const [selectedOption, setSelectedOption] = useState("price_highest");

  useEffect(() => {
    setTempValues(initialValues);
  }, [initialValues]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    if (onApply) {
      onApply(filterKey, tempValues);
    }
    setAppliedFilters((prev) => ({ ...prev, [filterKey]: true }));
    handleClose();
  };

  const handleChange = (newValues) => {
    setTempValues((prev) => ({
      ...prev,
      ...newValues,
    }));
  };

  function onCloseDrawer() {
    dispatch(toggleDrawerAction());
  }

  function handleToggleTabs(tab) {
    setTab(tab);
  }

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const open = Boolean(anchorEl);
  const filterStyle = {
    rent: { popBg: `bg-[#FBAD3C]`, tabBg: "#FBAD3C" },
    monthlyStay: { popBg: `bg-[#FF0242]`, tabBg: "#FF0242" },
    hotels: { popBg: `bg-[#279DD4]`, tabBg: "#279DD4" },
    shortTerm: { popBg: `bg-[#4a90e2]`, tabBg: "#4a90e2" },
    buy: { popBg: `bg-[#2AB529]`, tabBg: "#2AB529" },
  };
  const sortOptions = {
    hotels: hotelOptions,
    monthlyStay: shortStayOptions,
    rent: rentBuyOptions,
    buy: rentBuyOptions,
  };

  return (
    <div className="flex flex-1 items-center relative h-full bg-[#fff]">
      <div
        className={`flex flex-col gap-3 bg-[#fff] !text-[#fff] w-full h-full`}
      >
        <div
          className={`text-white px-4 pt-4  ${filterStyle?.[filterKey]?.popBg}`}
        >
          <div className="flex justify-center py-1 items-center">
            <div className="text-lg font-semibold">{label}</div>
          </div>
          <div className="flex justify-between">
            <div
              className="text-white text-sm pb-4 cursor-pointer"
              style={{
                borderBottom: activeTab === "filter" ? "2px solid #fff" : "",
              }}
              onClick={() => handleToggleTabs("filter")}
            >
              Filter
            </div>
            <div
              className="text-white text-sm cursor-pointer"
              style={{
                borderBottom: activeTab === "sort" ? "2px solid #fff" : "",
              }}
              onClick={() => handleToggleTabs("sort")}
            >
              Sort
            </div>
          </div>
        </div>
        {activeTab === "sort" && (
          <Sorting
            options={sortOptions?.[filterKey]}
            selectedOption={selectedOption}
            onOptionChange={handleOptionChange}
            filterKey={filterKey}
          />
        )}
        {activeTab === "filter" && (
          <Box className="flex flex-wrap justify-between items-center gap-2 px-4">
            <div
              onClick={onCloseDrawer}
              className={`rounded-[5px] cursor-pointer px-4 py-2  text-xs transition bg-[#FFF0F0] text-[#000] shadow-md
              `}
            >
              Close Filter
            </div>
            <div
              onClick={() => console.log()}
              className={`rounded-[5px] cursor-pointer px-4 py-2  text-xs transition ${filterStyle[filterKey].popBg} text-[#fff] shadow-md
              `}
            >
              Save
            </div>
            <div
              onClick={handleApply}
              className={`rounded-[5px] cursor-pointer px-4 py-2  text-xs transition ${filterStyle[filterKey].popBg} text-[#fff] shadow-md
              `}
            >
              Apply
            </div>
          </Box>
        )}
        {!isDisabled && activeTab === "filter" && (
          <React.Fragment>
            {/* Scrollable content */}
            <div
              className="flex-grow overflow-y-auto hide-scrollbar p-3"
              style={{
                maxHeight: "calc(100vh - 180px)",
              }}
            >
              {React.Children.map(children, (child) =>
                child && React.isValidElement(child)
                  ? React.cloneElement(child, {
                      onChange: handleChange,
                      value: tempValues,
                    })
                  : null
              )}
            </div>
          </React.Fragment>
        )}

        {isDisabled && (
          <div className="flex justify-center items-center h-full px-2">
            Rent and Buy only available in Portugal, Spain, and Italy.
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterButton;

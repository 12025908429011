import React, { useEffect, useState } from "react";
import StaySelection from "./StaySelection";
import MapScreen from "./MapScreen";
import CustomDrawer from "../../components/CustomDrawer";
import AccommodationFilter from "../../components/LeftCol/AccommodationFilter";
import { toggleDrawerAction } from "../../store/Common/commonActions";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getSavedFilter } from "../../common/Helpers";
import { getUserLocationsAction } from "../../store/User/userAction";

const Home = () => {
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();
  let cachedFilters = getSavedFilter();
  const selectedMainFilters = useSelector(
    (state) => state.filter.selectedMainFilters || []
  );
  const isDrawerOpen = useSelector(
    (state) => state.common?.isDrawerOpen || false
  );
  const [state, setState] = useState({
    selected: null,
  });

  const { selected } = state;

  useEffect(() => {
    if (cachedFilters?.selectedMainFilters?.length > 0) {
      let predefineFilter = {};
      cachedFilters.selectedMainFilters.map((sF) => {
        predefineFilter = { ...predefineFilter, [sF]: true };
      });
      setState({ ...state, selected: predefineFilter });
    } else {
      setState({ ...state, selected: mapDefaultFilter() });
    }
    if (currentUser?.id) {
      dispatch(getUserLocationsAction(currentUser?.id));
    }
  }, []);

  function mapDefaultFilter() {
    const initialFilters = {};
    if (selectedMainFilters.includes("hotels")) {
      initialFilters.hotels = true;
    }
    return initialFilters;
  }

  function onSelectStayType(selectedType) {
    setState({ ...state, selected: selectedType });
  }

  function toggleDrawer() {
    dispatch(toggleDrawerAction());
  }

  return (
    <div className="h-full bg-[#000] w-full">
      <div className="h-full w-full">
        <div className="absolute bottom-[50px] z-30 w-full flex justify-center">
          <StaySelection
            onSelectStayType={onSelectStayType}
            defaultSelected={selected}
          />
        </div>
        <MapScreen isMobile={true} />
      </div>
      <CustomDrawer open={isDrawerOpen} onClose={toggleDrawer} isMobile={true}>
        <AccommodationFilter />
      </CustomDrawer>
    </div>
  );
};

export default Home;

import React, { useState } from "react";
import { Star, ArrowBack, ArrowForward, Close } from "@mui/icons-material";

const ReviewPlace = ({ onClose, reviews }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  const currentReview = reviews[currentIndex];
  return (
    <div className="relative p-4 bg-white w-full max-w-md rounded-lg shadow-md">
      {/* Close Button */}
      <div className="absolute top-2 right-6">
        <div
          onClick={onClose}
          className="flex items-center cursor-pointer justify-center rounded-full border border-solid border-[#000] w-15px] h-[15px]"
        >
          <Close sx={{ fontSize: 15 }} />
        </div>
      </div>

      {/* Reviewer Info */}
      <div className="space-x-3">
        <div className="text-sm font-semibold text-gray-800">
          {currentReview.author_name}
        </div>
        <div className="flex items-center">
          <div className="flex items-center space-x-1 text-yellow-500">
            <span>{currentReview.rating}</span>
            {[...Array(5)].map((_, index) => (
              <Star
                key={index}
                fontSize="small"
                style={{
                  color: index < currentReview.rating ? "#FFD700" : "#E0E0E0",
                }}
              />
            ))}
          </div>
          <div className="w-[30px] h-[30px] rounded-full overflow-hidden border border-gray-200">
            <img
              src={currentReview.profile_photo_url}
              alt={currentReview.author_name}
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        <div>
          <span className="text-xs text-gray-500">
            {currentReview.relative_time_description}
          </span>
        </div>
      </div>

      {/* Review Text */}
      <div className="mt-4 text-sm text-gray-700 space-y-3">
        <p>{currentReview.text}</p>
      </div>

      {/* Navigation Buttons */}
      <div className="mt-4 flex justify-between items-center">
        <div onClick={handlePrev} className="cursor-pointer">
          <ArrowBack />
        </div>
        <div onClick={handleNext} className="cursor-pointer">
          <ArrowForward />
        </div>
      </div>
    </div>
  );
};

export default ReviewPlace;

import React, { useState } from "react";
import Logo from "../Logo";
import IconCircular from "../IconCircular/IconCircular";
import { FiMoreVertical } from "react-icons/fi";
import { Popover, Divider } from "@mui/material";
import { supabase } from "../../utils/helpers/supabaseClient";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../common/Helpers";

const Header = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const layout = useSelector((state) => state.layout.col);
  const navigate = useNavigate();
  const currentUser = getCurrentUser();

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      // Handle logout success if needed
      localStorage.removeItem("user");
    }
    handleClose(); // Close the popover after logout
  };

  function handleChangePass() {
    navigate("/reset-password");
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (layout === 3) {
    return null;
  }
  const activeStyle = { fontWeight: 700 };
  return (
    <header className="flex flex-col bg-[#1351BC] h-[75px] min-h-[75px] text-white px-4">
      <div className="flex flex-row flex-1 w-full justify-between items-center">
        <div className="flex items-center gap-2">
          <Link
            to={"/home"}
            className="flex flex-row items-center gap-3 cursor-pointer no-underline"
          >
            <Logo classes={"w-12 h-12 cur"} />
            <span className="text-secondary-l0 font-semibold !no-underline">
              LifeMatch
            </span>
          </Link>
        </div>

        {/* Center-aligned MapSearch with max width */}
        <div className="flex-1 space-x-[129px] flex justify-center items-end h-[60px]">
          <div
            onClick={() => navigate("/home")}
            className="text-[#ffff] text-[20px] font-sans px-3 cursor-pointer"
            style={location.pathname === "/home" ? activeStyle : {}}
          >
            Map mode
          </div>
          <div
            onClick={() => navigate("/listing")}
            className="text-[#ffff] text-[20px] font-sans px-3 cursor-pointer"
            style={location.pathname === "/listing" ? activeStyle : {}}
          >
            List mode
          </div>
          <div
            onClick={() => navigate("/my-collection")}
            className="text-[#ffff] text-[20px] font-sans px-3 cursor-pointer"
            style={location.pathname === "/my-collection" ? activeStyle : {}}
          >
            My collection
          </div>
        </div>

        <div className="flex flex-row">
          <div className="flex flex-row items-center gap-3">
            <div className="flex justify-center items-center gap-2">
              <IconCircular
                alt={"language"}
                className={"w-10 h-10 cursor-pointer"}
                key={"language"}
                src={"https://picsum.photos/200"}
              />
              <div>
                <div className="text-[#fff] font-bold text-xs">
                  {currentUser?.user_metadata?.name}
                </div>
                <div className="text-[#fff] text-xs">Premium level</div>
              </div>
            </div>
            <FiMoreVertical
              className="text-secondary-l0 cursor-pointer"
              size={30}
              onClick={handleMoreClick}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="flex flex-col p-2">
                <div
                  onClick={handleChangePass}
                  className="text-xs cursor-pointer mb-2"
                >
                  Change password
                </div>
                <Divider orientation="vertical" flexItem />
                <div
                  onClick={handleLogout}
                  className="text-xs cursor-pointer mb-2"
                >
                  Logout
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

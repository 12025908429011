import React, { useEffect, useState } from "react";
import { Card, Typography, Checkbox } from "@mui/material";
import { listType } from "../../common/Helpers";
import nightIcon from "../../assets/icons/svg/night-new.svg";
import shorttermIcon from "../../assets/icons/svg/shortterm-new.svg";
import rentIcon from "../../assets/icons/svg/rent-new.svg";
import buyIcon from "../../assets/icons/svg/buy-new.svg";
import {
  clearHotelFilters,
  clearShortTermFilters,
  clearRentFilters,
  clearBuyFilters,
  setMainFilter,
  setHotelFilters,
  setRentFilters,
  setBuyFilters,
} from "@/store/Filter/filterReducers";
import {
  clearMonthlyFilters,
  setMonthlyFilters,
} from "@/store/Filter/filterReducers";
import { removeListingByType } from "@/store/Listing/listingAction";
import { useDispatch, useSelector } from "react-redux";

const StaySelection = ({ onSelectStayType, defaultSelected }) => {
  const [selected, setSelected] = useState(defaultSelected);
  const filters = useSelector((state) => state.filter.filters || {});
  const dispatch = useDispatch();

  useEffect(() => {
    defaultSelected && setSelected(defaultSelected);
  }, [defaultSelected]);

  const options = [
    {
      filterKey: "hotels",
      label: "Night",
      icon: nightIcon,
    },
    {
      filterKey: "monthlyStay",
      label: "Short term",
      icon: shorttermIcon,
    },
    {
      filterKey: "rent",
      label: "Rent",
      icon: rentIcon,
    },
    {
      filterKey: "buy",
      label: "Buy",
      icon: buyIcon,
    },
  ];

  const handleSelect = (id) => {
    if (!selected?.[id]) {
      handleApplyFilter(id, filters?.[id]);
    } else {
      handleClearFilter(id);
    }
    setSelected({ ...selected, [id]: !selected?.[id] });
    onSelectStayType({ ...selected, [id]: !selected?.[id] });
  };

  const handleClearFilter = (filterKey) => {
    const actionMap = {
      hotels: clearHotelFilters,
      shortTerm: clearShortTermFilters,
      monthlyStay: clearMonthlyFilters,
      rent: clearRentFilters,
      buy: clearBuyFilters,
    };
    const action = actionMap[filterKey];
    dispatch(removeListingByType(filterKey));
    if (action) {
      dispatch(action());
    }
  };

  const handleApplyFilter = (filterKey, values) => {
    const actionMap = {
      hotels: setHotelFilters,
      monthlyStay: setMonthlyFilters,
      rent: setRentFilters,
      buy: setBuyFilters,
    };
    const action = actionMap[filterKey];
    if (action) {
      dispatch(action({ ...values, isRefatched: true }));
      dispatch(setMainFilter(filterKey));
    }
  };

  return (
    <div className="w-full flex items-center justify-center ">
      {options.map((option, i) => (
        <div
          onClick={() => handleSelect(option.filterKey)}
          key={option.filterKey}
          style={{
            background: selected?.[option.filterKey]
              ? listType?.[option.filterKey]?.color
              : "#fff",
            color: selected?.[option.filterKey] ? "#fff" : "#000",
          }}
          className={`flex items-center cursor-pointer font-sans text-[16px] font-light py-[22px] px-[20px] ${
            i === 0 && "rounded-l-[20px]  "
          } ${i === 3 && "rounded-r-[20px] "}`}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default StaySelection;

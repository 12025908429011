import React, { useEffect, useState } from "react";
import drawIcon from "../../../assets/icons/svg/draw-icon.svg";
import mapTypeIcon from "../../../assets/icons/svg/map-type.svg";
import { Draw, FilterAlt, Map } from "@mui/icons-material";

const Tools = ({
  onDrawChange,
  clear,
  showMapType,
  toggleDrawer,
  isListingScreen,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(!isChecked);
    onDrawChange(!isChecked);
  };

  useEffect(() => {
    if (!clear) setIsChecked(false);
  }, [clear]);

  return (
    <div className="flex">
      <div
        className={`gap-4 flex  items-center text-[#fff] font-sans font-light text-xs ${
          isListingScreen && "flex-col mt-[80px] mr-[-40px]"
        }`}
      >
        {/* <div
          className="flex gap-1 justify-center text-[#000] items-center bg-[#fff] rounded-[6px] cursor-pointer p-2"
          onClick={handleCheckboxChange}
        >
          <Draw style={{ fontSize: "20px", fill: "#1351BC" }} />
          <div>Draw area</div>
        </div> */}
        <div
          className="flex gap-1 justify-center text-[#000] items-center bg-[#fff] rounded-[6px] cursor-pointer p-2"
          onClick={showMapType}
        >
          <Map style={{ fontSize: "20px", fill: "#1351BC" }} />
          <div>Map type</div>
        </div>
        {!isListingScreen && (
          <div
            className="flex gap-1 justify-center text-[#000] items-center bg-[#fff] rounded-[6px] cursor-pointer p-2"
            onClick={toggleDrawer}
          >
            <FilterAlt style={{ fontSize: "20px", fill: "#1351BC" }} />
            <div>Filter</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tools;

import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import metroIcon from "../../assets/icons/svg/metro-station.svg";
import DirectionIcon from "@mui/icons-material/Directions";
import mapTypeIcon from "../../assets/icons/svg/map-type.svg";
import IconCircular from "../../components/IconCircular/IconCircular";
import {
  getClosestMetroDistance,
  getDistanceFromLatLonInKm,
  parseReviewString,
  trimString,
} from "../../common/Helpers";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import dayjs from "dayjs";
import BestRoute from "./BestRoute";
import {
  DeleteOutline,
  FavoriteBorder,
  OpenInBrowser,
} from "@mui/icons-material";

const MobileListingAirbnbItem = ({
  property,
  typeMapData,
  majorLocation,
  cityCenter,
  toggleAvailabilityView,
  metroStation,
}) => {
  const { fetchDirections, directions } = useGooglePlaces();
  const [isOpenRoute, setRoute] = useState(false);
  const {
    thumbnail,
    propertyName,
    propertyClass,
    priceInfoContent,
    listingKey,
    review_score_word,
    price,
    listing,
    listingParamOverrides,
  } = property.properties;
  const geometry = property?.geometry;
  const { checkin, checkout } = listingParamOverrides || "";

  useEffect(() => {
    fetchDirections(
      { lat: geometry?.coordinates?.[0], lng: geometry?.coordinates?.[1] },
      { lat: majorLocation?.latitude, lng: majorLocation?.longitude }
    );
  }, []);

  function toggleRoute() {
    setRoute(!isOpenRoute);
  }

  const daysBetween =
    checkout && checkin ? dayjs(checkout).diff(dayjs(checkin), "day") : 0;
  const actualPrice = price ? price.replace(/\D/g, "") : price;
  const pricePerDay = Number(actualPrice) / daysBetween;
  const review = review_score_word ? parseReviewString(review_score_word) : "";

  return (
    <React.Fragment>
      {/* Details Section */}
      <div
        className="mb-3 w-full"
        onClick={() => toggleAvailabilityView(property)}
      >
        <div className="flex  w-full relative ">
          <div className="w-full flex flex-col">
            <div
              style={{ background: typeMapData?.[listingKey]?.color }}
              className="py-[15px] px-2 flex w-full "
            >
              <div className="flex justify-between items-center w-full ">
                <div className="flex flex-col gap-1">
                  <div className="text-[16px] text-[#FBFCFF]">
                    {trimString(propertyName, 40)}
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <div className="text-[#fff]">
                    <div className="text-[22px] font-medium font-sans">
                      {review?.score}
                    </div>
                    {propertyClass && (
                      <div className="flex">
                        {[...Array(propertyClass)].map((_, i) => (
                          <StarIcon
                            key={i}
                            fontSize="small"
                            style={{ color: "#FFD700" }}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col mr-4 text-[#fff]">
                    <div className="text-[22px] font-sans font-medium">
                      {review?.review_nr}
                    </div>
                    <div>Reviews</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-[240px] relative">
              <img
                src={thumbnail}
                alt={propertyName}
                className="h-[240px] w-full object-fill "
              />
              <div className="flex justify-center items-center w-full absolute top-2 z-50">
                <div className="flex w-[50%] px-2 py-1 justify-center items-center gap-1 border border-solid border-[#AEA3A3] shadow bg-[#fff] text-[#000] font-sans rounded-10">
                  <div className="text-[18px] font-sans font-medium text-[#093BA5]">
                    {Math.round(pricePerDay)} $/night
                  </div>
                </div>
              </div>
              <div className="absolute top-3 right-3">
                <img alt="area" src={mapTypeIcon} />
              </div>
              <div className="absolute bottom-[5px] flex items-center w-full py-2 justify-between bg-[#170E0E]/50 text-[12px] text-[#fff] font-light">
                <div className="flex items-center ml-2">
                  <div className="w-[30px] h-[30px] ">
                    <IconCircular
                      alt={"locaton"}
                      key={"location"}
                      src={
                        majorLocation?.image ||
                        "https://picsum.photos/200?random=5"
                      }
                    />
                  </div>
                  <span>
                    {getDistanceFromLatLonInKm(
                      majorLocation?.latitude,
                      majorLocation?.longitude,
                      geometry?.coordinates?.[0],
                      geometry?.coordinates?.[1]
                    ).toFixed(1)}{" "}
                    km to {majorLocation?.locationName}
                  </span>
                  <DirectionIcon
                    onClick={toggleRoute}
                    className="text-primary cursor-pointer"
                  />
                </div>
                {metroStation?.length > 0 && (
                  <div className="text-[12px] flex items-center mr-2">
                    <div className="rounded-full w-6 h-6 mr-2 flex items-center">
                      <img
                        alt="metro"
                        src={metroIcon}
                        className="rounded-full  w-4 h-4"
                      />
                    </div>
                    {getClosestMetroDistance(metroStation, {
                      lat: geometry?.coordinates?.[0],
                      lng: geometry?.coordinates?.[1],
                    }).toFixed(1)}{" "}
                    km Metro
                  </div>
                )}
              </div>
            </div>

            <div className="flex text-[#0A2470] text-[16px]">
              {listing?.formattedBadges?.length > 0 &&
                listing.formattedBadges.map((badge, i) => (
                  <div
                    key={i}
                    className="p-2"
                    style={{ background: i % 2 === 0 ? "#FB9FB6" : "#FECEDA" }}
                  >
                    {badge?.text}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full items-center mt-2">
          <div className="ml-2 text-[10px]">
            <DeleteOutline sx={{ fontSize: 32, color: "red" }} />
          </div>
          <div className=" flex">
            <div
              onClick={() => toggleAvailabilityView(property)}
              style={{ background: typeMapData?.[listingKey]?.color }}
              className="flex  cursor-pointer items-center no-underline p-2 gap-2 border shadow border-solid border-[#fff]  rounded-[5px] text-[#fff] text-[15px] font-sans font-medium"
            >
              <div>See availability</div>
              <OpenInBrowser />
            </div>
          </div>
          <div className="mr-2 text-[10px]">
            <FavoriteBorder sx={{ fontSize: 32, color: "#56B221" }} />
          </div>
        </div>
      </div>
      {isOpenRoute && (
        <BestRoute
          open={isOpenRoute}
          toggleRoute={toggleRoute}
          majorLocation={majorLocation}
          directions={directions}
          latlng={geometry?.coordinates}
        />
      )}
    </React.Fragment>
  );
};
export default MobileListingAirbnbItem;

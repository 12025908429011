import React, { useEffect } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Bedtime, CalendarMonth, Hotel } from "@mui/icons-material";
import keysIcon from "../../assets/icons/svg/keys.svg";
import { useSelector } from "react-redux";
import ListingRentBuyItem from "./ListingRentBuyItem";
import ListingHotelItem from "./ListingHotelItem";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import ListingAirbnbItem from "./ListingAirbnbItem";
import MobileListingHotelItem from "./MobileListingHotelItem";
import MobileListingAirbnbItem from "./MobileListingAirbnbItem";
import MobileListingRentBuyItem from "./MobileListingRentBuyItem";

const MobileListingsView = ({ toggleAvailabilityView }) => {
  const {
    cityCenter,
    fetchCityCenter,
    fetchPlaces,
    places: metroStation,
  } = useGooglePlaces();
  const filterState = useSelector((state) => state.filter);
  const listings = useSelector((state) => state.hotels?.hotels);
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const majorLocation =
    myLocation?.length > 0
      ? myLocation.filter((loc) => loc.isMajor)?.[0]
      : null;

  useEffect(() => {
    if (filterState?.searchText?.text) {
      fetchCityCenter(filterState?.searchText?.text);
    }
    if (filterState?.latitude && filterState?.longitude) {
      fetchPlaces(
        "",
        { lat: filterState?.latitude, lng: filterState?.longitude },
        50,
        "subway_station"
      );
    }
  }, [filterState?.searchText, filterState?.latitude]);

  const typeMapData = {
    hotels: {
      text: "Per night",
      icon: (
        <Hotel
          sx={{
            color: "#fff",
            fontSize: 20,
          }}
        />
      ),
      color: "#0BD6F5",
    },
    Hotel: {
      text: "Per night",
      icon: (
        <Hotel
          sx={{
            color: "#fff",
            fontSize: 20,
          }}
        />
      ),
      color: "#0BD6F5",
    },
    monthlyStay: {
      text: "Short term",
      icon: (
        <Bedtime
          sx={{
            color: "#fff",
            fontSize: 20,
          }}
        />
      ),
      color: "#FF0242",
    },
    rent: {
      text: "Rent",
      icon: (
        <CalendarMonth
          sx={{
            color: "#fff",
            fontSize: 20,
          }}
        />
      ),
      color: "#EF990E",
    },
    buy: {
      text: "Buy",
      icon: <img src={keysIcon} alt="key" />,
      color: "#2ab529",
    },
  };

  return (
    <div className="flex-1 overflow-y-auto  mb-[5rem]">
      {listings?.features?.length > 0 &&
        listings.features.map((list, index) => (
          <div
            key={`${list?.id}${index}`}
            className="flex bg-[#fff] rounded-lg shadow-lg border border-solid border-[#E7E7E7] overflow-hidden"
          >
            {["rent", "buy"].includes(list?.properties?.listingKey) ? (
              <MobileListingRentBuyItem
                toggleAvailabilityView={toggleAvailabilityView}
                property={list}
                typeMapData={typeMapData}
                majorLocation={majorLocation}
                cityCenter={cityCenter}
                metroStation={metroStation}
              />
            ) : list?.properties?.listingKey === "monthlyStay" ? (
              <MobileListingAirbnbItem
                toggleAvailabilityView={toggleAvailabilityView}
                property={list}
                typeMapData={typeMapData}
                majorLocation={majorLocation}
                cityCenter={cityCenter}
                metroStation={metroStation}
              />
            ) : (
              <MobileListingHotelItem
                toggleAvailabilityView={toggleAvailabilityView}
                property={list}
                typeMapData={typeMapData}
                majorLocation={majorLocation}
                cityCenter={cityCenter}
                metroStation={metroStation}
              />
            )}
          </div>
        ))}
    </div>
  );
};
export default MobileListingsView;

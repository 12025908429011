import React, { useRef, useState } from "react";
import { Card, CardMedia, Typography, Popover } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  deleteMylocationAction,
  updateMylocationAction,
} from "../../store/User/userAction";
import { getCurrentUser } from "../../common/Helpers";

const MylocationList = ({
  myLocation,
  toggleLocation,
  setToggleAddModal,
  isOpenAddModal,
  isMobile,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [longPressTimeout, setLongPressTimeout] = useState(null);
  const buttonRefs = useRef([]);
  const currentUser = getCurrentUser();

  const handleLongPressStart = (e, index, location) => {
    e.preventDefault();
    const timeout = setTimeout(() => {
      setAnchorEl(buttonRefs.current[index]);
      setSelectedLocation(location);
    }, 600); // 600ms for long press
    setLongPressTimeout(timeout);
  };

  const handleLongPressEnd = () => {
    if (longPressTimeout) {
      clearTimeout(longPressTimeout);
      setLongPressTimeout(null);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedLocation(null);
  };

  const handleMajorLocation = () => {
    dispatch(
      updateMylocationAction(currentUser?.id, selectedLocation?._id, {
        isMajor: true,
      })
    );
    handleClosePopover();
  };

  const handleDeleteLocation = () => {
    dispatch(deleteMylocationAction(currentUser?.id, selectedLocation?._id));
    handleClosePopover();
  };

  const toggleMyLocation = (location) => {
    toggleLocation(location);
  };

  const open = Boolean(anchorEl);
  const id = open ? "long-press-popover" : undefined;

  return (
    <div className="bg-[#080505] flex justify-center w-full ">
      <div
        className="flex  flex-col w-full overflow-y-auto hide-scrollbar text-[#fff]"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <div
          onClick={() => setToggleAddModal(!isOpenAddModal)}
          className={`flex flex-col items-center justify-center cursor-pointer rounded-[5px] shadow text-center mb-3 w-full h-full max-h-[94px]`}
        >
          <div className={`${isMobile ? "text-[20px]" : "text-[34px]"}`}>+</div>
          <div className={`${isMobile ? "text-[14px]" : "text-[18px]"}`}>
            Add location
          </div>
        </div>
        {myLocation.map((location, index) => {
          return (
            <div
              className="flex flex-col gap-1 self-center cursor-pointer w-full items-center"
              key={index}
            >
              <div
                ref={(el) => (buttonRefs.current[index] = el)} // Assign ref to each card
                onMouseDown={(e) => handleLongPressStart(e, index, location)}
                onMouseUp={handleLongPressEnd}
                onTouchStart={(e) => handleLongPressStart(e, index, location)}
                onTouchEnd={handleLongPressEnd}
                onClick={() => toggleMyLocation(location)}
                className="w-[89px] h-[89px]"
              >
                <img
                  className="h-full w-full object-fill rounded-full"
                  src={location?.image}
                  alt={location.locationName}
                />
              </div>
              <div
                className={`${
                  isMobile ? "text-[14px]" : "text-[18px]"
                } my-[9px]`}
              >
                {location.locationName}
              </div>
            </div>
          );
        })}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <div className="p-4">
            <Typography
              onClick={handleMajorLocation}
              className="cursor-pointer !text-xs hover:text-gray-300 mb-2"
            >
              Major Location
            </Typography>
            <Typography
              onClick={handleDeleteLocation}
              className="cursor-pointer !text-xs hover:text-red-800"
            >
              Delete Location
            </Typography>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default MylocationList;

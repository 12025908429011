import React, { useState } from "react";
import {
  Star,
  ArrowBack,
  ArrowForward,
  Close,
  DirectionsCar,
  Train,
  DirectionsBike,
  DirectionsRun,
} from "@mui/icons-material";

const MapPlace = ({ onClose }) => {
  return (
    <div className="relative p-4 bg-white w-full max-w-md rounded-lg shadow-md">
      {/* Close Button */}
      <div className="flex items-center justify-between">
        <div className="flex flex-col items-center">
          <DirectionsCar sx={{ fontSize: 12 }} />
          <div className="text-[12px]">16 min</div>
        </div>
        <div className="flex flex-col items-center">
          <Train sx={{ fontSize: 12 }} />
          <div className="text-[12px]">16 min</div>
        </div>
        <div className="flex flex-col items-center">
          <DirectionsRun sx={{ fontSize: 12 }} />
          <div className="text-[12px]">16 min</div>
        </div>
        <div className="flex flex-col items-center">
          <DirectionsBike sx={{ fontSize: 12 }} />
          <div className="text-[12px]">16 min</div>
        </div>
        <div
          onClick={onClose}
          className="flex items-center cursor-pointer justify-center rounded-full border border-solid border-[#000] w-15px] h-[15px]"
        >
          <Close sx={{ fontSize: 15 }} />
        </div>
      </div>
    </div>
  );
};

export default MapPlace;

import React, { useEffect, useRef } from "react";

const MapWith3DMarker = ({
  lat,
  lng,
  defaultRange,
  radiusChange,
  tiltChange,
}) => {
  const mapRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    if (!mapRef.current) return;
    mapRef.current.range = parseInt(defaultRange) * 1000;
  }, [defaultRange]);

  useEffect(() => {
    const initMap = async () => {
      const { Map3DElement, Marker3DElement } =
        await window.google.maps.importLibrary("maps3d");

      mapRef.current = new Map3DElement({
        center: { lat, lng, altitude: 0 },
        heading: 110,
        tilt: 67.5,
        range: 1000,
      });

      markerRef.current = new Marker3DElement({
        position: { lat, lng },
      });

      mapRef.current.append(markerRef.current);

      const mapContainer = document.getElementById("map-3d-container");
      if (mapContainer) {
        mapContainer.innerHTML = ""; // Clear any existing map
        mapContainer.append(mapRef.current);
      }

      // Define listener functions
      const onRangeChange = () => {
        radiusChange((mapRef.current.range / 1000).toFixed(2));
      };

      const onTiltChange = () => {
        tiltChange(mapRef.current.tilt);
      };

      // Add listeners
      mapRef.current.addEventListener("gmp-rangechange", onRangeChange);
      mapRef.current.addEventListener("gmp-tiltchange", onTiltChange);

      // Cleanup function to remove listeners
      return () => {
        if (mapRef.current) {
          mapRef.current.removeEventListener("gmp-rangechange", onRangeChange);
          mapRef.current.removeEventListener("gmp-tiltchange", onTiltChange);
        }
      };
    };

    if (!mapRef.current) {
      let cleanup;
      initMap().then((cleanupFunction) => {
        cleanup = cleanupFunction;
      });
      return () => cleanup?.();
    }
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      // Update map center
      mapRef.current.center = { lat, lng, altitude: 0 };
    }
    if (markerRef.current) {
      // Update marker position
      markerRef.current.position = { lat, lng };
    }
  }, [lat, lng]);

  return (
    <div id="map-3d-container" style={{ height: "100%", width: "100%" }} />
  );
};

export default MapWith3DMarker;

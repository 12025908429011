import React from "react";
import ListingOverview from "./ListingOverview";
import MostPopularFacilities from "../../components/Listing/MosPopularFacilities";
import CustomChip from "../../components/CustomChip";

// Reusable Modal Component
const DetailContentIdealista = ({
  property,
  majorLocation,
  myLocation,
  listingDetail,
  listingMedia,
  isFullScreen = false,
  toggleFullscreen,
  isMobile,
  toggleImageView,
  toggleTypeSwitch,
  activeType,
}) => {
  const { propertyComment, moreCharacteristics, priceInfo } =
    listingDetail || "";
  const {
    constructedArea,
    roomNumber,
    bathNumber,
    chaletType,
    energyCertificationType,
    garden,
    housingFurnitures,
    status,
    swimmingPool,
    lift,
    floor,
  } = moreCharacteristics || "";

  const meaningfulFeatures = [
    { title: `Size ${constructedArea || "N/A"}` },
    { title: `Rooms ${roomNumber}` },
    { title: `${bathNumber} bathrooms` },
    { title: `${chaletType}` },
    { title: `Energy class ${energyCertificationType}` },
    { title: `${garden ? "Garden" : "No garden"}` },
    { title: `Furnished: ${housingFurnitures}` },
    { title: `${status}` },
    { title: `${swimmingPool ? "Swimming pool" : "No swimming pool"}` },
  ];
  const building = [
    { title: `${floor ? `Floor ${floor}` : "Floor N/A"}` },
    { title: `${lift ? `With elevator` : "Without elevator"}` },
  ];

  return (
    <React.Fragment>
      <ListingOverview
        property={property}
        majorLocation={majorLocation}
        myLocation={myLocation}
        listingDetail={listingDetail}
        listingMedia={listingMedia}
        toggleFullscreen={toggleFullscreen}
        isFullScreen={isFullScreen}
        isMobile={isMobile}
        toggleImageView={toggleImageView}
        toggleTypeSwitch={toggleTypeSwitch}
        activeType={activeType}
      />
      <div className="">
        <div className="mt-[20px]">
          <div
            className="!text-[14px] font-sans font-light"
            dangerouslySetInnerHTML={{
              __html: propertyComment || "",
            }}
            style={{ whiteSpace: "pre-wrap" }}
          ></div>
        </div>
      </div>

      <div className="mt-[34px]">
        <div className="text-[18px] font-sans font-medium text-[#063292]">
          Basic features
        </div>
        <MostPopularFacilities
          listingDetail={{
            facility: meaningfulFeatures,
          }}
        />
      </div>
      <div className="mt-[34px]">
        <div className="text-[18px] font-sans font-medium text-[#063292]">
          Building
        </div>
        <div className="flex gap-4 mt-4">
          {building.map((chip, i) => (
            <CustomChip key={i} text={chip?.title} chipBgClr={"#D5F0FC"} />
          ))}
        </div>
      </div>
      <div className="mt-[34px]">
        <div className="text-[18px] font-sans font-medium text-[#063292]">
          Price
        </div>
        <div className="flex gap-4 mt-4">
          <CustomChip text={"Property price:"} chipBgClr={"#EBFFE4"} />
          <CustomChip
            text={`${priceInfo?.amount} ${priceInfo?.currencySuffix}`}
            chipBgClr={"#EBFFE4"}
          />
        </div>
        <div className="flex gap-4 mt-4">
          <CustomChip text={"Price per m²:"} chipBgClr={"#EBFFE4"} />
          <CustomChip
            text={`${property?.properties?.priceByArea} €/m²`}
            chipBgClr={"#EBFFE4"}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DetailContentIdealista;

import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import GoogleRoutes from "../../components/GoogleRoutes";

const MyLocationLeftSideInfo = ({
  handleMarkerClick,
  location,
  distance,
  isMobile = false,
  activeItem,
}) => {
  return (
    <div className="w-full h-full relative mt-2">
      {/* Close Button */}
      <div className="absolute top-2 right-6">
        <div
          onClick={() => handleMarkerClick(null)}
          className="flex items-center cursor-pointer justify-center rounded-full bg-[#000] text-[#fff] w-[20px] h-[20px]"
        >
          <Close sx={{ fontSize: 15 }} />
        </div>
      </div>
      <div
        className={`mb-2 w-full ml-[10px]  ${
          isMobile ? "max-h-[200px]" : "max-h-[200px] max-w-[280px]"
        }`}
      >
        <img
          className={
            "w-full h-full max-h-[200px] max-w-[280px] object-fill rounded-10"
          }
          src={location?.image}
          alt={location?.locationName}
        />
      </div>
      <div className="flex justify-between items-center text-[14px] font-sans text-[#0A26A4] p-2">
        <div>{location?.locationName}</div>
        <div>{distance} km</div>
      </div>
      <GoogleRoutes
        geometry={{
          lat: activeItem?.properties?.latitude,
          lng: activeItem?.properties?.longitude,
        }}
        majorLocation={location}
      />
    </div>
  );
};

export default MyLocationLeftSideInfo;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { handleMapSearchAction } from "@/store/Map/mapActions";
import { setCoordinates } from "@/store/Filter/filterReducers"; // Import the setCoordinates action
import "./MapSearch.css";
import CustomAutocomplete from "./CustomAutocomplete";
import { useMap } from "@vis.gl/react-google-maps";
import { checkLocationType } from "../../../common/Helpers";

const MapSearch = ({ onPlaceChanged, filterState = null }) => {
  const dispatch = useDispatch();
  const { selectedAccommodationFilter } = useSelector((state) => state.map);
  const map = useMap();

  // Handle place selection
  const onPlaceSelect = (place, id = "") => {
    if (!place || !place.geometry || !place.geometry.location) return;
    const { lat, lng } = place.geometry.location;

    // Fit map bounds to the selected place
    map.fitBounds(place.geometry.viewport);

    onPlaceChanged(place);
    // Dispatch actions to update the map and set the coordinates in the filter reducer
    handleMapSearchAction(dispatch, {
      center: {
        lat: lat(),
        lng: lng(),
      },
      name: place.name,
      filter: selectedAccommodationFilter,
    });
    filterState &&
      localStorage.setItem(
        "userFilter",
        JSON.stringify({
          ...filterState,
          searchText: {
            text: place?.formatted_address,
            type: checkLocationType(place?.types),
            id: id,
          },
          latitude: lat(),
          longitude: lng(),
        })
      );
    // Set coordinates in the filter state
    dispatch(
      setCoordinates({
        lat: lat(),
        lng: lng(),
        searchText: {
          text: place?.formatted_address,
          type: checkLocationType(place?.types),
          id: id,
        },
        isRefetchAll: true,
      })
    );
  };

  return (
    <div className="relative flex flex-row w-auto flex-grow gap-1 mx-3">
      <CustomAutocomplete onPlaceSelect={onPlaceSelect} />
    </div>
  );
};

export default MapSearch;

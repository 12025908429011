import React from "react";
import { Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import dayjs from "dayjs";
import { OpenInBrowser, Phone } from "@mui/icons-material";
import { listType } from "../../common/Helpers";

const ListDetailFooter = ({
  activeList,
  handlePreviousListing,
  handleNextListing,
  listingDetail,
  handleFavorite,
  isMobile,
}) => {
  const { listingKey, listing, contactInfo } = activeList?.properties || "";
  const { url } = listingDetail || "";
  const phoneNumber = contactInfo?.phone1?.phoneNumberForMobileDialing;

  const handleDial = () => {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    }
  };

  return (
    <div
      className={`flex items-center  ${
        isMobile ? "w-full h-full justify-between" : "p-4 justify-between"
      } border-t border-gray-300`}
    >
      {!isMobile && (
        <Button
          onClick={handlePreviousListing}
          variant="text"
          startIcon={
            <ArrowBackIcon className="!text-[#fff] rounded-full p-1 bg-[#061E8D]" />
          }
          style={{
            color: "#000000",
            fontSize: "14px",
            fontWeight: "light",
          }}
        >
          Previous
        </Button>
      )}

      {/* Delete and Favorite Icons */}
      <IconButton>
        <DeleteIcon className="text-[#CA0000]" />
      </IconButton>
      {listingKey === "monthlyStay" && (
        <a
          style={{ background: listType?.[listingKey]?.color }}
          href={listing?.webURL}
          target="_blank"
          rel="noreferrer"
          className="flex items-center no-underline p-2 gap-2 border shadow border-solid border-[#fff]  rounded-[5px] text-[#fff] text-[20px]"
        >
          <div>Continue to Airbnb</div>
          <OpenInBrowser />
        </a>
      )}
      {["hotels", "hotel", "Hotel"].includes(listingKey) && (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className="flex items-center no-underline p-2 gap-2 border shadow border-solid border-[#fff] bg-[#015EFF] rounded-[5px] text-[#fff] text-[20px]"
        >
          <div>Continue to Booking</div>
          <OpenInBrowser />
        </a>
      )}
      {["rent", "buy"].includes(listingKey) && (
        <div
          onClick={handleDial}
          className="bg-gradient-to-r from-[#FFC002] to-[#E71010] cursor-pointer flex items-center no-underline p-2 gap-2 border shadow border-solid border-[#fff]  rounded-[5px] text-[#fff] text-[20px]"
        >
          <Phone />
          <div>View phone</div>
        </div>
      )}
      <IconButton onClick={handleFavorite}>
        <FavoriteBorderIcon className="text-[#10A922]" />
      </IconButton>

      {!isMobile && (
        <Button
          onClick={handleNextListing}
          variant="text"
          endIcon={
            <ArrowForwardIcon className="!text-[#fff] rounded-full p-1 bg-[#061E8D]" />
          }
          style={{
            color: "#000000",
            fontSize: "14px",
            fontWeight: "light",
          }}
        >
          Next
        </Button>
      )}
    </div>
  );
};

export default ListDetailFooter;

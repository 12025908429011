import React, { useEffect, useState } from "react";
import ListingOverview from "./ListingOverview";
import MostPopularFacilities from "../../components/Listing/MosPopularFacilities";
import { useDispatch, useSelector } from "react-redux";
import { getListingOverviewAction } from "../../store/Listing/listingAction";
import GuestReviews from "../../components/Listing/GuestReviews";
import HotelRules from "../../components/Listing/HotelRules";
import HotelFaqs from "../../components/Listing/HotelFaqs";
import CustomChip from "../../components/CustomChip";
import { cubeClrs } from "../../common/Helpers";

// Reusable Modal Component
const DetailContent = ({
  property,
  majorLocation,
  myLocation,
  listingDetail,
  listingMedia,
  isFullScreen = false,
  isMobile,
  toggleImageView,
  toggleTypeSwitch,
  activeType,
}) => {
  const { top_ufi_benefits } = listingDetail || "";
  const [isShowMore, setSeeMore] = useState(false);
  const dispatch = useDispatch();
  const listingOverview = useSelector((state) => state.hotels.listingOverview);

  useEffect(() => {
    if (
      property?.properties?.id !== listingOverview?.listingId &&
      (property?.properties?.accommodationType === "Hotel" ||
        property?.properties?.accommodationType === "Shortterm")
    ) {
      dispatch(
        getListingOverviewAction(
          {
            listingId: property?.properties?.id,
          },
          property?.properties?.accommodationType
        )
      );
    }
  }, [property]);

  function handleSeeMore() {
    setSeeMore(!isShowMore);
  }

  const MAX_LENGTH = 300; // Limit for initial display
  const truncatedText =
    listingOverview?.description?.length > MAX_LENGTH
      ? listingOverview.description.slice(0, MAX_LENGTH) + "..."
      : listingOverview?.description;

  const textToDisplay = isShowMore
    ? listingOverview?.description
    : truncatedText;
  return (
    <React.Fragment>
      <ListingOverview
        property={property}
        majorLocation={majorLocation}
        myLocation={myLocation}
        listingDetail={listingDetail}
        listingMedia={listingMedia}
        isFullScreen={isFullScreen}
        isMobile={isMobile}
        toggleImageView={toggleImageView}
        toggleTypeSwitch={toggleTypeSwitch}
        activeType={activeType}
      />
      <div className="w-full flex justify-center items-center gap-2 flex-wrap mt-[25px]">
        {top_ufi_benefits?.length > 0 &&
          top_ufi_benefits.map((chip, i) => (
            <CustomChip
              key={i}
              text={chip?.translated_name}
              chipBgClr={cubeClrs?.[i] || ""}
            />
          ))}
      </div>
      <div className="mt-[34px]">
        <div className="mt-[20px]">
          <div
            className="font-sans text-[20px] font-light leading-[28px] tracking-[0.05em] text-left "
            dangerouslySetInnerHTML={{
              __html: textToDisplay || "",
            }}
            style={{ whiteSpace: "pre-wrap" }}
          ></div>
          <div className="cursor-pointer" onClick={handleSeeMore}>
            {isShowMore ? "See less" : "See more"}
          </div>
        </div>
      </div>
      <div className="w-full h-[1px] bg-[#E3D7D7] my-[53px]"></div>
      <div className="mt-[34px]">
        <div
          className={`text-[18px] font-sans font-medium text-[#063292] ${
            isMobile && "text-center"
          }`}
        >
          Most popular facilities
        </div>
        <MostPopularFacilities listingDetail={listingDetail} />
      </div>

      <div className="mt-[34px]">
        <div
          className={`text-[18px] font-sans font-medium text-[#063292] ${
            isMobile && "text-center"
          }`}
        >
          Guest reviews
        </div>
        <div className="mt-[20px]">
          <GuestReviews property={property} />
        </div>
      </div>
      <div className="mt-[34px]">
        <div
          className={`text-[18px] font-sans font-medium text-[#063292] ${
            isMobile && "text-center"
          }`}
        >
          House rules
        </div>
        <div className="mt-[20px]">
          <HotelRules isMobile={isMobile} />
        </div>
      </div>
      <div className="mt-[34px]">
        <HotelFaqs isMobile={isMobile} />
      </div>
    </React.Fragment>
  );
};

export default DetailContent;

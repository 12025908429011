import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { listType } from "../../common/Helpers";
import { Room } from "@mui/icons-material";
import dayjs from "dayjs";

const HotelInfo = ({ property, majorLocation, isMobile, listingDetail }) => {
  const {
    propertyName,
    propertyClass,
    checkinDate,
    checkoutDate,
    priceBreakdown,
    priceInfoContent,
    listingKey,
    review_nr,
    review_score,
    review_score_word,
    price,
  } = property?.properties || "";
  const { address } = listingDetail || "";
  const daysBetween = dayjs(checkoutDate).diff(dayjs(checkinDate), "day");
  const totalPrice = priceBreakdown
    ? priceBreakdown?.grossPrice?.value * daysBetween
    : price;

  return (
    <React.Fragment>
      {/* Details Section */}
      <div className="flex-1 p-4 flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center">
            {!isMobile && (
              <div className="flex items-center">
                <div
                  className={`${
                    isMobile ? "text-[24px]" : "text-[32px]"
                  } font-medium text-[#2D6ADC]`}
                >
                  {propertyName}
                </div>
                {propertyClass && (
                  <div className="flex items-center">
                    {[...Array(propertyClass)].map((_, i) => (
                      <StarIcon
                        key={i}
                        fontSize="small"
                        style={{ color: "#FFD700" }}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}

            {!isMobile && (
              <div className="flex items-center gap-2">
                <div className="font-sans text-[24px] font-semibold text-[#0C3897] mr-2">
                  {listType?.[listingKey]?.text}
                </div>
                <div>
                  <div className="text-[18px] font-normal text-[#050505]">
                    {review_score_word}
                  </div>
                  <div className="text-[16px] font-normal text-[#050505]">
                    {review_nr} reviews
                  </div>
                </div>
                <div className="!bg-[#1E4BB1] !text-[#fff] px-2 py-1  text-sm font-bold rounded-md h-full">
                  {review_score}
                </div>
              </div>
            )}
          </div>
          {isMobile && (
            <React.Fragment>
              <div className="flex items-center gap-2 mt-2">
                <div className="flex flex-col w-1/2">
                  <div className="flex">
                    <div className={`text-[20px] font-medium text-[#2D6ADC]`}>
                      {propertyName}
                      {propertyClass && (
                        <div className="flex items-center">
                          {[...Array(propertyClass)].map((_, i) => (
                            <StarIcon
                              key={i}
                              fontSize="small"
                              style={{ color: "#FFD700" }}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-1/2 items-end">
                  <div className="text-[#0C6AD6] font-sans text-[20px] font-semibold">
                    {Math.round(price)} $/night
                  </div>
                  <div className="font-sans text-[20px] font-semibold text-[#0C3897] mr-2">
                    {listType?.[listingKey]?.text}
                  </div>
                </div>
              </div>
              <div className="flex justify-between my-4">
                <div>
                  <div className="text-[28px] font-sans font-bold text-[#2D7EE3]">
                    ${Math.round(totalPrice)}
                  </div>{" "}
                  <div className="text-[#061B45] text-[16px] font-light mr-3">
                    {priceInfoContent}
                  </div>
                </div>
                <div>
                  <div className="text-[18px] font-normal text-[#050505]">
                    {review_score_word}
                  </div>
                  <div className="text-[16px] font-normal text-[#050505]">
                    {review_nr} reviews
                  </div>
                </div>
                <div className="!bg-[#1E4BB1] !text-[#fff] px-2 py-1  text-sm font-bold rounded-md h-full">
                  {review_score}
                </div>
              </div>
              <div>
                {address && (
                  <div className="flex justify-center items-center gap-0 text-[14px] font-light">
                    <Room className="text-[#f00]" />
                    <div className="text-[#0C6AD6]">{address}</div>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        {!isMobile && (
          <div className="flex justify-between items-center gap-5 mt-6">
            <div>
              {address && (
                <div className="flex items-center gap-0 text-[14px] font-light">
                  <Room className="text-[#f00]" />
                  <div>{address}</div>
                </div>
              )}
            </div>
            <div>
              <span className="text-[#061B45] text-[16px] font-light mr-3">
                {priceInfoContent}
              </span>
              <span className="text-[28px] font-sans font-bold text-[#2D7EE3]">
                Total ${Math.round(totalPrice)}
              </span>{" "}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default HotelInfo;

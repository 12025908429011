import React, { useState } from "react";
import Logo from "../Logo";
import IconCircular from "../IconCircular/IconCircular";
import { FiMoreVertical } from "react-icons/fi";
import { Popover, Divider } from "@mui/material";
import { supabase } from "../../utils/helpers/supabaseClient";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../common/Helpers";
import { Map, SwapVert, Tune } from "@mui/icons-material";
import {
  setActiveFilterView,
  toggleDrawerAction,
  toggleListingMapView,
} from "../../store/Common/commonActions";

const MobileHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFilter, setFilter] = useState(false);
  const layout = useSelector((state) => state.layout.col);
  const navigate = useNavigate();
  const currentUser = getCurrentUser();

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      // Handle logout success if needed
      localStorage.removeItem("user");
    }
    handleClose(); // Close the popover after logout
  };

  function handleChangePass() {
    navigate("/reset-password");
  }

  function toggleDrawer(item) {
    dispatch(setActiveFilterView(item));
    dispatch(toggleDrawerAction());
  }

  function toggleFilter() {
    setFilter(!isFilter);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (layout === 3) {
    return null;
  }
  const filterOpt = [
    {
      filterKey: "hotels",
      label: "Night",
    },
    {
      filterKey: "monthlyStay",
      label: "Short term",
    },
    {
      filterKey: "rent",
      label: "Rent",
    },
    {
      filterKey: "buy",
      label: "Buy",
    },
  ];

  function toggleMapView() {
    dispatch(toggleListingMapView());
  }

  const activeStyle = { borderBottom: `2px solid #fff` };
  return (
    <div className="relative">
      <header className="flex flex-col bg-[#1351BC] h-[95px] min-h-[95px] text-white px-2">
        <div className="flex justify-end mt-2">
          <FiMoreVertical
            className="text-secondary-l0 cursor-pointer"
            size={30}
            onClick={handleMoreClick}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="flex flex-col p-2">
              <div
                onClick={handleChangePass}
                className="text-xs cursor-pointer mb-2"
              >
                Change password
              </div>
              <Divider orientation="vertical" flexItem />
              <div
                onClick={handleLogout}
                className="text-xs cursor-pointer mb-2"
              >
                Logout
              </div>
            </div>
          </Popover>
        </div>
        <div className="flex flex-row flex-1 w-full justify-between items-center">
          {/* Center-aligned MapSearch with max width */}
          <div className="flex-1 space-x-[12px] flex justify-center items-center">
            <div
              onClick={() => navigate("/home")}
              className="text-[#ffff] text-[12px] font-sans px-3 py-3 cursor-pointer"
              style={location.pathname === "/home" ? activeStyle : {}}
            >
              Map mode
            </div>
            <div
              onClick={() => navigate("/listing")}
              className="text-[#ffff] text-[12px] font-sans px-3 py-3 cursor-pointer"
              style={location.pathname === "/listing" ? activeStyle : {}}
            >
              List mode
            </div>
            <div
              onClick={() => navigate("/my-collection")}
              className="text-[#ffff] text-[12px] font-sans px-3 py-3 cursor-pointer"
              style={location.pathname === "/my-collection" ? activeStyle : {}}
            >
              My collection
            </div>
          </div>
        </div>
      </header>
      {location.pathname !== "/my-collection" && (
        <div className="bg-[#1357EA] p-2 text-[#fff] flex justify-between text-[16px] font-sans font-light">
          <div
            className="flex items-center cursor-pointer"
            onClick={toggleMapView}
          >
            <Map />
          </div>
          <div className="flex items-center">
            <SwapVert />
            <div>Sort</div>
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={toggleFilter}
          >
            <Tune />
            <div>Filter</div>
          </div>
        </div>
      )}
      {isFilter && (
        <div className="absolute top-[133px] right-[10px] space-y-2 z-[1000] bg-[#fff]">
          {filterOpt.map((opt) => (
            <div
              onClick={() => toggleDrawer(opt)}
              key={opt.filterKey}
              className="py-2 px-4 rounded-10 hover:bg-[#dad8d8] font-sans text-[16px] cursor-pointer"
            >
              {opt.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MobileHeader;

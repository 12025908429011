import React, { useEffect, useState } from "react";
import { major, Popover } from "@mui/material";
import {
  Favorite,
  LocationOn,
  SwapVert,
  Timeline,
  TrendingDown,
  TrendingUp,
} from "@mui/icons-material";
import { setSort } from "../../store/Filter/filterReducers";
import { useDispatch, useSelector } from "react-redux";
import useGooglePlaces from "../../hooks/useGooglePlaces";

const ListingSort = ({ onSortChange }) => {
  const { cityCenter, fetchCityCenter } = useGooglePlaces();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState({
    key: "upsort_bh",
    label: "Most Popular first",
  });
  const dispatch = useDispatch();
  const filterState = useSelector((state) => state.filter);
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const majorLocation =
    myLocation?.length > 0
      ? myLocation.filter((loc) => loc.isMajor)?.[0]
      : null;

  useEffect(() => {
    if (filterState?.searchText?.text) {
      fetchCityCenter(filterState?.searchText?.text);
    }
  }, [filterState?.searchText]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (option) => {
    setSelectedOption(option);
    setAnchorEl(null);
    onSortChange && onSortChange(option);
    handleSorting(option?.key);
  };

  function handleSorting(option) {
    let sort = {
      sort: option,
      isRefatched: true,
      activeListingType: filterState.selectedMainFilters,
    };
    if (option === "distance_location") {
      sort = {
        ...sort,
        lat: majorLocation?.latitude,
        lng: majorLocation?.longitude,
        sort: "distance",
      };
    }
    if (option === "distance") {
      sort = { ...sort, lat: cityCenter?.lat, lng: cityCenter?.lng };
    }
    console.log("sort>>>", sort);
    dispatch(setSort(sort));
  }

  const sortOptions = [
    { key: "upsort_bh", label: "Most Popular first", icon: <Timeline /> },
    { key: "price", label: "Price: Lowest First", icon: <TrendingUp /> },
    {
      key: "popularity",
      label: "Price: Highest First",
      icon: <TrendingDown />,
    },
    {
      key: "distance_location",
      label: "Proximity to personal location: Closest First",
      icon: <Favorite />,
    },
    {
      key: "distance",
      label: "Proximity to City Center: Closest First",
      icon: <LocationOn />,
    },
  ];

  const open = Boolean(anchorEl);
  const id = open ? "sort-popover" : undefined;

  return (
    <div>
      <div
        onClick={handleOpen}
        className="flex cursor-pointer items-center border-[.5px] px-[20px] max-w-[280px] py-[8px] rounded-[6px] bg-[#fff] border-solid border-[#C2ACAC]"
      >
        <SwapVert />
        <span className="text-[16px] font-sans leading-4 font-light">
          Sort by: {selectedOption.label}
        </span>
      </div>

      {/* Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/* Popover Content */}
        <div className="p-4 w-72">
          {/* Options */}
          <div className="space-y-3">
            {sortOptions.map((option) => (
              <div
                onClick={() => handleChange(option)}
                key={option.key}
                className={`flex text-[12px] p-2 items-center gap-1 cursor-pointer rounded-full ${
                  option.key === selectedOption.key
                    ? "bg-[#01A3FE] text-[#fff]"
                    : "bg-[#F0F0F0]"
                }`}
              >
                <div>{option.icon}</div>
                <div>{option.label}</div>
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default ListingSort;

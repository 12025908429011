import React, { useState } from "react";
import {
  Button,
  Popover,
  IconButton,
  Radio,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SwapVert } from "@mui/icons-material";

const MobileSaveListSort = ({ onSortChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Price: Highest First");

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    setAnchorEl(null);
    onSortChange && onSortChange(event.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "sort-popover" : undefined;

  return (
    <div>
      <div
        onClick={handleOpen}
        className="flex items-center border-[.5px] px-[20px] max-w-[280px] py-[10px] rounded-[30px] border-solid border-[#C2ACAC]"
      >
        <SwapVert />
        <span className="text-[16px] font-sans leading-4 font-light">
          Sort by: {selectedOption}
        </span>
      </div>

      {/* Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/* Popover Content */}
        <div className="p-4 w-72">
          {/* Header */}
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Sort by</h3>
            <IconButton
              size="small"
              onClick={handleClose}
              className="text-blue-500"
            >
              <CloseIcon />
            </IconButton>
          </div>

          {/* Options */}
          <div className="space-y-2">
            {[
              "Price: Lowest First",
              "Price: Highest First",
              "Most Popular first",
              "Proximity to personal location: Closest First",
              "Proximity to City Center: Closest First",
            ].map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={
                  <Radio
                    checked={selectedOption === option}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label={<span className="text-sm text-blue-600">{option}</span>}
                className="w-full"
              />
            ))}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default MobileSaveListSort;

export const getUserFavoriteListUrl = "/api/user/favorites";
export const getUserBlackListUrl = "/api/user/get-black-list";
export const addFavoriteUrl = "/api/user/saved-list";
export const deleteUserFavoriteItemUrl = "/api/user/remove-item";
export const pinFavoriteItemUrl = "/api/user/pin-item";
export const saveFavoriteItemNotesUrl = "/api/user/save-favorite-item-notes";
export const blackListUrl = "/api/user/blacklist";
export const addMyLocationUrl = "/api/user/add-my-location";
export const getUserLocationUrl = "/api/user/add-my-location";
export const deleteLocationUrl = "/api/user/delete-my-location";
export const updateLocationUrl = "/api/user/update-my-location";
export const savePlaceTypesUrl = "/api/user/save-place-types";
export const updatePlaceTypesUrl = "/api/user/update-place-types";
export const getPlaceTypesUrl = "/api/user/get-place-types";
export const createGroupUrl = "/api/user/create-group";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import {
  getDistanceFromLatLonInKm,
  getStayDuration,
} from "../../../common/Helpers";
import { setActiveStepAction } from "../../../store/Map/mapActions";
import { setLayoutAction } from "../../../store/Layout/layoutAction";
import useListing from "../../../hooks/useListing";
import { setHotels } from "../../../store/Listing/listingReducers";

const getInfoContent = (feature, filterState, handleAvailabilityClick) => {
  const {
    review_score,
    price,
    thumbnail,
    propertyName,
    id,
    priceInfoContent,
    accommodationType,
  } = feature.properties;

  // Create a container for the info window content
  const content = document.createElement("div");
  content.classList.add("info-window");

  // Set basic styles for the info window
  content.style.minWidth = "400px !important"; // Ensure the width gives enough room for content
  content.style.display = "flex";
  content.style.flexDirection = "column"; // Set the main direction to column to stack vertically
  content.style.border = "1px solid #ccc";
  content.style.borderRadius = "8px";
  content.style.padding = "16px";
  content.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
  content.style.backgroundColor = "#fff";
  content.style.maxHeight = "600px"; // Adjusted to fit vertical layout

  content.innerHTML = `
    <!-- Image section (top) -->
    <div class="info-image" style="width: 100%; margin-bottom: 16px;">
        <img src="${thumbnail}" alt="Hotel Image" 
             style="width: 100%; height: auto; object-fit: cover; border-radius: 8px;">
    </div>
    
    <!-- Details section (below the image) -->
    <div class="info-content" style="display: flex; flex-direction: column; gap: 8px;">
        <!-- Hotel Name -->
        <div>
            <h3 style="font-size: 20px; 
                color: #0056b3; 
                margin-bottom: 4px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-height: 1.2;
                max-height: 2.4em;
                text-overflow: ellipsis;">${propertyName}</h3>
        </div>
        
        <!-- Review Score and Rating -->
        <div style="display: flex; justify-content: space-between; align-items: center;">
            <div style="display: flex; flex-direction: row; gap: 6px;">
                <span>${
                  review_score < 5
                    ? "Poor"
                    : review_score === 5
                    ? "Fair"
                    : "Very Good"
                }</span>
                <div style="width: min-content; color: #fff; background: #0056b3; border-radius: 4px; font-weight: bold; padding: 7px;">${review_score}</div>
            </div>
        </div>

        <!-- Price Information -->
        <div style="display: flex; flex-direction: column; align-items: flex-end;">
            <div class="info-footer" style="margin-top: auto; text-align: right;">
                <p style="font-size: 14px; margin:0px;">
                  ${
                    priceInfoContent
                      ? priceInfoContent
                      : `${getStayDuration(
                          filterState?.filters?.hotels?.startDate,
                          filterState?.filters?.hotels?.endDate
                        )}, ${filterState?.filters?.hotels?.adults} adults`
                  }
                </p>
                <p class="price" style="font-size: 20px; font-weight: bold; color: #333; margin:0px;">
                ${
                  accommodationType === "monthlyStay"
                    ? price
                    : `€ ${Math.round(price)}`
                }
                </p>
                <p style="font-size: 12px; color: #666; margin: 0px;">Includes taxes and charges</p>
            </div>
            <!-- More Info Button -->
            <a href="javascript:;" data-key="${id}" class="view-details custom-btn" 
                style="background-color: #0071c2; color: white; padding: 8px 16px; text-decoration: none; 
                border-radius: 4px; font-size: 14px; display: inline-block;">
              More info
            </a>
        </div>
    </div>
`;

  // Add event listener to the "See availability" button
  const viewDetailsBtn = content.querySelector(".view-details");
  if (viewDetailsBtn) {
    viewDetailsBtn.addEventListener("click", (event) => {
      event.preventDefault();
      // Call the handler function passed into the component
      handleAvailabilityClick(id, feature);
    });
  }

  return content;
};

const getContent = (icon, price, type, callback, distance) => {
  const typeBg = {
    Hotel: { bg: "bg-[#0BD6F5]", arrow: "border-t-[#0BD6F5]" },
    Aparthotel: { bg: "bg-[#0BD6F5]", arrow: "border-t-[#0BD6F5]" },
    Shortterm: { bg: "bg-[#0BD6F5]", arrow: "border-t-[#0BD6F5]" },
    monthlyStay: { bg: "bg-[#FF0242]", arrow: "border-t-[#FF0242]" },
    rent: { bg: "bg-[#EF990E]", arrow: "border-t-[#EF990E]" },
    buy: { bg: "bg-[#29B629]", arrow: "border-t-[#29B629]" },
  };

  const labelContent = document.createElement("div");
  labelContent.classList.add(
    "cursor-pointer",
    "relative",
    typeBg[type]?.bg || typeBg.Shortterm.bg || "bg-secondary-l0",
    "rounded-[5px]",
    "shadow-lg",
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
    "font-sans",
    "p-[0.5px]"
  );

  const arrow = document.createElement("div");
  labelContent.append(arrow);
  arrow.classList.add(
    "-z-1",
    "absolute",
    "bottom-[-10px]",
    "left-1/2",
    "transform",
    "-translate-x-1/2",
    "w-0",
    "h-0",
    "border-l-[10px]",
    "border-r-[10px]",
    "border-solid",
    typeBg[type]?.arrow || typeBg.Shortterm.arrow || "border-t-secondary-l0",
    "border-l-transparent",
    "border-r-transparent",
    "border-t-[10px]"
  );

  const innerMost = document.createElement("div");
  innerMost.classList.add(
    "flex",
    "flex-col",
    "text-center",
    "cursor-pointer",
    "p-0.5"
  );

  // Add price element
  const priceEl = document.createElement("div");
  priceEl.classList.add("text-[#000]", "font-bold", "text-[14px]");
  priceEl.innerHTML = price;
  innerMost.append(priceEl);

  // Add distance element
  if (distance) {
    const distanceEl = document.createElement("div");
    distanceEl.classList.add(
      "text-[#000]",
      "text-[12px]",
      "mt-1",
      "font-light"
    );
    distanceEl.innerHTML = distance;
    innerMost.append(distanceEl);
  }

  labelContent.append(innerMost);

  labelContent.addEventListener("dblclick", function (e) {
    callback();
  });

  return labelContent;
};

const LMGeojson = ({
  geojson,
  selectBounds,
  showDetailToggle,
  activeRadiusLocation,
}) => {
  const filterState = useSelector((state) => state.filter);
  const { showDetail } = useListing();
  const map = useMap();
  const dispatch = useDispatch();
  const markerLib = useMapsLibrary("marker"); // Load the marker library
  const markersRef = useRef([]); // Ref to store markers
  const [markersMap, setMarkersMap] = useState(new Map());
  const bounds = useMemo(() => new window.google.maps.LatLngBounds(), []);
  const activeStep = useSelector((state) => state.map.activeStep);

  const infoWindow = useRef(new window.google.maps.InfoWindow()); // Create a single info window instance

  useEffect(() => {
    // Clean up previous markers
    if (!activeStep && geojson?.features?.length > 0) {
      setActiveStepAction(dispatch, geojson?.features?.[0]?.id);
    }
    markersRef.current.forEach((marker) => marker.setMap(null));
    markersRef.current = [];
    if (markersMap) markersMap.clear();

    if (!map || !markerLib) return;

    const markers = filterBaseOnBounds().map((feature) => {
      const [lat, lng] = feature.geometry.coordinates;
      const position = new window.google.maps.LatLng(lat, lng);
      bounds.extend(position); // Extend bounds to include this position

      const { price, thumbnail, accommodationType } = feature?.properties;
      const marker = new markerLib.AdvancedMarkerElement({
        position,
        map,
        content: getContent(
          thumbnail,
          accommodationType === "monthlyStay"
            ? price
            : `€ ${Math.round(price)}`,
          accommodationType,
          () => {
            openDetail(feature?.id, feature);
          },
          feature?.distance
        ),
      });

      const openDetail = (id, listing) => {
        setActiveStepAction(dispatch, id);
        setLayoutAction(dispatch, 1);
        showDetail(listing);
      };
      marker.addListener("click", () => {
        showDetailToggle(feature);
        showDetail(feature);
      });

      marker.addListener("mouseout", () => {
        infoWindow.current.close(); // Close the info window when mouse leaves the marker
      });
      map.addListener("click", () => {
        infoWindow.current.close(); // Close the info window when mouse leaves the marker
      });

      if (markersMap)
        markersMap.set(feature?.id, { marker: marker, feature: feature });

      markersRef.current.push(marker); // Store marker reference

      return marker;
    });

    // Fit the map to the bounds of the markers
    if (!bounds.isEmpty()) {
      map.panToBounds(bounds);
    }

    // Cleanup function to remove markers when the component unmounts or geojson changes
    return () => {
      markers.forEach((marker) => marker.setMap(null));
      if (markersMap) markersMap.clear();
    };
  }, [geojson, map, markerLib, bounds, selectBounds]);

  function filterBaseOnBounds() {
    let updatedListing = [];
    (geojson?.features || []).map((feature) => {
      const [lat, lng] = feature.geometry.coordinates;
      const position = new window.google.maps.LatLng(lat, lng);
      if (selectBounds && selectBounds.contains(position)) {
        updatedListing.push({
          ...feature,
          distance: `${Math.round(
            getDistanceFromLatLonInKm(
              lat,
              lng,
              activeRadiusLocation?.latitude,
              activeRadiusLocation?.longitude
            )
          )} km`,
        });
      } else {
        updatedListing.push(feature);
      }
    });
    return updatedListing;
  }

  return null;
};

export default LMGeojson;

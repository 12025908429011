import React, { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import DirectionIcon from "@mui/icons-material/Directions";
import mapTypeIcon from "../../assets/icons/svg/map-type.svg";
import IconCircular from "../../components/IconCircular/IconCircular";
import {
  getClosestMetroDistance,
  getDistanceFromLatLonInKm,
  trimString,
} from "../../common/Helpers";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import dayjs from "dayjs";
import {
  DeleteOutline,
  FavoriteBorder,
  OpenInBrowser,
  Phone,
} from "@mui/icons-material";
import BestRoute from "./BestRoute";
import metroIcon from "../../assets/icons/svg/metro-station.svg";

const MobileListingRentBuyItem = ({
  property,
  typeMapData,
  majorLocation,
  cityCenter,
  metroStation,
  toggleAvailabilityView,
}) => {
  const {
    thumbnail,
    propertyName,
    listingKey,
    contactInfo,
    price,
    priceBreakdown,
    checkinDate,
    checkoutDate,
    status,
    bathrooms,
    propertyType,
    size,
    rooms,
    operation,
  } = property.properties || "";
  const geometry = property?.geometry;
  const { fetchDirections, directions } = useGooglePlaces();
  const [isOpenRoute, setRoute] = useState(false);
  const topB = [
    `Condition: ${status}`,
    `Bathrooms: ${bathrooms}`,
    `Rooms: ${rooms}`,
    `Size: ${size}m²`,
    `Type: ${propertyType}`,
  ];

  useEffect(() => {
    fetchDirections(
      { lat: geometry?.coordinates?.[0], lng: geometry?.coordinates?.[1] },
      { lat: majorLocation?.latitude, lng: majorLocation?.longitude }
    );
  }, []);
  function toggleRoute() {
    setRoute(!isOpenRoute);
  }
  const daysBetween = dayjs(checkoutDate).diff(dayjs(checkinDate), "day");
  const totalPrice = priceBreakdown?.grossPrice?.value * daysBetween;
  const phoneNumber = contactInfo?.phone1?.phoneNumberForMobileDialing;

  const handleDial = () => {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    }
  };

  return (
    <React.Fragment>
      {/* Details Section */}
      <div className="mb-3 w-full">
        <div className="flex  w-full relative ">
          <div className="w-full flex flex-col">
            <div
              style={{ background: typeMapData?.[listingKey]?.color }}
              className="py-[15px] px-2 flex w-full "
            >
              <div className="flex justify-between items-center w-full ">
                <div className="flex flex-col gap-1">
                  <div className="text-[16px] text-[#FBFCFF]">
                    {propertyName}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="w-full relative"
              onClick={() => toggleAvailabilityView(property)}
            >
              <img
                src={thumbnail}
                alt={propertyName}
                className="h-[240px] w-full "
              />
              <div className="flex justify-center items-center w-full absolute top-2 z-50">
                <div className="flex w-[50%] px-2 py-1 justify-center items-center gap-1 border border-solid border-[#AEA3A3] shadow bg-[#fff] text-[#000] font-sans rounded-10">
                  <div className="text-[18px] font-sans font-medium text-[#093BA5]">
                    {Math.round(price)} $/month
                  </div>
                </div>
              </div>
              <div className="absolute top-3 right-3">
                <img alt="area" src={mapTypeIcon} />
              </div>
              <div className="absolute bottom-[5px] flex items-center w-full py-2 justify-between bg-[#170E0E]/50 text-[16px] text-[#fff] font-light">
                <div className="flex items-center ml-2">
                  <div className="w-[30px] h-[30px] ">
                    <IconCircular
                      alt={"locaton"}
                      key={"location"}
                      src={
                        majorLocation?.image ||
                        "https://picsum.photos/200?random=5"
                      }
                    />
                  </div>
                  <span>
                    {getDistanceFromLatLonInKm(
                      majorLocation?.latitude,
                      majorLocation?.longitude,
                      geometry?.coordinates?.[0],
                      geometry?.coordinates?.[1]
                    ).toFixed(1)}{" "}
                    km to {majorLocation?.locationName}
                  </span>
                  <DirectionIcon
                    onClick={toggleRoute}
                    className="text-primary cursor-pointer"
                  />
                </div>
                {metroStation?.length > 0 && (
                  <div className="text-[16px] flex items-center mr-2 font-sans font-light">
                    <div className="rounded-full w-6 h-6 mr-2 flex items-center">
                      <img
                        alt="metro"
                        src={metroIcon}
                        className="rounded-full  w-4 h-4"
                      />
                    </div>
                    {getClosestMetroDistance(metroStation, {
                      lat: geometry?.coordinates?.[0],
                      lng: geometry?.coordinates?.[1],
                    }).toFixed(1)}{" "}
                    km Metro
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-3 text-[#0A2470] text-[16px]">
              {topB?.map((ch, i) => (
                <div
                  key={i}
                  className="p-2"
                  style={{
                    background:
                      i % 2 === 0
                        ? operation === "rent"
                          ? "#F2D587"
                          : "#D8FAA9"
                        : operation === "rent"
                        ? "#FA9D6F"
                        : "#A7E74E",
                  }}
                >
                  {ch}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full items-center mt-2">
          <div className="ml-2 text-[10px]">
            <DeleteOutline sx={{ fontSize: 32, color: "red" }} />
          </div>
          <div className="flex justify-end items-center relative  px-2 mt-3">
            <div className=" flex">
              <div
                onClick={handleDial}
                className={`${
                  operation === "rent"
                    ? "bg-gradient-to-r from-[#FFC002] to-[#E71010]"
                    : "bg-[#56B221]"
                } cursor-pointer flex items-center no-underline p-2 gap-2 border shadow border-solid border-[#fff]  rounded-full text-[#fff] text-[16px] font-sans font-medium`}
              >
                <Phone />
              </div>
            </div>
          </div>
          <div className="mr-2 text-[10px]">
            <FavoriteBorder sx={{ fontSize: 32, color: "#56B221" }} />
          </div>
        </div>
      </div>
      {isOpenRoute && (
        <BestRoute
          open={isOpenRoute}
          toggleRoute={toggleRoute}
          majorLocation={majorLocation}
          directions={directions}
          latlng={geometry?.coordinates}
        />
      )}
    </React.Fragment>
  );
};
export default MobileListingRentBuyItem;

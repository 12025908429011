import React from "react";

const AirbnbRatings = ({ listingDetail, isMobile }) => {
  const { valueRating, sections } = listingDetail ?? "";
  const reviews = [
    {
      name: "Gerhard",
      location: "",
      years: "5 years on Airbnb",
      date: "3 weeks ago",
      stay: "Stayed a few nights",
      review: "Great value for money",
    },
    {
      name: "Erica",
      location: "Lisbon, Portugal",
      years: "",
      date: "October 2024",
      stay: "Stayed a few nights",
      review:
        "Such a beautiful spot! Even on a rainy weekend this is a perfect place to be, surrounded by tall trees and the sounds of a stream outside...",
    },
    {
      name: "Kathy",
      location: "Ann Arbor, Michigan",
      years: "",
      date: "October 2024",
      stay: "Group trip",
      review:
        "Beautiful stay at this perfectly described home. Highly recommend if it’s the type of place you’re looking for near the national park...",
    },
    {
      name: "Antonio Julián",
      location: "",
      years: "2 years on Airbnb",
      date: "October 2024",
      stay: "Stayed a few nights",
      review:
        "The stay was wonderful, despite the weather we were able to enjoy the pool. The only one I would put is the road to the house that is not in good condition...",
    },
  ];
  const ratings = sections?.reviewsDefault?.ratings;

  return (
    <div className={`${isMobile ? "p-0" : "p-6"} w-full mx-auto`}>
      {/* Header Section */}
      <div className="text-center">
        <h1 className="text-6xl font-bold">{valueRating}</h1>
        <p className="text-lg mt-2 text-gray-600">
          {sections?.reviewsDefault?.heading?.accessibilityLabel}
        </p>
      </div>

      {/* Rating Breakdown */}
      <div className="grid md:grid-cols-6 grid-cols-2 gap-4 mt-8">
        {ratings?.length > 0 &&
          ratings.map((rat) => (
            <div className="flex flex-col items-center">
              <span className="text-sm text-gray-600">{rat?.label}</span>
              <span className="text-lg font-medium">
                {rat?.localizedRating}
              </span>
            </div>
          ))}
      </div>

      {/* Reviews Section */}
      <div className="mt-10 space-y-8 grid sm:grid-cols-2 grid-cols-1">
        {reviews.map((review, index) => (
          <div class="flex items-start space-x-4 p-4 bg-white ">
            <img
              src="https://via.placeholder.com/40"
              alt="Profile"
              class="h-10 w-10 rounded-full"
            />
            <div>
              <h4 class="font-medium text-gray-800">Gerhard</h4>
              <p class="text-sm text-gray-500">5 years on Airbnb</p>
              <div class="flex items-center text-sm text-gray-500 mt-2 space-x-2">
                <span class="flex items-center text-yellow-500">★★★★★</span>
                <span>·</span>
                <span>3 weeks ago</span>
                <span>·</span>
                <span>Stayed a few nights</span>
              </div>
              <p class="text-gray-800 mt-2">Great value for money</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AirbnbRatings;

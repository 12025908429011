import React, { useEffect, useState } from "react";
import { Checkbox, Popover, Button } from "@mui/material";
import nightIcon from "../../assets/icons/svg/night-new.svg";
import shorttermIcon from "../../assets/icons/svg/shortterm-new.svg";
import rentIcon from "../../assets/icons/svg/rent-new.svg";
import buyIcon from "../../assets/icons/svg/buy-new.svg";
import mapTypeIcon from "../../assets/icons/svg/map-type.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveFilterView,
  toggleDrawerAction,
} from "../../store/Common/commonActions";
import { getSavedFilter, listType } from "../../common/Helpers";
import {
  clearHotelFilters,
  clearShortTermFilters,
  clearRentFilters,
  clearBuyFilters,
  setMainFilter,
  setHotelFilters,
  setRentFilters,
  setBuyFilters,
} from "@/store/Filter/filterReducers";
import {
  clearMonthlyFilters,
  setMonthlyFilters,
} from "@/store/Filter/filterReducers";
import { removeListingByType } from "@/store/Listing/listingAction";
import { Close, FilterAlt, Map, SwapVert, Tune } from "@mui/icons-material";
import MapSearch from "../LeftCol/MapSearch";
import ListingSort from "../Sort/ListingSort";
import DateRangePicker from "../DateRangePick";
import dayjs from "dayjs";
import OccupancyForm from "../OccupancyForm";

const ListingMenu = ({
  isDisableDrawerClose = false,
  isMobile = false,
  isMapView = false,
  searchMode,
}) => {
  const [isLowestFirst, setSort] = useState(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState({});
  const [requestedKey, setRequestedListingKey] = useState("");
  const [selectedOption, setSelectedOption] = useState("Price: Highest First");
  let cachedFilters = getSavedFilter();
  const selectedMainFilters = useSelector(
    (state) => state.filter.selectedMainFilters || []
  );
  const filterState = useSelector((state) => state.filter || {});
  const [activeListing, setActiveListing] = useState(() => {
    if (selectedMainFilters.includes("hotels")) {
      return { hotels: true };
    }
    return {};
  });

  const menuItems = [
    {
      filterKey: "hotels",
      label: "Per night",
      icon: nightIcon,
    },
    {
      filterKey: "monthlyStay",
      label: "Month +",
      icon: shorttermIcon,
    },
    {
      filterKey: "rent",
      label: "Rent",
      icon: rentIcon,
    },
    {
      filterKey: "buy",
      label: "Buy",
      icon: buyIcon,
    },
  ];

  useEffect(() => {
    if (cachedFilters?.selectedMainFilters?.length > 0) {
      let predefineFilter = {};
      cachedFilters.selectedMainFilters.map((sF) => {
        predefineFilter = { ...predefineFilter, [sF]: true };
      });
      setActiveListing(predefineFilter);
    }
  }, []);

  function handleFilterView(item) {
    dispatch(setActiveFilterView(item));
    !isDisableDrawerClose && dispatch(toggleDrawerAction());
    setAnchorEl(null);
  }

  function handleChangeFilter(newValue) {
    setState({
      ...state,
      updatedFilter: { ...state.updatedFilter, ...newValue },
    });
  }

  function showDatePicker(filterKey) {
    setRequestedListingKey(filterKey);
    setState({ updatedFilter: filterState?.filters?.[filterKey] });
  }
  function handleRentBuy(filterKey) {
    if (!activeListing?.[filterKey]) {
      handleListingType(filterKey);
    } else {
      clearFilter(filterKey);
    }
  }
  function handleListingType(filterKey) {
    handleApplyFilter(filterKey, {
      ...filterState?.filters?.[filterKey],
      ...state.updatedFilter,
    });
    setActiveListing({
      ...activeListing,
      [filterKey]: true,
    });
    setRequestedListingKey("");
  }

  function clearFilter(filterKey) {
    handleClearFilter(filterKey);
    setActiveListing({
      ...activeListing,
      [filterKey]: false,
    });
    setRequestedListingKey("");
    setState({ updatedFilter: {} });
  }

  const handleClearFilter = (filterKey) => {
    const actionMap = {
      hotels: clearHotelFilters,
      shortTerm: clearShortTermFilters,
      monthlyStay: clearMonthlyFilters,
      rent: clearRentFilters,
      buy: clearBuyFilters,
    };
    const action = actionMap[filterKey];
    dispatch(removeListingByType(filterKey));
    if (action) {
      dispatch(action());
    }
  };

  const handleApplyFilter = (filterKey, values) => {
    const actionMap = {
      hotels: setHotelFilters,
      monthlyStay: setMonthlyFilters,
      rent: setRentFilters,
      buy: setBuyFilters,
    };
    const action = actionMap[filterKey];
    if (action) {
      dispatch(action({ ...values, isRefatched: true }));
      dispatch(setMainFilter(filterKey));
    }
    setState({ updatedFilter: {} });
  };

  const toggleDropdown = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-dropdown" : undefined;
  console.log("state", state);
  return (
    <div className="flex items-center justify-center space-x-4 bg-[#1351BC] h-[100px] shadow-md border-t border-solid border-t-[#fff]">
      <div className="w-[400px]">
        {searchMode === 1 && (
          <MapSearch
            onPlaceChanged={() => console.log("Place changed")}
            filterState={filterState}
          />
        )}
      </div>
      <div className="relative flex gap-2 my-2">
        {menuItems.map((option, i) => (
          <div
            onClick={() =>
              ["rent", "buy"].includes(option.filterKey)
                ? handleRentBuy(option.filterKey)
                : showDatePicker(option.filterKey)
            }
            key={option.filterKey}
            style={{
              background: activeListing?.[option.filterKey]
                ? listType?.[option.filterKey]?.color
                : "#fff",
              color: activeListing?.[option.filterKey] ? "#fff" : "#000",
            }}
            className={`flex items-center cursor-pointer font-sans text-[16px] font-light py-2 px-[20px] rounded-[6px] ${
              activeListing?.[option.filterKey]
                ? "border border-solid border-[#FFF0F0]"
                : ""
            }`}
          >
            {option.label}
          </div>
        ))}
        {requestedKey && (
          <div className="bg-[#fff] p-2 absolute top-12 z-[60] rounded-[6px] w-full">
            <div
              onClick={() => setRequestedListingKey("")}
              className="flex justify-end my-1 cursor-pointer"
            >
              <Close sx={{ fontSize: 16 }} />
            </div>
            <OccupancyForm
              filterKey={requestedKey}
              initialOccupancy={{
                adults: state?.updatedFilter?.adults || 1,
                children: state?.updatedFilter?.children || 0,
                rooms: state?.updatedFilter?.rooms || 1,
              }}
              onChange={handleChangeFilter}
            />
            <DateRangePicker
              filterKey={requestedKey}
              initialValue={[
                dayjs(state?.updatedFilter?.startDate),
                dayjs(state?.updatedFilter?.endDate),
              ]}
              onChange={handleChangeFilter}
            />
            <div className="flex justify-center mt-3 gap-3">
              <div
                onClick={() => clearFilter(requestedKey)}
                className="py-2 cursor-pointer bg-[#01A3FE] text-[#fff] text-[14px] text-center rounded-[6px] w-18"
              >
                Clear
              </div>
              <div
                onClick={() => handleListingType(requestedKey)}
                className="py-2 cursor-pointer bg-[#01A3FE] text-[#fff] text-[14px] text-center rounded-[6px] w-18"
              >
                Apply
              </div>
            </div>
          </div>
        )}
      </div>
      {!isMobile && !isMapView ? (
        <React.Fragment>
          <div
            className="flex gap-1 justify-center text-[#000] items-center bg-[#fff] rounded-[6px] cursor-pointer p-2"
            onClick={toggleDropdown}
          >
            <FilterAlt style={{ fontSize: "20px", fill: "#1351BC" }} />
            <div>Filter</div>
          </div>
          <div className="flex gap-1 justify-center text-[#000] items-center bg-[#fff] rounded-[6px] cursor-pointer p-2">
            <Map style={{ fontSize: "20px", fill: "#1351BC" }} />
            <div>Map type</div>
          </div>
        </React.Fragment>
      ) : (
        <div className="w-[20%]"></div>
      )}

      {/* Dropdown */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="p-4 flex flex-col gap-4">
          {menuItems.map((item, index) => (
            <Button
              key={index}
              variant="outlined"
              startIcon={
                <img src={item.icon} alt={item.label} className="w-6 h-6" />
              }
              onClick={() => handleFilterView(item)}
            >
              {item.label}
            </Button>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default ListingMenu;

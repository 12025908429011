import React, { useEffect, useState } from "react";
import { Modal, Box } from "@mui/material";
import dayjs from "dayjs";
import DetailTabs from "../../components/Listing/DetailTabs";
import ListingDetailFooter from "../../components/Listing/ListingDetailFooter";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStepAction } from "../../store/Map/mapActions";
import useListing from "../../hooks/useListing";
import { setActiveListAction } from "../../store/Common/commonActions";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import { addToFavoriteList } from "../../store/User/userAction";
import { getCurrentUser } from "../../common/Helpers";
import MobileDetailTabs from "../../components/Listing/MobileDetailTabs";

// Reusable Modal Component
const MobileListingDetail = ({ activeList, isMobile = true, onClose }) => {
  const { showDetail } = useListing();
  const { cityCenter, fetchCityCenter } = useGooglePlaces();
  const filterState = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.map.activeStep);
  const listings = useSelector((state) => state.hotels?.hotels?.features);
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const listingDetail = useSelector((state) => state.hotels.listingDetail);
  const listingMedia = useSelector((state) => state.hotels.listingMedia);
  const swipeScreenActiveListing = useSelector(
    (state) => state.hotels.swipeScreenActiveListing
  );
  const majorLocation =
    myLocation?.length > 0
      ? myLocation.filter((loc) => loc.isMajor)?.[0]
      : null;
  const [swipeList, setList] = useState(listings || []);
  const [currentListingIndex, setCurrentListingIndex] = useState(0);
  const [activeItem, setActive] = useState(activeList);
  const [isShowFullscreen, setFullscreen] = useState(false);
  const [isImageView, setImageView] = useState(false);
  const currentUser = getCurrentUser();

  useEffect(() => {
    fetchCityCenter(filterState?.searchText?.text);
  }, []);

  useEffect(() => {
    setList(listings || []);
  }, [listings]);

  useEffect(() => {
    if (activeStep) {
      renderDetail();
    }
  }, [activeStep]);

  function renderDetail() {
    const listingsList = listings;
    let activeListing = null;
    if (listingsList?.length > 0) {
      activeListing = activeStep
        ? listingsList.find((item) => item.id === activeStep)
        : listingsList?.[0];
    } else {
      activeListing = activeList;
    }
    if (!activeListing) {
      if (
        listingDetail?.listingType === "rent" ||
        listingDetail?.listingType === "buy"
      ) {
        activeListing = {
          properties: {
            ...listingDetail,
            accommodationType: listingDetail?.listingType,
            latitude: listingDetail?.ubication?.latitude,
            longitude: listingDetail?.ubication?.longitude,
          },
        };
      } else if (listingDetail?.listingType === "monthlyStay") {
        console.log("listingss", listingDetail);
        activeListing = {
          properties: {
            ...listingDetail,
            accommodationType: listingDetail?.listingType,
            listingKey: listingDetail?.listingType,
            thumbnail: listingDetail?.imageUrl,
            id: swipeScreenActiveListing?.properties?.id,
            propertyName: swipeScreenActiveListing?.properties?.name,
            price: swipeScreenActiveListing?.properties?.price,
            review_score: swipeScreenActiveListing?.properties?.review_score,
            badge: swipeScreenActiveListing?.properties?.review_word,
            latitude: listingDetail?.listingLat,
            longitude: listingDetail?.listingLng,
          },
        };
      } else {
        activeListing = {
          properties: {
            ...listingDetail,
            accommodationType: listingDetail?.listingType,
            listingKey: listingDetail?.listingType,
            id: listingDetail?.hotel_id,
            review_score: swipeScreenActiveListing?.properties?.review_score,
            badge: swipeScreenActiveListing?.properties?.review_word,
          },
        };
      }
    }

    setActive(activeListing);
    dispatch(setActiveListAction(activeListing));
  }

  const handleNextListing = () => {
    if (swipeList?.length === currentListingIndex + 1) {
      setActiveStepAction(dispatch, swipeList[0]?.id);
      setCurrentListingIndex(0);
      showDetail(swipeList?.[0]);
    } else {
      const nextIndex = currentListingIndex + 1;
      setActiveStepAction(dispatch, swipeList[nextIndex]?.id);
      setCurrentListingIndex(nextIndex);
      showDetail(swipeList?.[nextIndex]);
    }
  };

  const handlePreviousListing = () => {
    if (currentListingIndex === 0) {
      setActiveStepAction(
        dispatch,
        swipeList?.length > 0
          ? swipeList?.[swipeList?.length - 1]?.id
          : swipeList?.[0]?.id
      );
      setCurrentListingIndex(0);
      showDetail(swipeList?.[0]);
    } else {
      const prevIndex = currentListingIndex - 1;
      setActiveStepAction(dispatch, swipeList?.[prevIndex]?.id);
      setCurrentListingIndex(prevIndex);
      showDetail(swipeList?.[prevIndex]);
    }
  };
  function toggleFullscreen() {
    setFullscreen(!isShowFullscreen);
  }
  function toggleImageView() {
    setImageView(!isImageView);
  }

  const handleFavorite = () => {
    const property = activeItem || activeList;
    const {
      id,
      price,
      thumbnail,
      propertyName,
      review_score,
      review_nr,
      priceInfoContent,
      listingKey,
      badge,
      latitude,
      longitude,
      priceBreakdown,
      checkinDate,
      checkoutDate,
    } = property?.properties || {};
    const daysBetween = dayjs(checkoutDate).diff(dayjs(checkinDate), "day");
    const totalPrice = priceBreakdown?.grossPrice?.value * daysBetween;
    dispatch(
      addToFavoriteList({
        userId: currentUser?.id,
        itemData: {
          isPinned: false,
          listingId: id,
          propertyName: propertyName,
          phoneNumber: listingDetail?.phoneNumber || "",
          price,
          thumbnail: thumbnail,
          totalPrice: totalPrice,
          review_score: review_score,
          review_word: badge,
          total_review: review_nr,
          description: priceInfoContent,
          listingKey: listingKey,
          class: property?.properties?.class,
          lat: latitude,
          lng: longitude,
          url:
            property?.properties?.listing?.webURL || listingDetail?.url || "",
          country: property?.properties?.country,
          latlng: [latitude, longitude],
        },
      })
    );
  };
  console.log("detailViewwwwwww", {
    properties: {
      ...activeItem.properties,
      listingKey: activeItem?.properties?.propertyType,
    },
  });
  return (
    <React.Fragment>
      <Box className="relative bg-[#fff] w-full shadow-lg h-full">
        {/* Content Section */}
        <div className="w-full flex h-full">
          <MobileDetailTabs
            onClose={onClose}
            property={activeItem || activeList}
            majorLocation={majorLocation}
            myLocation={myLocation}
            listingDetail={listingDetail}
            listingMedia={listingMedia}
            toggleFullscreen={toggleFullscreen}
            toggleImageView={toggleImageView}
            cityCenter={cityCenter}
            isMobile={isMobile}
            isImageView={isImageView}
          />
        </div>

        {/* Footer Section */}
        <div
          className={`absolute bottom-0 bg-[#fff] ${
            isMobile ? "w-full" : "w-[98%]"
          }`}
        >
          <ListingDetailFooter
            activeList={{
              properties: {
                ...activeItem.properties,
                listingKey: activeItem?.properties?.propertyType,
              },
            }}
            swipeList={swipeList}
            handleNextListing={handleNextListing}
            handlePreviousListing={handlePreviousListing}
            listingDetail={listingDetail}
            handleFavorite={handleFavorite}
            isMobile={isMobile}
          />
        </div>
      </Box>
    </React.Fragment>
  );
};

export default MobileListingDetail;

import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowBack, UTurnLeft } from "@mui/icons-material";
import DetailContent from "../../views/Listings/DetailContent";
import DetailContentAirbnb from "../../views/Listings/DetailContentAirbnb";
import DetailContentIdealista from "../../views/Listings/DetailContentIdealista";
import ListingMyLocation from "../../views/Listings/ListingMyLocation";
import NeighborhoodTab from "../../views/Listings/NeighborhoodTab";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import BirdEyeTab from "../../views/Listings/BirdEyeTab";
import StreetTab from "../../views/Listings/StreetTab";
import PlacesTab from "../../views/Listings/PlacesTab";
import PlacesView from "../../views/Area/Places/PlacesView";
import MobileNeighborhoodTab from "../../views/Listings/MobileNeighborhoodTab";
import MobileListingMyLocation from "../../views/Listings/MobileListingMyLocation";
import { listType } from "../../common/Helpers";
import ListingDetailFullscreen from "../../views/Listings/ListingDetailFullscreen";

const DetailTabs = (props) => {
  const { onClose, property, isMobile, isImageView, handleChangeTab } = props;
  const [activeType, setType] = useState("photo");
  const [selectedTab, setSelectedTab] = useState(1);
  const { neighborhood, fetchNeighborhood } = useGooglePlaces();
  const geometry = property?.geometry;

  useEffect(() => {
    fetchNeighborhood(geometry?.coordinates?.[0], geometry?.coordinates?.[1]);
  }, [geometry]);
  const handleChange = (event, newValue) => {
    if (newValue === 0 && !isMobile) {
      onClose();
    }
    setSelectedTab(newValue);
    handleChangeTab && handleChangeTab(newValue);
  };

  function toggleTypeSwitch(selectedType) {
    setType(selectedType);
  }

  const tabData = [
    { id: "back", label: "" },
    { id: "overview", label: "Overview" },
    { id: "myLocation", label: "My locations" },
    { id: "neighbr", label: "The neighborhood" },
    { id: "places", label: "Places 360" },
    { id: "bird", label: "Bird’s eye" },
    { id: "street", label: "Walk in the streets" },
  ];
  console.log("detailTabssss", property);
  return (
    <div className="w-full h-full relative">
      <div
        style={{
          background:
            listType?.[
              property?.properties?.accommodationType ||
                property?.properties?.propertyType
            ]?.color,
        }}
      >
        <div
          onClick={onClose}
          className="flex cursor-pointer gap-1 text-[15px] text-[#fff] items-center p-2"
        >
          <UTurnLeft className="rotate-90" sx={{ fontSize: "20px" }} />
        </div>
        <div
          className={` w-full flex justify-between items-center gap-4  !shadow-lg overflow-x-auto hide-scrollbar text-[#fff]`} // Tailwind styling for bottom border
        >
          {tabData.map((tab, index) => (
            <div
              key={tab.id}
              onClick={() => handleChange(null, index)}
              style={{
                borderBottom: selectedTab === index ? "3px solid #fff" : "",
              }}
              className={`px-4 pb-2 flex items-center cursor-pointer justify-center whitespace-nowrap ${
                isMobile ? "!text-[14px]" : "!text-[18px]"
              } !normal-case !font-normal pb-2 !text-[#fff] `}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>
      {isImageView ? (
        isMobile ? (
          <div className="flex justify-center">
            <div className={"w-[95%] h-[300px] mt-2 relative"}>
              <div
                onClick={props.toggleImageView}
                className="absolute top-1 left-0 bg-[#000]/20"
              >
                <UTurnLeft className="rotate-90 text-[#fff]" />
              </div>
              <div
                className="w-full flex flex-col gap-[6px] overflow-y-auto hide-scrollbar"
                style={{ height: "calc(100vh - 160px)" }}
              >
                {props.listingMedia?.length > 0 &&
                  props.listingMedia.map((image, index) => (
                    <div key={index} className="cursor-pointer  rounded-[4px]">
                      <img
                        src={image?.url_1440 || image?.url || image?.baseUrl}
                        alt={`Thumbnail ${index + 1}`}
                        className="w-full h-[300px]  object-cover rounded-lg"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          <ListingDetailFullscreen
            toggleImageView={props.toggleImageView}
            listingMedia={props.listingMedia}
            listingMediaVideo={props.listingMediaVideo}
            toggleTypeSwitch={toggleTypeSwitch}
            activeType={activeType}
            listingDetail={props.listingDetail}
            property={property}
          />
        )
      ) : (
        <div className="w-full h-full flex justify-center">
          <div
            className={
              selectedTab === 1
                ? "flex justify-center py-2 overflow-y-auto hide-scrollbar h-full w-full"
                : "flex justify-center h-full w-full"
            }
            style={{
              height:
                selectedTab === 1
                  ? `calc(100vh - 170px)`
                  : "calc(100vh - 80px)",
            }}
          >
            <div
              className={
                selectedTab === 1
                  ? isMobile
                    ? "w-[95%]"
                    : "w-[70%]"
                  : "w-full"
              }
            >
              {["rent", "buy"].includes(
                property?.properties?.listingKey ||
                  property?.properties?.accommodationType
              ) ? (
                <React.Fragment>
                  {tabData[selectedTab]?.id === "overview" && (
                    <DetailContentIdealista
                      toggleTypeSwitch={toggleTypeSwitch}
                      activeType={activeType}
                      {...props}
                      isMobile={isMobile}
                    />
                  )}
                  {tabData[selectedTab]?.id === "myLocation" &&
                    (isMobile ? (
                      <MobileListingMyLocation cityCenter={props.cityCenter} />
                    ) : (
                      <ListingMyLocation isMobile={isMobile} />
                    ))}
                  {tabData[selectedTab]?.id === "neighbr" &&
                    (isMobile ? (
                      <MobileNeighborhoodTab placeName={neighborhood || ""} />
                    ) : (
                      <NeighborhoodTab placeName={neighborhood || ""} />
                    ))}
                  {tabData[selectedTab]?.id === "bird" && (
                    <BirdEyeTab isMobile={isMobile} activeItem={property} />
                  )}
                  {tabData[selectedTab]?.id === "street" && (
                    <StreetTab isMobile={isMobile} activeItem={property} />
                  )}
                  {tabData[selectedTab]?.id === "places" && (
                    <PlacesTab isMobile={isMobile} activeItem={property} />
                  )}
                </React.Fragment>
              ) : property?.properties?.listingKey === "monthlyStay" ||
                property?.properties?.accommodationType === "monthlyStay" ? (
                <React.Fragment>
                  {tabData[selectedTab]?.id === "overview" && (
                    <DetailContentAirbnb
                      toggleTypeSwitch={toggleTypeSwitch}
                      activeType={activeType}
                      {...props}
                      isMobile={isMobile}
                    />
                  )}
                  {tabData[selectedTab]?.id === "myLocation" &&
                    (isMobile ? (
                      <MobileListingMyLocation cityCenter={props.cityCenter} />
                    ) : (
                      <ListingMyLocation isMobile={isMobile} />
                    ))}
                  {tabData[selectedTab]?.id === "neighbr" &&
                    (isMobile ? (
                      <MobileNeighborhoodTab placeName={neighborhood || ""} />
                    ) : (
                      <NeighborhoodTab placeName={neighborhood || ""} />
                    ))}
                  {tabData[selectedTab]?.id === "bird" && (
                    <BirdEyeTab isMobile={isMobile} activeItem={property} />
                  )}
                  {tabData[selectedTab]?.id === "street" && (
                    <StreetTab isMobile={isMobile} activeItem={property} />
                  )}
                  {tabData[selectedTab]?.id === "places" && (
                    <PlacesTab isMobile={isMobile} activeItem={property} />
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {tabData[selectedTab]?.id === "overview" && (
                    <DetailContent
                      {...props}
                      isMobile={isMobile}
                      toggleTypeSwitch={toggleTypeSwitch}
                      activeType={activeType}
                    />
                  )}
                  {tabData[selectedTab]?.id === "myLocation" &&
                    (isMobile ? (
                      <MobileListingMyLocation cityCenter={props.cityCenter} />
                    ) : (
                      <ListingMyLocation isMobile={isMobile} />
                    ))}
                  {tabData[selectedTab]?.id === "neighbr" &&
                    (isMobile ? (
                      <MobileNeighborhoodTab placeName={neighborhood || ""} />
                    ) : (
                      <NeighborhoodTab placeName={neighborhood || ""} />
                    ))}
                  {tabData[selectedTab]?.id === "bird" && (
                    <BirdEyeTab isMobile={isMobile} activeItem={property} />
                  )}
                  {tabData[selectedTab]?.id === "street" && (
                    <StreetTab isMobile={isMobile} activeItem={property} />
                  )}
                  {tabData[selectedTab]?.id === "places" && (
                    // <PlacesTab activeItem={property} />
                    <PlacesView activeItem={property} isMobileScreen={false} />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailTabs;

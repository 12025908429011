import dayjs from "dayjs";
import { hotelFacilitiesMapping } from "../views/Swipe/facilityMapping";

export function getHashParams() {
  const hash = window.location.hash.substring(1); // Remove the leading #
  const params = new URLSearchParams(hash);
  return {
    accessToken: params.get("access_token"),
    expiresAt: params.get("expires_at"),
    expiresIn: params.get("expires_in"),
    refreshToken: params.get("refresh_token"),
    tokenType: params.get("token_type"),
    type: params.get("type"),
  };
}

export function getStayDuration(checkinDate, checkoutDate) {
  const checkin = dayjs(checkinDate);
  const checkout = dayjs(checkoutDate);

  // Calculate the total number of days
  const totalDays = checkout.diff(checkin, "day");

  if (totalDays < 7) {
    return `${totalDays} day${totalDays > 1 ? "s" : ""}`;
  } else {
    const weeks = Math.floor(totalDays / 7);
    const days = totalDays % 7;

    if (days === 0) {
      return `${weeks} week${weeks > 1 ? "s" : ""}`;
    } else {
      return `${weeks} week${weeks > 1 ? "s" : ""} and ${days} day${
        days > 1 ? "s" : ""
      }`;
    }
  }
}
export function isGreaterDate(start, end) {
  const startDate = dayjs(start); // Start date
  const endDate = dayjs(end); // End date
  return startDate.isAfter(endDate);
}
export function listingDataMap(dataObj) {
  if (dataObj?.operation === "rent" || dataObj?.operation === "sale") {
    return {
      ...dataObj,
      propertyName: dataObj?.suggestedTexts?.title,
      accommodationTypeName: dataObj?.propertyType,
      price: dataObj?.price,
      thumbnail: dataObj?.thumbnail,
      accommodationType: dataObj?.operation === "sale" ? "buy" : "rent",
      id: dataObj?.propertyCode,
      city_in_trans: dataObj?.province,
      distance_to_cc: dataObj?.district,
      badge: dataObj?.status,
      review_score: dataObj?.review_score,
      address: dataObj?.address,
      review_score_word: dataObj?.review_score_word,
      review_nr: dataObj?.review_nr,
      images: dataObj?.multimedia?.images,
      unit_configuration_label: `<b>${dataObj?.detailedType?.typology} – ${dataObj?.size} m²</b>: ${dataObj?.rooms} rooms • ${dataObj?.bathrooms} bathroom`,
    };
  }

  if (dataObj?.accommodation_type_name === "monthlyStay") {
    return {
      ...dataObj,
      propertyName: dataObj?.listing?.title,
      accommodationTypeName: dataObj?.accommodation_type_name,
      price:
        dataObj?.pricingQuote?.structuredStayDisplayPrice?.primaryLine?.price ||
        dataObj?.pricingQuote?.structuredStayDisplayPrice?.primaryLine
          ?.discountedPrice ||
        dataObj?.pricingQuote?.structuredStayDisplayPrice?.primaryLine
          ?.originalPrice,
      thumbnail: dataObj?.listing?.contextualPictures?.[0]?.picture,
      accommodationType: dataObj?.accommodation_type_name,
      id: dataObj?.listing?.id,
      city_in_trans: dataObj?.listing?.localizedCityName,
      distance_to_cc: dataObj?.structuredContent?.distance,
      badge: dataObj?.formattedBadges?.[0]?.text,
      review_score: dataObj?.listing?.avgRatingLocalized,
      address: dataObj?.address,
      review_score_word: dataObj?.listing?.avgRatingLocalized,
      review_nr: dataObj?.reviewsCount,
      images: dataObj?.listing?.contextualPictures,
      latitude: dataObj?.listing?.coordinate?.latitude,
      longitude: dataObj?.listing?.coordinate?.longitude,
    };
  }
  return {
    ...dataObj,
    propertyName: dataObj?.name,
    accommodationTypeName: dataObj?.accommodation_type_name,
    thumbnail: dataObj?.photoMainUrl || dataObj?.max_photo_ur || "",
    accommodationType: dataObj?.accommodation_type_name,
    id: dataObj?.id,
    city_in_trans: dataObj?.city_in_trans,
    distance_to_cc: `${dataObj?.distance_to_cc} km from centre`,
    unit_configuration_label: dataObj?.unit_configuration_label,
    class: dataObj?.accuratePropertyClass,
    badge: dataObj?.reviewScoreWord,
    review_score: dataObj?.reviewScore,
    review_score_word: dataObj?.reviewScoreWord,
    review_nr: dataObj?.reviewCount,
    address: dataObj?.address,
    distance_to_cc_formatted: dataObj?.distance_to_cc_formatted,
    price: dataObj?.priceBreakdown?.grossPrice?.value,
    priceInfoContent: dataObj?.priceDetails?.info,
  };
}
export function listingDetailMap(dataObj, listingType) {
  if (listingType === "rent" || listingType === "buy") {
    return {
      ...dataObj,
      neighbourhood: dataObj?.ubication?.administrativeAreaLevel3,
      phoneNumber: dataObj?.contactInfo?.phone1?.phoneNumberForMobileDialing,
      listingType,
    };
  }
  if (listingType === "monthlyStay") {
    return {
      ...dataObj,
      facility: dataObj?.sections?.amenities?.seeAllAmenitiesGroups,
      listingType,
    };
  }
  const hotel_facilities = dataObj?.hotel_facilities;
  let facility = hotel_facilities ? hotel_facilities.split(",") : [];
  facility = facility.map((chip) => ({
    title: hotelFacilitiesMapping?.[chip] || "",
  }));
  return {
    ...dataObj,
    facility,
    listingType,
    propertyName: dataObj?.hotel_name,
    price: dataObj?.composite_price_breakdown?.gross_amount?.value,
  };
}
export function getCityAndCountry(locationString) {
  if (!locationString) {
    return { isNotAvailable: true };
  }
  const countryCodeMapping = {
    portugal: { code: "pt", capital: "lisbon" },
    italy: { code: "it", capital: "rome" },
    spain: { code: "es", capital: "madrid" },
  };
  let isNotAvailable = false;

  let [city, country] = locationString
    .split(",")
    .map((item) => item.trim().toLowerCase());

  // If only country is provided
  if (!country) {
    country = city; // If no city is provided, assume it's just the country
    city = countryCodeMapping[country]?.capital || "unknown"; // Get capital or 'unknown'
  }

  // Check if country is not in Portugal, Italy, or Spain
  if (!countryCodeMapping[country]) {
    isNotAvailable = true;
  }

  // Convert country to its code if available in the mapping
  const countryCode = countryCodeMapping[country]?.code || country;

  return { city, country: countryCode, isNotAvailable };
}
export function getCurrentUser() {
  let storedUser = localStorage.getItem("user");
  if (storedUser) {
    return JSON.parse(storedUser);
  }
  return storedUser;
}
export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km
  return distance;
}
export function gotoDirections(startLatLng, distinationLatLng) {
  const baseUrl = "https://www.google.com/maps/dir/?api=1";
  const url = `${baseUrl}&origin=${startLatLng?.[0]},${startLatLng?.[1]}&destination=${distinationLatLng?.[0]},${distinationLatLng?.[1]}`;

  // Open the Google Maps URL in a new window/tab
  window.open(url, "_blank");
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function getSavedFilter() {
  let userFilter = localStorage.getItem("userFilter");
  userFilter = userFilter ? JSON.parse(userFilter) : null;
  return userFilter;
}
export function getCityAndCountryByLatLng(lat, lng) {
  if (!window.google) {
    return "";
  }
  const geocoder = new window.google.maps.Geocoder();
  const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };

  geocoder.geocode({ location: latlng }, (results, status) => {
    if (status === "OK" && results[0]) {
      const addressComponents = results[0].address_components;
      const city = addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const country = addressComponents.find((component) =>
        component.types.includes("country")
      )?.long_name;

      return `${city}, ${country}`;
    } else {
      return "";
    }
  });
}
export function typeMapping(key) {
  const mapObj = {
    all: { text: "All", tabBg: "#16adf5", color: "#fff" },
    Hotel: { text: "Per Night", tabBg: "#2D6ADC", color: "#fff" },
    hotels: { text: "Per Night", tabBg: "#2D6ADC", color: "#fff" },
    monthlyStay: { text: "Monthly Stay", tabBg: "#FF0E0E", color: "#fff" },
    rent: { text: "Rent", tabBg: "#C5F211", color: "#000" },
    buy: { text: "Buy", tabBg: "#FFAB49", color: "#000" },
  };

  return mapObj[key];
}
export function sortListings(listings, referenceLat, referenceLng, option) {
  return listings.sort((a, b) => {
    // Sort by price (lowest first)
    if (option === "price") {
      const priceA = parseFloat(a.price.replace(/[^0-9.-]+/g, ""));
      const priceB = parseFloat(b.price.replace(/[^0-9.-]+/g, ""));
      if (priceA !== priceB) {
        return priceA - priceB;
      }
    }

    // Sort by review score (higher first)
    if (option === "rating") {
      const scoreA = parseFloat(a.review_score);
      const scoreB = parseFloat(b.review_score);
      if (!isNaN(scoreA) && !isNaN(scoreB) && scoreA !== scoreB) {
        return scoreB - scoreA;
      }
    }

    // Sort by distance to a specific lat/lng (closest first)
    if (option === "distance_me" || option === "distance_office") {
      const distanceA = calculateDistance(
        referenceLat,
        referenceLng,
        a.lat,
        a.lng
      );
      const distanceB = calculateDistance(
        referenceLat,
        referenceLng,
        b.lat,
        b.lng
      );
      return distanceA - distanceB;
    }
  });
}
// Helper function to calculate distance between two latitude and longitude points
function calculateDistance(lat1, lng1, lat2, lng2) {
  if (lat1 == null || lng1 == null || lat2 == null || lng2 == null)
    return Infinity;
  const toRadians = (deg) => deg * (Math.PI / 180);
  const R = 6371; // Radius of the Earth in km
  const dLat = toRadians(lat2 - lat1);
  const dLng = toRadians(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}
export function trimString(string, noOfChar = 85) {
  if (string && string.length > noOfChar) {
    return `${string.substring(0, noOfChar)}...`;
  } else {
    return string;
  }
}
export const listType = {
  hotels: { bg: "!bg-[#0BD6F5]", color: "#0BD6F5", text: "Per night" },
  Hotel: { bg: "!bg-[#0BD6F5]", color: "#0BD6F5", text: "Per night" },
  Aparthotel: { bg: "!bg-[#FF0242]", color: "#FF0242", text: "Per night" },
  Shortterm: { bg: "!bg-[#FF0242]", color: "#FF0242", text: "Per night" },
  monthlyStay: { bg: "!bg-[#FF0242]", color: "#FF0242", text: "Night" },
  rent: { bg: "!bg-[#EF990E]", color: "#EF990E", text: "Rent" },
  buy: { bg: "!bg-[#29B629]", color: "#2ab529", text: "Buy" },
};
export function getClosestMetroDistance(metros, location) {
  const { lat, lng } = location;
  let closestDistance = Infinity;
  metros.forEach((metro) => {
    const distance = getDistanceFromLatLonInKm(
      lat,
      lng,
      metro?.geometry?.location?.lat(),
      metro?.geometry?.location?.lng()
    );
    if (distance < closestDistance) {
      closestDistance = distance;
    }
  });

  return closestDistance;
}
export function mapTypeProperties(type) {
  const placeTypes = [
    { type: "cafe", color: "#CE5B08", text: "Cafe" },
    { type: "subway_station", color: "#BE0B28", text: "Metro" },
    { type: "park", color: "#51F610", text: "Park" },
    { type: "school", color: "#FFC05A", text: "School" },
    { type: "beach", color: "#04B8FF", text: "Beach" },
    { type: "bank", color: "#42BD27", text: "Bank" },
    { type: "gym", color: "#8F8FF4", text: "Gym" },
  ];
  return placeTypes.filter((x) => x.type === type)?.[0] || null;
}
export const cubeClrs = [
  "#FFE5B4",
  "#FFA4A4",
  "#A7B3FF",
  "#FF6CA7",
  "#FC8183",
  "#D4A5E6",
  "#D48FFC",
  "#DCFF9B",
  "#6FFFB4",
  "#FFEB69",
  "#AEC5FF",
  "#93FFFF",
  "#A3D5FF",
  "#9BF9E7",
  "#E4FF77",
  "#FFF476",
  "#93F6D2",
  "#8AD8F2",
  "#D0FF6B",
  "#A391ED",
  "#FFDB58",
  "#CAB7FF",
  "#F7C1DA",
  "#FFA07A",
];

export function parseReviewString(reviewString) {
  // Match the pattern for the number with the parentheses
  const regex = /^([\d.]+) \((\d+)\)$/;
  const match = reviewString.match(regex);

  if (match) {
    const score = parseFloat(match[1]);
    const reviewNr = parseInt(match[2], 10);
    return { score: score, review_nr: reviewNr };
  }

  return null; // Return null if the format doesn't match
}

export function checkLocationType(types) {
  if (types.includes("locality")) {
    return "city";
  } else if (types.includes("country")) {
    return "country";
  } else {
    return "city";
  }
}

import { useState } from "react";

const VideoSlider = (props) => {
  const { isFullscreen, listingMedia } = props;
  const [selectedImage, setSelectedImage] = useState("");

  const activeImg = selectedImage ? selectedImage : listingMedia?.[0];
  return (
    <div className="w-full">
      {/* Main Image */}
      <div className="flex gap-2 w-full">
        {!isFullscreen && (
          <div className={"w-[120px]  h-[500px]"}>
            <div className="w-full h-full flex flex-col gap-[6px] overflow-y-auto hide-scrollbar">
              {listingMedia?.length > 0 &&
                listingMedia.map((image, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedImage(image);
                    }}
                    className="cursor-pointer  rounded-[4px]"
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={image?.url}
                      className="relative inline-block w-full h-full"
                    >
                      <video
                        src={image?.url}
                        poster={image?.thumbnail}
                        className="w-full h-full"
                        autoPlay
                        controls
                        loop
                      />
                    </a>
                  </div>
                ))}
            </div>
          </div>
        )}
        <div
          className={
            isFullscreen
              ? "w-full   h-[515px]"
              : "w-full  h-[500px] rounded-[7px]"
          }
        >
          <a
            target="_blank"
            rel="noreferrer"
            href={activeImg?.url}
            className="relative inline-block w-full h-full"
          >
            <video
              src={activeImg?.url}
              poster={activeImg?.thumbnail}
              className="w-full h-full"
              autoPlay
              controls
              loop
            />
          </a>
        </div>
      </div>
      <div className="w-full relative mt-[9px] ">
        <div className="absolute cursor-pointer w-[124px] h-[30px] bg-[#000]/20 text-[#fff] left-[130px] bottom-[-4px] transform -translate-y-1/2 flex justify-center items-center">
          {listingMedia?.length} photos
        </div>
      </div>
    </div>
  );
};

export default VideoSlider;

// isVideo(activeImg) ? (
//   <a
//     target="_blank"
//     href={listingMedia?.[0]?.url}
//     className="relative inline-block w-full h-full"
//   >
//     <video
//       src={listingMedia?.[0]?.url}
//       poster={listingMedia?.[0]?.thumbnail}
//       className="w-full h-full"
//       autoPlay
//       controls
//       loop
//     />
//   </a>
// )

import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckOutIcon from "@mui/icons-material/Logout";
import InfoIcon from "@mui/icons-material/Info";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import GroupIcon from "@mui/icons-material/Group";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";

const HotelRules = ({ isMobile }) => {
  const rules = [
    {
      icon: <CheckCircleOutlineIcon className="text-blue-500" />,
      title: "Check-in",
      details: "From 14:00",
    },
    {
      icon: <CheckOutIcon className="text-blue-500" />,
      title: "Check-out",
      details: "Until 12:00",
    },
    {
      icon: <InfoIcon className="text-blue-500" />,
      title: "Cancellation/ prepayment",
      details:
        "Cancellation and prepayment policies vary according to accommodation type.",
      linkText: "conditions",
      linkHref: "#",
    },
    {
      icon: <ChildFriendlyIcon className="text-blue-500" />,
      title: "Children and beds",
      details: "Child policies",
    },
    {
      icon: <GroupIcon className="text-blue-500" />,
      title: "Groups",
      details: "When booking more than 5 rooms, different policies may apply.",
    },
    {
      icon: <CreditCardIcon className="text-blue-500" />,
      title: "Accepted payment methods",
      details: "We accept Visa, MasterCard, American Express, and cash.",
    },
  ];

  return (
    <div className={`w-full mx-auto ${isMobile ? "p-0" : "p-4"} bg-white`}>
      <div className="space-y-4">
        {rules.map((rule, index) => (
          <React.Fragment key={index}>
            <div className="flex gap-6 items-center space-x-3">
              <div className="flex items-center w-1/3">
                <div className="mt-1">{rule.icon}</div>
                <div className="font-medium text-sm text-gray-900">
                  {rule.title}
                </div>
              </div>
              <div className="font-sans text-sm">{rule.details}</div>
            </div>
            <div className="w-full h-[1px] bg-[#000]/10"></div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default HotelRules;

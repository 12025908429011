import React from "react";

const CustomMarker = ({ label, bgcolr, icon }) => {
  return (
    <div className="relative flex items-center justify-center">
      {/* Speech bubble */}
      <div
        style={{ background: bgcolr || "red" }}
        className="text-[#fff] text-sm font-medium px-4 py-2 rounded-lg relative"
      >
        <div className="flex items-center space-x-2">
          {/* Icon */}
          {icon && icon}
          {/* Text */}
          <span>{label}</span>
        </div>
        {/* Tail */}
        <div
          style={{ background: bgcolr || "red" }}
          className="absolute bottom-[-5px] left-1/2 transform -translate-x-1/2 w-3 h-3 rotate-45"
        ></div>
      </div>
    </div>
  );
};

export default CustomMarker;

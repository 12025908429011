import React, { useEffect, useState } from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import IconCircular from "../IconCircular/IconCircular";
import { useDispatch, useSelector } from "react-redux";
import {
  setCoordinates,
  setRadiusRefetch,
} from "../../store/Filter/filterReducers";
import { useMap } from "@vis.gl/react-google-maps";
import LocationAutocomplete from "../Autocomplete/LocationAutocomplete";
import {
  Add,
  ArrowForward,
  ArrowForwardIos,
  CompassCalibration,
  EmergencyShare,
  KeyboardDoubleArrowRight,
  QuestionMark,
} from "@mui/icons-material";
import AddLocationModal from "../Listing/AddLocationModal";
import CustomTooltip from "../CustomTooltip";
import { addMyLocationAction } from "../../store/User/userAction";
import { checkLocationType, getCurrentUser } from "../../common/Helpers";
import PlayVideo from "../PlayVideo";

const StyledSlider = styled(Slider)({
  color: "#01A3FE", // Track color (yellow)
  height: 5,
  borderRadius: "0 !important",
  "& .MuiSlider-thumb": {
    height: 24,
    width: 30,
    top: "15px",
    backgroundColor: "#fff", // Blue background color for the thumb
    borderRadius: "8px", // Rounded capsule shape
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow effect
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "inherit",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#DFD3D3", // Light gray rail color
  },
});

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <div>
      {React.cloneElement(children, {
        style: {
          ...children.props.style,
          position: "relative",
        },
      })}
      <div
        className="text-primary"
        style={{
          position: "absolute",
          fontWeight: "bold",
          top: "-25px",
          right: 0,
          textShadow: "1px 1px 2px black",
          whiteSpace: "nowrap",
        }}
      >
        {`${value} km`}
      </div>
    </div>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

export default function DistanceSlider({
  onRadiusValue,
  handleTogglePlaceCard,
  onRadiusChange,
  onChangeRadiusPin,
  isMobile,
  isListingScreen,
  isMapView = true,
}) {
  const currentUser = getCurrentUser();
  const myLocations = useSelector(
    (state) => state.user?.userStore?.myLocations
  );
  const dispatch = useDispatch();
  const [myLocation, setLocations] = useState(myLocations);
  let lastRadiusPin = localStorage.getItem("lastRadiusPin");
  lastRadiusPin = lastRadiusPin ? JSON.parse(lastRadiusPin) : myLocation[0];
  const map = useMap();
  const [value, setValue] = useState(7); // Default value of 3 km
  const [search, setSearch] = useState("");
  const [activeLocation, setActiveLocation] = useState(lastRadiusPin);
  const [radiusSearchLocation, setSearchRadiusLoc] = useState(null);
  const [isOpenAddModal, setToggleAddModal] = useState(false);
  const [isPlayVideo, setVideo] = useState(false);
  const [isShowSearch, setToggle] = useState(false);
  const [isShowMyLocation, setToggleLocation] = useState(false);
  const filterState = useSelector((state) => state.filter);
  const currentLocation = useSelector((state) => state.map?.currentLocation);

  useEffect(() => {
    if (map) {
      getCircleBounds(map.getCenter().lat(), map.getCenter().lng(), value);
    }
  }, []);

  useEffect(() => {
    setLocations(myLocations);
  }, [myLocations]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onRadiusChange && onRadiusChange(newValue);
  };

  const getLiveLocation = () => {
    const current = {
      locationName: "Current location",
      latitude: currentLocation?.latitude,
      longitude: currentLocation?.longitude,
      image: "https://picsum.photos/200?random=5",
    };
    setLocations([current, ...myLocation]);
    onSelectMyLocation(current);
  };

  const onSliderChangeCommited = (event, newValue) => {
    if (map) {
      getCircleBounds(
        activeLocation?.latitude,
        activeLocation?.longitude,
        newValue
      );
      map.setCenter({
        lat: activeLocation?.latitude,
        lng: activeLocation?.longitude,
      });
    }
  };

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  const onPlaceSelect = (place) => {
    if (!place || !place.geometry || !place.geometry.location) return;
    const { lat, lng } = place.geometry.location;
    // Fit map bounds to the selected place
    map.fitBounds(place.geometry.viewport);
    const updatedLoc = {
      address: place?.formatted_address,
      locationName: place?.name,
      latitude: lat(),
      longitude: lng(),
      image: place.photos?.[0].getUrl() || "https://picsum.photos/200?random=4",
    };
    localStorage.setItem("lastRadiusPin", JSON.stringify(updatedLoc));
    setLocations([updatedLoc, ...myLocation]);
    setActiveLocation(updatedLoc);
    onChangeRadiusPin(updatedLoc);
    setSearchRadiusLoc(place);
    handleTogglePlaceCard && handleTogglePlaceCard(updatedLoc);
    const searchParams = {
      searchText: {
        text: place?.formatted_address,
        type: checkLocationType(place?.types),
        id: place?.place_id,
      },
    };
    localStorage.setItem(
      "userFilter",
      JSON.stringify({
        ...filterState,
        ...searchParams,
        latitude: lat(),
        longitude: lng(),
      })
    );
    dispatch(
      setCoordinates({
        ...searchParams,
        lat: lat(),
        lng: lng(),
        isRefetchAll: true,
      })
    );

    getCircleBounds(lat(), lng(), value);
    map.setCenter({ lat: lat(), lng: lng() });
    map.setZoom(12);
  };

  const toggleSearch = () => {
    setToggle(!isShowSearch);
  };

  function toggleMyLocation() {
    setToggleLocation(!isShowMyLocation);
  }

  function onSelectMyLocation(selectedLocation) {
    localStorage.setItem("lastRadiusPin", JSON.stringify(selectedLocation));
    setToggleLocation(!isShowMyLocation);
    setActiveLocation(selectedLocation);
    onChangeRadiusPin(selectedLocation);
    const { latitude, longitude } = selectedLocation;
    dispatch(setCoordinates({ lat: latitude, lng: longitude }));
    dispatch(
      setRadiusRefetch({
        lat: latitude,
        lng: longitude,
        isRefatched: true,
        activeListingType: filterState.selectedMainFilters,
      })
    );
    map.setCenter({ lat: latitude, lng: longitude });
  }

  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <div className="mt-2">
        <CustomTooltip title={"Move it"}>
          <SliderThumb {...other}>
            {children}
            <div className="text-[#01A3FE] flex items-center">
              <KeyboardDoubleArrowRight sx={{ fontSize: 24 }} />
            </div>
            <div className="absolute top-[-20px] text-[#fff] text-[12px]">
              {value}km
            </div>
          </SliderThumb>
        </CustomTooltip>
      </div>
    );
  }

  function toggleAddModal() {
    setToggleAddModal(!isOpenAddModal);
  }

  function getCircleBounds(centerLat, centerLng, radiusInKm) {
    if (!window.google) {
      onRadiusValue(null);
      return;
    }
    const EARTH_RADIUS = 6371; // Earth's radius in kilometers

    // Helper function to convert degrees to radians
    function toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    // Helper function to convert radians to degrees
    function toDegrees(radians) {
      return radians * (180 / Math.PI);
    }

    // Latitude boundaries
    const latRadius = radiusInKm / EARTH_RADIUS;
    const minLat = centerLat - toDegrees(latRadius);
    const maxLat = centerLat + toDegrees(latRadius);

    // Longitude boundaries
    const lngRadius =
      radiusInKm / (EARTH_RADIUS * Math.cos(toRadians(centerLat)));
    const minLng = centerLng - toDegrees(lngRadius);
    const maxLng = centerLng + toDegrees(lngRadius);

    // Create bounds object (using Google Maps LatLngBounds)
    const bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(minLat, minLng), // Southwest corner
      new window.google.maps.LatLng(maxLat, maxLng) // Northeast corner
    );
    map.fitBounds(bounds);
    onRadiusValue(bounds);
  }

  const onSaveSearchLocation = () => {
    const { lat, lng } = radiusSearchLocation.geometry.location;
    dispatch(
      addMyLocationAction({
        ...{
          address: radiusSearchLocation?.formatted_address,
          locationName: radiusSearchLocation?.name,
          image: radiusSearchLocation?.photos?.[0]?.getUrl(),
          latitude: lat(),
          longitude: lng(),
        },
        userId: currentUser?.id,
      })
    );
  };

  function toggleVideo() {
    setVideo(!isPlayVideo);
  }

  return (
    <React.Fragment>
      {isPlayVideo && (
        <PlayVideo
          isOpen={isPlayVideo}
          toggleModal={toggleVideo}
          modalTitle="Guide how to use it"
          videoUrl={"https://www.youtube.com/embed/MZLXGYTKsDU"}
        />
      )}

      <div
        className={`w-60 absolute z-[60] ${
          isListingScreen
            ? isMobile
              ? isMapView
                ? "left-[50px] top-[-100px]"
                : "left-[50px] top-[200px]"
              : "left-[150px] top-[102px]"
            : "left-[180px] top-[-80px]"
        }`}
      >
        <div className="mb-[20px] font-sans font-bold text-xs text-center text-[#fff]">
          Max Radius from “{activeLocation?.locationName}”
        </div>
        <div className="relative flex flex-row items-center w-full">
          {isShowMyLocation && (
            <div className="h-[50vh] overflow-y-auto hide-scrollbar bg-[#1351BC] p-[20px] rounded-b-[10px] absolute top-[45px] left-[-20px]">
              <React.Fragment>
                <CustomTooltip title="My live location" placement="bottom">
                  <button
                    className="flex flex-col items-center justify-center mb-3 p-4 cursor-pointer w-10 h-10 border-none rounded-full bg-gradient-to-r from-[#4a90e2] to-primary-light shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-110 hover:shadow-xl focus:outline-none"
                    onClick={getLiveLocation}
                    aria-label="Search"
                  >
                    <EmergencyShare
                      className="text-secondary-l0"
                      style={{ fontSize: "20px" }}
                    />
                  </button>
                </CustomTooltip>
                <CustomTooltip title="Search a center point" placement="bottom">
                  <button
                    className="flex flex-col items-center justify-center mb-3 p-4 cursor-pointer w-10 h-10 border-none rounded-full bg-gradient-to-r from-[#4a90e2] to-primary-light shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-110 hover:shadow-xl focus:outline-none"
                    onClick={toggleSearch}
                    aria-label="Search"
                  >
                    <SearchIcon
                      className="text-secondary-l0"
                      style={{ fontSize: "20px" }}
                    />
                  </button>
                </CustomTooltip>
                <CustomTooltip title="Add a location" placement="bottom">
                  <button
                    className="flex flex-col items-center justify-center mb-3 p-4 cursor-pointer w-10 h-10 border-none rounded-full bg-gradient-to-r from-[#4a90e2] to-primary-light shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-110 hover:shadow-xl focus:outline-none"
                    onClick={toggleAddModal}
                  >
                    <Add
                      className="text-secondary-l0"
                      style={{ fontSize: "20px" }}
                    />
                  </button>
                </CustomTooltip>
              </React.Fragment>

              {myLocation?.length > 0 &&
                myLocation.map((item, index) => {
                  if (item.locationName === activeLocation.locationName) return;
                  return (
                    <div
                      onClick={() => onSelectMyLocation(item)}
                      key={index}
                      className={
                        "min-w-10 w-10 h-10 mb-6 cursor-pointer z-20 shadow-lg"
                      }
                    >
                      <IconCircular
                        className={"h-full"}
                        alt={"language"}
                        key={"language"}
                        src={item.image}
                      />
                      <div className="text-[8px] text-[#fff] mt-1 font-bold text-center ">
                        {item.locationName}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          <div className="h-[40px]">
            <CustomTooltip title={"Center point"}>
              <div
                onClick={toggleMyLocation}
                className={
                  "w-[40px] h-[40px] mb-2 cursor-pointer z-20 shadow-lg rounded-full"
                }
              >
                <IconCircular
                  className={"max-w-[40px] max-h-[40px] w-[40px] h-[40px]"}
                  alt={"language"}
                  key={"language"}
                  src={activeLocation?.image}
                />
              </div>
            </CustomTooltip>
          </div>

          <div
            className={`w-full flex items-center gap-2 ${
              isShowSearch && !isMobile ? "min-w-[500px]" : "min-w-[300px]"
            }`}
          >
            <StyledSlider
              value={value}
              onChange={handleChange}
              onChangeCommitted={onSliderChangeCommited}
              min={0}
              max={50}
              step={0.1}
              valueLabelDisplay="off" // Auto display the value inside the thumb
              slots={{
                thumb: AirbnbThumbComponent, // Custom value label component
              }}
            />

            {isShowSearch && (
              <div
                className={`flex shadow-2xl max-w-[200px] ${
                  isMobile && "absolute left-[45px] top-[55px]"
                }`}
              >
                <LocationAutocomplete
                  onPlaceSelect={onPlaceSelect}
                  handleSearchChange={handleSearchChange}
                  onSaveSearchLocation={onSaveSearchLocation}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpenAddModal && (
        <AddLocationModal
          map={map}
          open={isOpenAddModal}
          handleClose={toggleAddModal}
        />
      )}
    </React.Fragment>
  );
}

import React, { useEffect, useRef, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Add,
  ArrowForward,
  Close,
  DeleteOutline,
  Note,
  PushPin,
  Star,
  SyncAlt,
} from "@mui/icons-material";
import mapTypeIcon from "../../assets/icons/svg/map-type.svg";
import MobileSaveListSort from "../../components/Sort/MobileSaveListSort";
import { getCurrentUser, trimString } from "../../common/Helpers";
import CustomBottomSheet from "../../components/CustomBottomSheet/CustomBottomSheet";
import MobileListingDetail from "../Listings/MobileListingDetail";
import { setActiveStepAction } from "../../store/Map/mapActions";
import { useDispatch, useSelector } from "react-redux";
import useListing from "../../hooks/useListing";
import dayjs from "dayjs";
import {
  createGroupAction,
  deleteFavoriteItem,
  getFavoriteList,
  pinFavoriteItem,
  saveFavoriteItemNotes,
} from "../../store/User/userAction";

const MobileList = () => {
  const { showDetail } = useListing();
  const [selectedOption, setSelectedOption] = useState("Price: Highest First");
  const [isShowDetail, setToggleDetail] = useState(false);
  const [isShowCreateForm, setToggleGroup] = useState(false);
  const [isShowNote, setNote] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [longPressElement, setLongPressElement] = useState(null);
  const [longPressTimeout, setLongPressTimeout] = useState(null);
  const { favoriteList } = useSelector((state) => state.user.userStore);
  const [saveList, setSaveList] = useState([]);
  const [notes, setNotes] = useState("");
  const [groupName, setGroupName] = useState("");
  const listRef = useRef([]);
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(getFavoriteList(currentUser?.id));
    }
  }, []);
  useEffect(() => {
    if (favoriteList?.items?.length > 0) {
      mapList();
    }
  }, [favoriteList]);

  function onSortChange(value) {
    setSelectedOption(value);
  }

  function mapList() {
    if (!favoriteList?.items) return;
    let finalArr = favoriteList?.items || [];
    const pinArray = favoriteList.items.filter((x) => x.isPinned);
    const notPinArray = favoriteList.items.filter((x) => !x.isPinned);
    if (pinArray?.length > 0) {
      finalArr = [...pinArray, ...notPinArray];
    } else {
      finalArr = notPinArray;
    }
    setSaveList(finalArr);
  }

  function toggleAvailabilityView(e, property = null) {
    if (property?.listingId) {
      const { listingId, propertyType, checkin_date, checkout_date, country } =
        property;
      let checkin = checkin_date;
      let checkout = checkout_date;
      if (propertyType === "monthlyStay") {
        checkin = dayjs(checkin_date).format("YYYY-MM-DD");
        checkout = dayjs(checkout_date).format("YYYY-MM-DD");
      }
      setSelectedList({ properties: property });
      setActiveStepAction(dispatch, property?.listingId);
      showDetail(
        {
          properties: {
            ...property,
            id: listingId,
            accommodationType: propertyType,
            checkinDate: checkin,
            checkoutDate: checkout,
            country,
          },
        },
        false
      );
    }
    setToggleDetail(!isShowDetail);
  }
  function toggleCreateGroup() {
    setToggleGroup(!isShowCreateForm);
  }
  function toggleNote() {
    setNote(!isShowNote);
  }
  const handleLongPressStart = (e, index, item) => {
    e.preventDefault();
    const timeout = setTimeout(() => {
      setAnchorEl(anchorEl ? null : listRef.current);
      setLongPressElement(longPressElement ? null : item);
    }, 600); // 600ms for long press
    setLongPressTimeout(timeout);
  };

  const handleLongPressEnd = () => {
    if (longPressTimeout) {
      clearTimeout(longPressTimeout);
      setLongPressTimeout(null);
    }
  };

  function deleteFav() {
    setAnchorEl(null);
    setLongPressElement(null);
    dispatch(deleteFavoriteItem(currentUser?.id, longPressElement?.listingId));
  }
  function pinItem() {
    dispatch(pinFavoriteItem(currentUser?.id, longPressElement?.listingId));
  }
  function saveNotes() {
    dispatch(
      saveFavoriteItemNotes({
        userId: currentUser?.id,
        listingId: longPressElement?.listingId,
        notes,
      })
    );
  }

  function addGroup() {
    dispatch(
      createGroupAction({
        userId: currentUser?.id,
        newGroup: {
          name: groupName,
        },
      })
    );
  }

  return (
    <div className="w-full">
      {/* Top Tabs */}
      {!anchorEl ? (
        <div className="flex w-full flex-nowrap items-center gap-2 hide-scrollbar overflow-x-auto bg-[#0D81F5] text-[#fff] font-sans text-[16px] leading-6 h-[55px]">
          <div
            className="cursor-pointer flex flex-none px-2"
            onClick={toggleCreateGroup}
          >
            <Add />
          </div>
          {favoriteList?.groups?.length > 0 &&
            favoriteList.groups
              .slice()
              .reverse()
              .map((group) => (
                <div
                  key={group._id}
                  className="cursor-pointer flex flex-none px-2"
                >
                  {group?.name}
                </div>
              ))}
        </div>
      ) : (
        <div className="flex w-full justify-between items-center gap-2 bg-[#1A0978] text-[#fff] font-sans text-[16px] leading-6 h-[55px]">
          <div
            className="cursor-pointer flex flex-none px-2"
            onClick={toggleNote}
          >
            <Note />
          </div>

          <div className="cursor-pointer flex flex-none px-2">
            <SyncAlt />
          </div>

          <div
            className="cursor-pointer flex flex-none px-2"
            onClick={deleteFav}
          >
            <DeleteOutline />
          </div>

          <div className="cursor-pointer flex flex-none px-2" onClick={pinItem}>
            <PushPin />
          </div>

          <div className="cursor-pointer flex flex-none px-2">1</div>

          <div className="cursor-pointer flex flex-none px-2">
            <ArrowForward />
          </div>
        </div>
      )}
      {isShowCreateForm && (
        <div className="p-2 bg-[#C8F6FF] flex justify-center items-center">
          <div className="relative mr-2">
            <input
              onChange={(e) => setGroupName(e.target.value)}
              className="bg-[#fff] p-2 rounded-10 shadow-lg border-0 min-w-[250px]"
              placeholder="Name the new list"
            />
            <div
              onClick={addGroup}
              className="absolute right-2 top-[5px] bg-[#2A6DF1] text-[12px] font-sans py-1 px-2 rounded-10 text-[#fff]"
            >
              Save
            </div>
          </div>
          <div className="rounded-full flex items-center border border-solid">
            <Close
              onClick={toggleCreateGroup}
              className="cursor-pointer"
              sx={{ fontSize: "16px" }}
            />
          </div>
        </div>
      )}

      <div className="flex justify-center items-center gap-4 my-[19px]">
        <img alt="area" src={mapTypeIcon} />
        <MobileSaveListSort onSortChange={onSortChange} />
      </div>

      {/* Listings */}
      <div
        className="overflow-y-auto hide-scrollbar hide-scrollbar w-full"
        style={{ height: "80vh" }}
      >
        {saveList?.length > 0 &&
          saveList.map((item, index) => (
            <div
              ref={(el) => (listRef.current = el)}
              key={item?.listingId}
              className={`flex items-start  ${
                longPressElement?.listingId === item.listingId
                  ? "bg-gradient-to-r from-[#1104FE] to-[#1BD8FE] px-[20px] py-[28px]"
                  : "bg-[#fff] mx-[20px] my-[28px]"
              }`}
              onClick={() => toggleAvailabilityView(null, item)}
              onMouseDown={(e) => handleLongPressStart(e, index, item)}
              onMouseUp={handleLongPressEnd}
              onTouchStart={(e) => handleLongPressStart(e, index, item)}
              onTouchEnd={handleLongPressEnd}
            >
              {/* Image */}
              <img
                src={item?.thumbnail}
                alt={item?.propertyName}
                className="w-[76px] h-[87px] rounded-[5px] object-cover"
              />
              {/* Details */}
              <div className="ml-4 flex-1">
                <div className="flex items-center justify-between">
                  <span
                    className={`text-[18px] font-semibold  font-sans leading-6 ${
                      longPressElement?.listingId === item.listingId
                        ? "text-[#fff]"
                        : "text-[#2E2E6A]"
                    }`}
                  >
                    {item?.propertyType === "monthlyStay"
                      ? item?.price
                      : `$${Math.round(item.price)}`}
                  </span>
                  {["rent", "buy"].includes(
                    item.propertyType
                  ) ? null : item.propertyType === "monthlyStay" ? (
                    <span className="flex items-center">
                      <span className="text-[#0E32B6] font-sans font-medium leading-5 text-[18px]">
                        {item.review_score}
                      </span>
                      <Star className="text-warn" sx={{ fontSize: "19px" }} />
                    </span>
                  ) : (
                    <span className="bg-[#1108FF] text-[#fff] text-sm px-2 py-1 rounded-lg">
                      {item.review_score}
                    </span>
                  )}
                </div>
                <div className="flex items-center my-2 w-full h-full">
                  {["rent", "buy"].includes(item.propertyType) && (
                    <div className="flex items-center w-full h-full gap-2">
                      <div className="font-sans font-normal leading-6 text-[17px] text-[#0D305D] ">
                        10 m²
                      </div>
                      <div className="bg-[#BAA8A8] w-[1px] h-[14px]"></div>
                      <div className="font-sans font-normal leading-6 text-[17px] text-[#0D305D] ">
                        T0
                      </div>
                      <div className="bg-[#BAA8A8] w-[1px] h-[14px]"></div>
                      <div className="font-sans font-normal leading-6 text-[17px] text-[#0D305D] ">
                        1st floor
                      </div>
                    </div>
                  )}
                  {item.propertyType === "monthlyStay" && (
                    <div
                      className={`font-sans font-normal leading-6 text-[16px] ${
                        longPressElement?.listingId === item.listingId
                          ? "text-[#fff]"
                          : "text-[#2E2E6A]"
                      }`}
                    >
                      {trimString(item.propertyName, 25)}
                    </div>
                  )}
                  {item.propertyType === "Hotel" && (
                    <React.Fragment>
                      <div
                        className={`font-sans font-medium leading-4 ${
                          longPressElement?.listingId === item.listingId
                            ? "text-[#fff]"
                            : "text-[#2E2E6A]"
                        }`}
                      >
                        {trimString(item.propertyName, 20)}
                      </div>
                      <div className="flex items-center">
                        {item?.class > 0 &&
                          [...Array(item?.class)].map((_, index) => (
                            <Star
                              key={index}
                              className="text-warn"
                              sx={{ fontSize: "16px" }}
                            />
                          ))}
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <div className="flex items-center mt-2 text-sm text-gray-500">
                  <LocationOnIcon
                    className="text-[#B73030] mr-1"
                    fontSize="small"
                  />
                  <span
                    className={`font-sans font-normal leading-4 ${
                      longPressElement?.listingId === item.listingId
                        ? "text-[#fff]"
                        : "text-[#14488D]"
                    }`}
                  >
                    {item?.address}
                  </span>
                </div>
              </div>
            </div>
          ))}
      </div>
      <CustomBottomSheet
        isHeader={false}
        isOpen={isShowDetail}
        onDismiss={toggleAvailabilityView}
      >
        <MobileListingDetail
          activeList={selectedList}
          onClose={toggleAvailabilityView}
        />
      </CustomBottomSheet>
      <CustomBottomSheet
        isHeader={false}
        isOpen={isShowNote}
        onDismiss={toggleNote}
        contentHeight={"50%"}
      >
        <div className="bg-[#FCFFBE] relative">
          <div className="flex justify-end">
            <Close onClick={toggleNote} />
          </div>
          <textarea
            onChange={(e) => setNotes(e.target.value)}
            rows="21"
            placeholder="Enter your text here..."
            className="w-full p-3 text-base bg-[#FCFFBE] border-0"
          ></textarea>
          <div className="flex justify-center absolute bottom-8 w-full">
            <div
              className="p-[10px] bg-[#0C5CFC] text-[#fff] rounded-[5px]"
              onClick={saveNotes}
            >
              Save
            </div>
          </div>
        </div>
      </CustomBottomSheet>
    </div>
  );
};

export default MobileList;

import React, { useEffect, useState } from "react";
import LMMap from "../../components/LeftCol/Map/Map";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "../../common/Helpers";
import useSupabase from "../../hooks/useSupabase";
import useFilter from "../../hooks/useFilter";
import usePosition from "../../hooks/usePosition";
import { setCenterAction } from "@/store/Map/mapActions";
import { setCoordinates } from "@/store/Filter/filterReducers";
import ListingMenu from "../../components/ListingMenu/ListingMenu";
import { Add, Chat, PinDrop, Search } from "@mui/icons-material";
import { Checkbox, FormControlLabel } from "@mui/material";
import SearchMode from "../../components/SearchMode";

function MapScreen({ isMobile = false }) {
  const { fetchUser } = useSupabase();
  const currentUser = getCurrentUser();
  const dispatch = useDispatch();
  useFilter();
  const [searchMode, setSearchMode] = useState(1);
  const [isShowMetro, setMetroVisibility] = useState(true);
  const [isShowAddLocationModal, setAddLocationModal] = useState(false);

  const { latitude, longitude } = usePosition(false, {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 600000,
  });

  useEffect(() => {
    if (latitude && longitude) {
      dispatch(setCoordinates({ lat: latitude, lng: longitude }));
      setCenterAction(dispatch, { lat: latitude, lng: longitude });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (!currentUser) {
      fetchUser();
    }
  }, []);

  function switchSearchMode(id) {
    if (id === 3) {
      return;
    }
    setSearchMode(id);
  }

  function toggleAddLocationModal() {
    setAddLocationModal(!isShowAddLocationModal);
  }

  const menuItems = [
    {
      id: 1,
      label: "Free search",
      icon: <Search sx={{ fontSize: 18 }} />,
    },
    {
      id: 2,
      label: "By radius",
      icon: <PinDrop sx={{ fontSize: 18 }} />,
    },
    {
      id: 3,
      label: "Chat",
      icon: <Chat sx={{ fontSize: 18 }} />,
    },
  ];

  return (
    <div className="flex flex-col w-full h-full relative">
      <ListingMenu searchMode={searchMode} isMapView={true} />
      <div className="flex justify-center absolute top-[100px] left-[200px] z-50 gap-8">
        <SearchMode
          searchMode={searchMode}
          switchSearchMode={switchSearchMode}
        />
        <div className="flex justify-center items-center h-full px-1 rounded-[6px] bg-[#01A3FE] text-[#fff] text-[16px] font-bold font-sans">
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "#fff",
                  "&.Mui-checked": {
                    color: "#fff",
                  },
                }}
              />
            }
            label="Districts"
          />
        </div>
        <div className="flex justify-center items-center h-full px-1 rounded-[6px] bg-[#01A3FE] text-[#fff] text-[16px] font-bold font-sans">
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setMetroVisibility(e.target.checked)}
                checked={isShowMetro}
                sx={{
                  color: "#fff",
                  "&.Mui-checked": {
                    color: "#fff",
                  },
                }}
              />
            }
            label="Metro stations"
          />
        </div>
        <div
          onClick={toggleAddLocationModal}
          className="flex justify-center items-center cursor-pointer h-full p-2 rounded-[6px] bg-[#01A3FE] text-[#fff] text-[16px] font-sans"
        >
          <Add />
          <div>Add personal location</div>
        </div>
      </div>
      <LMMap
        isShowSearch={false}
        isShowMetro={isShowMetro}
        isShowAddLocationModal={isShowAddLocationModal}
        isMobile={isMobile}
        toggleAddLocationModal={toggleAddLocationModal}
        isShowRadius={searchMode === 2}
      />
    </div>
  );
}

export default MapScreen;

import React, { useEffect, useRef, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Add,
  ArrowForward,
  Close,
  DeleteOutline,
  Note,
  PushPin,
  Star,
  SyncAlt,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../common/Helpers";
import useListing from "../../hooks/useListing";
import dayjs from "dayjs";
import { setActiveStepAction } from "../../store/Map/mapActions";
import ListingDetailView from "../Listings/ListingDetailView";
import LMMap from "../../components/LeftCol/Map/Map";
import MapSearch from "../../components/LeftCol/MapSearch";
import MobileSaveListSort from "../../components/Sort/MobileSaveListSort";
import {
  createGroupAction,
  deleteFavoriteItem,
  getFavoriteList,
  pinFavoriteItem,
  saveFavoriteItemNotes,
} from "../../store/User/userAction";

const List = ({ activeTab }) => {
  const [isOpenDetailView, setDetailView] = useState(false);
  const [saveList, setSaveList] = useState([]);
  const currentUser = getCurrentUser();
  const dispatch = useDispatch();
  const { showDetail } = useListing();
  const { favoriteList } = useSelector((state) => state.user.userStore);
  const [isShowCreateForm, setToggleGroup] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Price: Highest First");
  const [activeList, setActiveList] = useState(null);
  const [isShowNote, setNote] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [longPressElement, setLongPressElement] = useState(null);
  const [longPressTimeout, setLongPressTimeout] = useState(null);
  const [notes, setNotes] = useState("");
  const [groupName, setGroupName] = useState("");
  const listRef = useRef([]);

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(getFavoriteList(currentUser?.id));
    }
  }, []);

  useEffect(() => {
    if (favoriteList) {
      onFilter();
    }
  }, [favoriteList, activeTab]);

  function onFilter() {
    if (
      !favoriteList ||
      !favoriteList?.items ||
      favoriteList?.items?.length <= 0
    ) {
      return;
    }
    const pinnedItems = favoriteList.items
      .filter((item) => item.isPinned)
      .sort((a, b) => b.time - a.time);
    const unPinnedItems = favoriteList.items.filter((item) => !item.isPinned);
    const sortedItems = [...pinnedItems, ...unPinnedItems];
    if (activeTab === "all") {
      setSaveList(sortedItems);
      return;
    }
    setSaveList(sortedItems.filter((li) => li.propertyType === activeTab));
  }

  function toggleCreateGroup() {
    setToggleGroup(!isShowCreateForm);
  }

  function onSortChange(value) {
    setSelectedOption(value);
  }

  function toggleAvailabilityView(property = null) {
    if (property?.listingId) {
      const { listingId, propertyType, checkin_date, checkout_date, country } =
        property;
      let checkin = checkin_date;
      let checkout = checkout_date;
      if (propertyType === "monthlyStay") {
        checkin = dayjs(checkin_date).format("YYYY-MM-DD");
        checkout = dayjs(checkout_date).format("YYYY-MM-DD");
      }
      setActiveList({ properties: property });
      setActiveStepAction(dispatch, listingId);
      showDetail(
        {
          properties: {
            ...property,
            id: listingId,
            accommodationType: propertyType,
            checkinDate: checkin,
            checkoutDate: checkout,
            country,
          },
        },
        false
      );
    }
    setDetailView(!isOpenDetailView);
  }
  function toggleCreateGroup() {
    setToggleGroup(!isShowCreateForm);
  }
  function toggleNote() {
    setNote(!isShowNote);
  }
  const handleLongPressStart = (e, index, item) => {
    e.preventDefault();
    const timeout = setTimeout(() => {
      setAnchorEl(anchorEl ? null : listRef.current);
      setLongPressElement(longPressElement ? null : item);
    }, 600); // 600ms for long press
    setLongPressTimeout(timeout);
  };

  const handleLongPressEnd = () => {
    if (longPressTimeout) {
      clearTimeout(longPressTimeout);
      setLongPressTimeout(null);
    }
  };

  function deleteFav() {
    setAnchorEl(null);
    setLongPressElement(null);
    dispatch(deleteFavoriteItem(currentUser?.id, longPressElement?.listingId));
  }
  function pinItem() {
    dispatch(pinFavoriteItem(currentUser?.id, longPressElement?.listingId));
  }
  function saveNotes() {
    dispatch(
      saveFavoriteItemNotes({
        userId: currentUser?.id,
        listingId: longPressElement?.listingId,
        notes,
      })
    );
  }

  return (
    <div className="w-full">
      <div className="w-full h-[1px] bg-[#fff]"></div>
      {!anchorEl ? (
        <div className="flex w-full flex-nowrap items-center gap-2 hide-scrollbar overflow-x-auto bg-[#1351BC] text-[#fff] font-sans text-[16px] leading-6 h-[55px] ">
          <div
            className="cursor-pointer flex flex-none px-2"
            onClick={toggleCreateGroup}
          >
            <Add />
          </div>
          {favoriteList?.groups?.length > 0 &&
            favoriteList.groups
              .slice()
              .reverse()
              .map((group) => (
                <div
                  key={group._id}
                  className="cursor-pointer flex flex-none px-2"
                >
                  {group?.name}
                </div>
              ))}
        </div>
      ) : (
        <div className="flex w-full justify-between items-center gap-2 bg-[#1A0978] text-[#fff] font-sans text-[16px] leading-6 h-[55px]">
          <div
            className="cursor-pointer flex flex-none px-2"
            onClick={toggleNote}
          >
            <Note />
          </div>

          <div className="cursor-pointer flex flex-none px-2">
            <SyncAlt />
          </div>

          <div
            className="cursor-pointer flex flex-none px-2"
            onClick={deleteFav}
          >
            <DeleteOutline />
          </div>

          <div className="cursor-pointer flex flex-none px-2" onClick={pinItem}>
            <PushPin />
          </div>

          <div className="cursor-pointer flex flex-none px-2">1</div>

          <div className="cursor-pointer flex flex-none px-2">
            <ArrowForward />
          </div>
        </div>
      )}
      {isShowCreateForm && (
        <div className="p-2 bg-[#C8F6FF] flex justify-center items-center">
          <div className="relative mr-2">
            <input
              className="bg-[#fff] p-2 rounded-10 shadow-lg border-0 min-w-[250px]"
              placeholder="Name the new list"
            />
            <div className="absolute right-2 top-[5px] bg-[#2A6DF1] text-[12px] font-sans py-1 px-2 rounded-10 text-[#fff]">
              Save
            </div>
          </div>
          <div className="rounded-full flex items-center border border-solid">
            <Close
              onClick={toggleCreateGroup}
              className="cursor-pointer"
              sx={{ fontSize: "16px" }}
            />
          </div>
        </div>
      )}
      <div className="flex h-screen bg-[#fff] w-full">
        <div className="flex flex-col w-[50%] h-full  bg-secondary-l0">
          <div className="flex justify-center gap-2 items-center my-[16px] px-4">
            <div className="text-[#0B207B]">{saveList?.length} listings</div>
            <MobileSaveListSort onSortChange={onSortChange} />
          </div>
          <div className="flex-1 overflow-y-auto hide-scrollbar  mb-[5rem]">
            {saveList?.length > 0 &&
              saveList.map((item, index) => (
                <div
                  key={item?.listingId}
                  className="flex items-start bg-[#fff] my-[28px] mx-[20px]"
                  onClick={() => toggleAvailabilityView(item)}
                  onMouseDown={(e) => handleLongPressStart(e, index, item)}
                  onMouseUp={handleLongPressEnd}
                  onTouchStart={(e) => handleLongPressStart(e, index, item)}
                  onTouchEnd={handleLongPressEnd}
                >
                  {/* Image */}
                  <img
                    src={item?.thumbnail}
                    alt={item?.propertyName}
                    className="w-[144px] h-[129px] rounded-[5px] object-cover"
                  />
                  {/* Details */}
                  <div className="ml-4 flex-1 justify-between space-y-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <span className="text-[18px] font-semibold text-[#1B5AEC] font-sans leading-6">
                          {item?.propertyType === "monthlyStay"
                            ? item.price
                            : `${Math.round(item?.price)}`}{" "}
                          {["rent", "buy"].includes(item.propertyType)
                            ? "/ Month"
                            : "/ Night"}
                        </span>
                        <div>
                          {item.class > 0 &&
                            [...Array(item?.class)].map((_, index) => (
                              <Star
                                key={index}
                                className="text-warn"
                                sx={{ fontSize: "16px" }}
                              />
                            ))}
                        </div>
                      </div>
                      {["rent", "buy"].includes(
                        item.propertyType
                      ) ? null : item.propertyType === "monthlyStay" ? (
                        <span className="flex items-center">
                          <span className="text-[#0E32B6] font-sans font-medium leading-5 text-[18px]">
                            {item.rating}
                          </span>
                          <Star
                            className="text-warn"
                            sx={{ fontSize: "19px" }}
                          />
                        </span>
                      ) : (
                        <div className="flex items-center gap-2">
                          <div>
                            <div className="text-[16px] font-sans font-normal">
                              {item?.review_word}
                            </div>
                            <div className="text-[14px] font-sans font-normal">
                              {item?.total_review} reviews
                            </div>
                          </div>
                          <div className="bg-[#1108FF] text-[#fff] text-sm px-2 py-1 rounded-lg">
                            {item?.review_score}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex items-center text-sm text-gray-500">
                      <LocationOnIcon
                        className="text-[#B73030] mr-1"
                        fontSize="small"
                      />
                      <span className="font-sans font-normal leading-4 text-[#1086ED]">
                        {item?.address}
                      </span>
                    </div>
                    {["rent", "buy"].includes(item.propertyType) ? (
                      <div className="flex items-center w-full h-full gap-2">
                        <div className="font-sans font-normal leading-6 text-[17px] text-[#0D305D] ">
                          10 m²
                        </div>
                        <div className="bg-[#BAA8A8] w-[1px] h-[14px]"></div>
                        <div className="font-sans font-normal leading-6 text-[17px] text-[#0D305D] ">
                          T0
                        </div>
                        <div className="bg-[#BAA8A8] w-[1px] h-[14px]"></div>
                        <div className="font-sans font-normal leading-6 text-[17px] text-[#0D305D] ">
                          1st floor
                        </div>
                      </div>
                    ) : (
                      <div className="text-[#1B76EC] text-[18px] font-sans font-medium">
                        {item?.propertyName}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="flex flex-col w-[50%] h-[89%] gap-6 bg-secondary-l0 relative ">
          <div className="absolute left-4 top-[15px] z-50 w-full">
            <div className="w-full max-w-[400px]">
              <MapSearch onPlaceChanged={() => console.log("Place changed")} />
            </div>
          </div>
          <LMMap isListingScreen={true} />
        </div>
      </div>
      {isOpenDetailView && (
        <ListingDetailView
          open={isOpenDetailView}
          onClose={toggleAvailabilityView}
          activeList={{
            properties: {
              ...activeList.properties,
              listingKey: activeList?.properties?.propertyType,
            },
          }}
        />
      )}
    </div>
  );
};

export default List;

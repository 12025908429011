import React, { useEffect, useState } from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import useWikipediaInfo from "../../hooks/useWikipediaInfo";
import { Close } from "@mui/icons-material";

const PlaceInfoCard = ({
  placeName,
  isMapScreen = false,
  closePlace,
  isMobile,
}) => {
  const [placeDetails, setPlaceDetails] = useState(null);
  const places = useMapsLibrary("places");
  const wikiInfo = useWikipediaInfo(placeName);

  useEffect(() => {
    if (places) {
      const request = {
        query: placeName,
        fields: ["place_id"],
      };

      const service = new places.PlacesService(document.createElement("div"));
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === places.PlacesServiceStatus.OK && results) {
          service.getDetails(
            {
              placeId: results?.[0]?.place_id,
              fields: ["name", "photos", "geometry", "url", "adr_address"],
            },
            (place) => {
              if (place) {
                setPlaceDetails(place);
              }
            }
          );
        }
      });
    }
  }, [places, placeName]);

  return (
    <div className={` ${isMobile ? "w-full" : "pb-2 w-[400px]"} relative`}>
      <div
        onClick={() => closePlace(null)}
        className={`absolute top-4 z-[200]  cursor-pointer ${
          isMobile ? "right-8" : "right-2"
        }`}
      >
        <Close
          sx={{ fontSize: 18 }}
          className="text-[#fff] bg-[#000] rounded-full"
        />
      </div>
      {placeDetails ? (
        <div className="flex flex-col space-y-2">
          <div className="w-full h-[300px]">
            <img
              alt="cover"
              src={placeDetails?.photos?.[0]?.getUrl()}
              className=" w-full h-full"
            />
          </div>
          {/* Photos Carousel */}
          <div className="flex overflow-x-auto hide-scrollbar w-full gap-2">
            {placeDetails.photos &&
              placeDetails.photos.map((photo, index) => (
                <div key={index} className="!w-[100px] !h-24">
                  <img
                    alt={index}
                    src={photo.getUrl()}
                    className="rounded-md !w-[100px] !h-full"
                  />
                </div>
              ))}
          </div>
          {/* Top Section */}
          <div className="">
            <h1 className="text-[24px] text-[#091A88] font-sans">
              {placeDetails.name}
            </h1>
            <div
              className="text-[14px] font-medium font-sans text-[#09168B]"
              dangerouslySetInnerHTML={{ __html: placeDetails?.adr_address }}
            ></div>
          </div>

          {/* Place Description */}

          <div className="flex justify-center">
            {wikiInfo && (
              <div>
                <p className="text-gray-700 text-xs text-center">
                  {wikiInfo.extract}
                  <a
                    className="text-xs flex justify-center"
                    href={wikiInfo.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more on Wikipedia
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default PlaceInfoCard;

import React from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const PlayVideo = ({ videoUrl, toggleModal, modalTitle = "", isOpen }) => {
  return (
    <Modal
      open={isOpen}
      onClose={toggleModal}
      aria-labelledby="video-modal-title"
      aria-describedby="video-modal-description"
    >
      <Box
        className="bg-[#fff] dark:bg-gray-800 rounded-lg shadow-xl p-4 relative"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "800px",
          outline: "none",
        }}
      >
        <div className="flex justify-between">
          {/* Modal Title */}
          {modalTitle && (
            <h2 id="video-modal-title" className="text-lg font-semibold mb-4">
              {modalTitle}
            </h2>
          )}
          {/* Close Button */}
          <IconButton className="" onClick={toggleModal}>
            <CloseIcon />
          </IconButton>
        </div>

        {/* Video */}
        <div className="relative w-full h-0 pb-[56.25%]">
          <iframe
            src={videoUrl}
            title="Video Player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="absolute top-0 left-0 w-full h-full rounded-lg"
            frameBorder="0"
          ></iframe>
        </div>
      </Box>
    </Modal>
  );
};

export default PlayVideo;

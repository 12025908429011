import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionIcon from "@mui/icons-material/Directions";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import IconCircular from "../../components/IconCircular/IconCircular";
import {
  getClosestMetroDistance,
  getDistanceFromLatLonInKm,
  trimString,
} from "../../common/Helpers";
import useGooglePlaces from "../../hooks/useGooglePlaces";
import dayjs from "dayjs";
import {
  Delete,
  Favorite,
  LocationOn,
  OpenInBrowser,
  Phone,
} from "@mui/icons-material";
import BestRoute from "./BestRoute";
import metroIcon from "../../assets/icons/svg/metro-station.svg";

const ListingRentBuyItem = ({
  property,
  typeMapData,
  majorLocation,
  cityCenter,
  metroStation,
  toggleAvailabilityView,
}) => {
  const {
    thumbnail,
    propertyName,
    listingKey,
    contactInfo,
    price,
    priceBreakdown,
    checkinDate,
    checkoutDate,
    unit_configuration_label,
  } = property.properties || "";
  const geometry = property?.geometry;
  const { fetchDirections, directions } = useGooglePlaces();
  const [isOpenRoute, setRoute] = useState(false);
  const [isShowPhone, setShowPhone] = useState(false);

  useEffect(() => {
    fetchDirections(
      { lat: geometry?.coordinates?.[0], lng: geometry?.coordinates?.[1] },
      { lat: majorLocation?.latitude, lng: majorLocation?.longitude }
    );
  }, []);
  function toggleRoute() {
    setRoute(!isOpenRoute);
  }
  const daysBetween = dayjs(checkoutDate).diff(dayjs(checkinDate), "day");
  const totalPrice = priceBreakdown?.grossPrice?.value * daysBetween;
  const phoneNumber = contactInfo?.phone1?.phoneNumberForMobileDialing;

  const handleDial = () => {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    }
  };

  function onShowPhone() {
    setShowPhone(!isShowPhone);
  }

  return (
    <React.Fragment>
      {/* Details Section */}
      <div className=" flex  w-full relative mb-4">
        <div className="absolute top-3 left-3">
          <div className="absolute top-5 w-full flex justify-center">
            <div className="bg-[#fff] rounded-full py-1 px-2 text-[#0067E8] text-[12px] font-sans font-medium flex gap-1 items-center">
              <LocationOn />
              <div>Alfama, Lisbon, Portugal</div>
            </div>
          </div>
          <img
            onClick={() => toggleAvailabilityView(property)}
            src={thumbnail}
            alt={propertyName}
            className="rounded-lg h-[250px] cursor-pointer w-[250px] max-w-[250px] max-h-[250px]"
          />
          <div className="flex justify-between items-center w-full absolute bottom-0 z-50 bg-[#fff] text-[#000] font-sans rounded-b-lg border border-solid border-[#B29D9D]">
            <div className="ml-2 text-[10px]">
              <Delete sx={{ fontSize: 18, color: "red" }} />
            </div>
            <div className="flex px-3 py-1 justify-center w-full items-center gap-1 ">
              <div>{typeMapData?.[listingKey]?.icon}</div>
              <div className="text-[18px]">
                {typeMapData?.[listingKey]?.text}
              </div>
            </div>
            <div className="mr-2 text-[10px]">
              <Favorite sx={{ fontSize: 18, color: "#3AF910" }} />
            </div>
          </div>
        </div>
        <div
          className="w-[30%] h-[56px]"
          style={{ background: typeMapData?.[listingKey]?.color }}
        ></div>
        <div className="w-[68%] flex flex-col">
          <div
            style={{ background: typeMapData?.[listingKey]?.color }}
            className="py-[15px] px-2 flex w-full "
          >
            <div className="flex justify-between items-center w-full ">
              <div className="flex items-center">
                <div className="text-[18px] text-[#000] font-bold">
                  {trimString(propertyName, 25)}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between text-[#07287B] px-2 mt-3">
            <div
              className="text-[18px] font-sans font-normal"
              dangerouslySetInnerHTML={{ __html: unit_configuration_label }}
            ></div>
            <div className="border border-solid border-[#AEA3A3] rounded-10 p-2 shadow-md text-[16px] font-medium">
              {Math.round(price)} $/month
            </div>
          </div>

          {/* Icons and Description */}
          <div className="px-2 flex flex-col gap-10 mt-3">
            <div className="flex items-center space-x-4 text-[14px] text-[#050505] font-light">
              <div className="flex items-center">
                <div className="w-10 h-10 ">
                  <IconCircular
                    alt={"locaton"}
                    key={"location"}
                    src={
                      majorLocation?.image ||
                      "https://picsum.photos/200?random=5"
                    }
                  />
                </div>
                <span>
                  {getDistanceFromLatLonInKm(
                    majorLocation?.latitude,
                    majorLocation?.longitude,
                    geometry?.coordinates?.[0],
                    geometry?.coordinates?.[1]
                  ).toFixed(1)}{" "}
                  km to {majorLocation?.locationName}
                </span>
                <DirectionIcon
                  onClick={toggleRoute}
                  className="text-primary cursor-pointer"
                />
              </div>
              {metroStation?.length > 0 && (
                <div className="text-[14px] text-[#050505] font-light flex items-center">
                  <div className="rounded-full w-6 h-6 mr-2 flex items-center">
                    <img
                      alt="metro"
                      src={metroIcon}
                      className="rounded-full  w-4 h-4"
                    />
                  </div>
                  {getClosestMetroDistance(metroStation, {
                    lat: geometry?.coordinates?.[0],
                    lng: geometry?.coordinates?.[1],
                  }).toFixed(1)}{" "}
                  km Metro
                </div>
              )}
              <div className="text-[14px] text-[#050505] font-light flex items-center">
                <LocationOnIcon
                  sx={{
                    color: "#8B0C0C",
                    fontSize: 20,
                  }}
                />
                {getDistanceFromLatLonInKm(
                  cityCenter?.lat,
                  cityCenter?.lng,
                  geometry?.coordinates?.[0],
                  geometry?.coordinates?.[1]
                ).toFixed(1)}{" "}
                km from city center
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between text-[#07287B] gap-1 px-2 mt-3">
            <div className="flex gap-1 items-center">
              <LocationOn className="text-[#B73030]" />
              <div className="text-[18px] font-sans font-normal">
                Pinhal do Concelho, Praia da Falésia, Albufeira
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center relative  px-2 mt-3">
            <div className=" flex">
              <div
                onClick={onShowPhone}
                className="bg-gradient-to-r from-[#FFC002] to-[#E71010] cursor-pointer flex items-center no-underline p-2 gap-2 border shadow border-solid border-[#fff]  rounded-[5px] text-[#fff] text-[16px] font-sans font-medium"
              >
                <Phone />
                <div>View phone</div>
              </div>
            </div>
            {isShowPhone && (
              <div
                onClick={handleDial}
                className="absolute bottom-10 bg-[#fff] border border-solid border-[#CBB9B9] shadow-md p-2"
              >
                {phoneNumber}
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpenRoute && (
        <BestRoute
          open={isOpenRoute}
          toggleRoute={toggleRoute}
          majorLocation={majorLocation}
          directions={directions}
          latlng={geometry?.coordinates}
        />
      )}
    </React.Fragment>
  );
};
export default ListingRentBuyItem;

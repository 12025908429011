import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import {
  Map,
  useMap,
  useMapsLibrary,
  AdvancedMarker,
} from "@vis.gl/react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import "./Map.css";
import { Button } from "@mui/material";
import BottomSheetTabs from "../../BottomSheetTabs";
import LMGeojson from "./LMGeojson";
import DrawPlaces from "./DrawPlaces";
import Tools from "./Tools";
import DistanceSlider from "../../DistanceSlider";
import DrawingOverlay from "./DrawingOverlay";
import { Polyline } from "./Polyline";
import NearbyPlacesFetcher from "./NearbyPlaceFetcher";
import MapWith3DMarker from "../../../views/Area/MapWith3DMarker";
import metroIcon from "../../../assets/icons/svg/metro-station.svg";
import {
  setActiveFilterView,
  toggleDrawerAction,
} from "../../../store/Common/commonActions";
import MapSearch from "../MapSearch";
import MapListInfo from "./MapListInfo";
import useGooglePlaces from "../../../hooks/useGooglePlaces";
import ListingDetailView from "../../../views/Listings/ListingDetailView";
import PlaceInfoCard from "../../../views/Swipe/PlaceInfoCard";
import { Circle } from "./Circle";
import MapListAirbnbInfo from "./MapListAirbnbInfo";
import { setRadiusBoundsAction } from "../../../store/Map/mapActions";
import AddLocationModal from "../../Listing/AddLocationModal";

const LMMap = ({
  isListingScreen = false,
  fullscreenControl = true,
  isMobile = false,
  isShowRadius = false,
  isShowOnlyRadius = false,
  isShowAddLocationModal = false,
  isShowSearch = true,
  isShowMetro = true,
  toggleAddLocationModal,
}) => {
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.user?.userStore?.myLocations);
  const {
    cityCenter,
    fetchCityCenter,
    fetchPlaces,
    places: metroStation,
  } = useGooglePlaces();
  const center = useSelector((state) => state.map.center);
  const zoom = useSelector((state) => state.map.zoom);
  const mapID = useSelector((state) => state.map.mapID);
  const [isRadiusActive, setIsRadiusActive] = useState(false);
  const { hotels } = useSelector((state) => state.hotels);
  const filterState = useSelector((state) => state.filter);
  const [range, setRange] = useState(0);
  const [open, setOpen] = useState(false);
  const [isPlaceInfoVisible, setPlaceInfoVisibility] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [isDetail, setShowDetail] = useState(false);
  const [isShowThreeDMap, setThreeDMap] = useState(false);
  const [isShowMapType, setShowMapType] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [radiusLocation, setRadiusSearchLocation] = useState(null);
  const containerRef = useRef(null);
  const [beRadius, setBeRadius] = useState(0);
  const [mapRadius, setMapRadius] = useState(7);
  const [activeList, setActiveList] = useState(null);
  const [drawing, setDrawing] = useState(false);
  const [path, setPath] = useState(null);
  const [places, setPlaces] = useState([]);
  const [selectBounds, setSelectBounds] = useState(null);
  const map = useMap();
  const markerLib = useMapsLibrary("marker"); // Load the marker library
  const activeItem = hotels?.features?.[0];
  const [isOpenDetailView, setDetailView] = useState(false);
  let lastRadiusPin = localStorage.getItem("lastRadiusPin");
  lastRadiusPin = lastRadiusPin ? JSON.parse(lastRadiusPin) : null;
  const [activeRadiusLocation, setActiveRadiusLocation] =
    useState(lastRadiusPin);

  const handleApply = useCallback((paths) => {
    setDrawing(false);
    setPath(paths);
  }, []);

  const handleCancel = useCallback(() => {
    setDrawing(false);
  }, []);

  const showMapType = () => setShowMapType(!isShowMapType);
  const handleClose = useCallback(() => setOpen(false), []);

  useEffect(() => {
    setIsRadiusActive(isShowRadius);
    if (!isShowRadius) {
      setSelectBounds(null);
    }
  }, [isShowRadius]);
  useEffect(() => {
    if (filterState?.searchText?.text) {
      fetchCityCenter(filterState?.searchText?.text);
    }
    if (filterState?.latitude && filterState?.longitude) {
      fetchPlaces(
        "",
        { lat: filterState?.latitude, lng: filterState?.longitude },
        50,
        "subway_station"
      );
    }
  }, [filterState?.searchText, filterState?.latitude]);

  const onRadiusCheckBoxChange = useCallback(
    (isChecked) => {
      if (!isChecked) {
        map.setCenter({
          lat: filterState?.latitude,
          lng: filterState?.longitude,
        });
        map.setZoom(12);
        setSelectBounds(null);
      }
      setIsRadiusActive(isChecked);
    },
    [filterState, map]
  );

  const removeBoundary = useCallback(() => {
    setPath(null);
    setSelectBounds(null);
  }, []);

  const handleBoundsChange = useCallback((bounds) => {
    setSelectBounds(bounds);
    dispatch(setRadiusBoundsAction(bounds));
  }, []);

  const onPlacesFetched = useCallback((fetchedPlaces) => {
    setPlaces(fetchedPlaces);
  }, []);

  function birdEyeRadiusChange(radius) {
    setBeRadius(radius);
  }

  function handleThreeDMap(value) {
    setThreeDMap(value);
  }

  function toggleDrawer(item) {
    dispatch(setActiveFilterView(item));
    dispatch(toggleDrawerAction());
  }

  function toggleFilter() {
    setFilter(!isFilter);
  }
  function onChangeRadiusPin(radiusPin) {
    setActiveRadiusLocation(radiusPin);
  }

  function showDetailToggle(selectedList = null) {
    setActiveList(selectedList);
    setShowDetail(!isDetail);
  }

  function onMapListHandler(isShowDetail = false) {
    setDetailView(isShowDetail);
    setShowDetail(false);
  }

  function toggleDetailView() {
    setDetailView(false);
  }
  function handleTogglePlaceCard(loc = null) {
    setPlaceInfoVisibility(!isPlaceInfoVisible);
    loc && setRadiusSearchLocation(loc);
  }

  const memoizedPlaces = useMemo(() => places, [places]);
  const mapCore = useMapsLibrary("core");
  const filterOpt = [
    {
      filterKey: "hotels",
      label: "Night",
    },
    {
      filterKey: "monthlyStay",
      label: "Short term",
    },
    {
      filterKey: "rent",
      label: "Rent",
    },
    {
      filterKey: "buy",
      label: "Buy",
    },
  ];

  if (isShowOnlyRadius) {
    return (
      <DistanceSlider
        onRadiusValue={handleBoundsChange}
        onPlacesUpdate={setSearchText}
        handleTogglePlaceCard={handleTogglePlaceCard}
        onChangeRadiusPin={onChangeRadiusPin}
        isMobile={isMobile}
        isListingScreen={isListingScreen}
        onRadiusChange={(val) => setMapRadius(val)}
        isMapView={false}
      />
    );
  }

  return (
    <React.Fragment>
      {isPlaceInfoVisible && (
        <div
          className={`${
            isMobile ? "bottom-0 w-full" : "top-0 w-[400px]"
          } absolute left-0  z-[100] overflow-y-auto hide-scrollbar `}
          style={
            isMobile ? { height: "60vh" } : { height: "calc(100vh - 80px)" }
          }
        >
          <div className="flex justify-center bg-secondary-l0">
            <PlaceInfoCard
              placeName={radiusLocation?.locationName || ""}
              closePlace={handleTogglePlaceCard}
              isMobile={isMobile}
            />
          </div>
        </div>
      )}
      {isOpenDetailView && (
        <ListingDetailView
          open={isOpenDetailView}
          onClose={toggleDetailView}
          activeList={activeList}
        />
      )}
      {isDetail && (
        <div className={`w-full flex justify-center`}>
          {["rent", "buy"].includes(activeList?.properties?.listingKey) ? (
            <MapListInfo
              cityCenter={cityCenter}
              showDetailToggle={onMapListHandler}
              details={activeList}
              isMobile={isMobile}
            />
          ) : activeList?.properties?.listingKey === "monthlyStay" ? (
            <MapListAirbnbInfo
              cityCenter={cityCenter}
              showDetailToggle={onMapListHandler}
              details={activeList}
              isMobile={isMobile}
            />
          ) : (
            <MapListInfo
              cityCenter={cityCenter}
              showDetailToggle={onMapListHandler}
              details={activeList}
              isMobile={isMobile}
            />
          )}
        </div>
      )}
      {isShowSearch && !isListingScreen && !isRadiusActive && (
        <div
          className={`absolute left-4 top-[15px] z-[100] w-full ${
            isMobile ? "max-w-[340px]" : "max-w-[400px]"
          }`}
        >
          <div
            className={`w-full ${isMobile ? "max-w-[340px]" : "max-w-[400px]"}`}
          >
            <MapSearch
              onPlaceChanged={() => console.log("Place changed")}
              filterState={filterState}
            />
          </div>
        </div>
      )}
      <div
        className={`${
          isListingScreen ? "" : "relative"
        } flex flex-col w-full h-full my-map rounded-10`}
        ref={containerRef}
      >
        {/* Top bar with divs 1, 2, 3 */}
        {!isListingScreen && !isMobile && (
          <div
            className={`flex justify-end items-center bg-transparent absolute  z-10  top-[-63.5px] right-[170px]`}
          >
            <div className="flex items-center gap-30">
              <Tools
                clear={drawing}
                onDrawChange={setDrawing}
                onRadiusChange={onRadiusCheckBoxChange}
                showMapType={showMapType}
                toggleDrawer={toggleFilter}
                isListingScreen={isListingScreen}
              />
            </div>
          </div>
        )}
        {isRadiusActive && (
          <DistanceSlider
            onRadiusValue={handleBoundsChange}
            onPlacesUpdate={setSearchText}
            handleTogglePlaceCard={handleTogglePlaceCard}
            onChangeRadiusPin={onChangeRadiusPin}
            isMobile={isMobile}
            isListingScreen={isListingScreen}
            onRadiusChange={(val) => setMapRadius(val)}
          />
        )}

        {path && path.length && (
          <Button
            variant="contained"
            className="z-10 top-[88px] right-[70px]"
            sx={{
              borderRadius: "5px",
              padding: "5px 5px",
              fontSize: "10px",
              backgroundColor: "#f44336",
              ":hover": { backgroundColor: "#f44336" },
              position: "absolute",
            }}
            onClick={removeBoundary}
          >
            Remove Boundary
          </Button>
        )}
        {mapCore && !isShowThreeDMap && (
          <Map
            disableDefaultUI={true}
            fullscreenControl={fullscreenControl}
            streetViewControl={false}
            zoomControl={false}
            defaultCenter={center}
            defaultZoom={zoom}
            mapId={mapID}
            zoomControlOptions={{
              position: mapCore.ControlPosition.RIGHT_CENTER,
            }}
            streetViewControlOptions={{
              position: mapCore.ControlPosition.RIGHT_CENTER,
            }}
          >
            {isRadiusActive && activeRadiusLocation && mapRadius && (
              <Circle
                radius={mapRadius ? mapRadius * 1000 : 7000}
                center={{
                  lat: activeRadiusLocation?.latitude,
                  lng: activeRadiusLocation?.longitude,
                }}
                strokeColor={"#8F0505"}
                strokeOpacity={1}
                strokeWeight={2}
                fillColor="#CBE9FF"
                fillOpacity={0.5}
              />
            )}

            <NearbyPlacesFetcher
              placeType={searchText}
              onPlacesFetched={onPlacesFetched}
            />
            {!isListingScreen &&
              locations?.length > 0 &&
              locations.map((item, index) => (
                <AdvancedMarker
                  key={item._id}
                  position={{ lat: item.latitude, lng: item.longitude }}
                >
                  {item.locationName === activeRadiusLocation?.locationName &&
                    isRadiusActive && (
                      <div className="bg-[#fff] py-1 pl-2 pr-4 absolute z-20 left-[-80px] top-[30%] rounded-full border border-solid border-[#150ECF] text-[16px] font-sans">
                        {item.locationName}
                      </div>
                    )}
                  <div className="flex w-14 h-14 rounded-full relative z-30">
                    <img
                      className="rounded-full"
                      width={"100%"}
                      height={"100%"}
                      src={item.image}
                      alt="locaton"
                    />
                  </div>
                </AdvancedMarker>
              ))}
            {isShowMetro &&
              metroStation.length > 0 &&
              metroStation.map((item, index) => (
                <AdvancedMarker
                  key={`${index}`}
                  position={item.geometry.location}
                >
                  <div className="flex  flex-col items-center w-[49px] h-[49px] relative z-40">
                    <img
                      className="rounded-full w-[49px] h-[49px] z-50"
                      src={metroIcon}
                      alt="locaton"
                    />
                    <div className="inline-flex items-center font-sans font-light text-[14px] rounded-full py-[4px] px-3 text-[#0A237E] border border-solid border-[#C6D4FF] absolute top-[13px] left-[43px] bg-[#fff] whitespace-nowrap">
                      {item?.name}
                    </div>
                  </div>
                </AdvancedMarker>
              ))}
            {hotels && (
              <LMGeojson
                geojson={hotels}
                selectBounds={selectBounds}
                showDetailToggle={showDetailToggle}
                activeRadiusLocation={activeRadiusLocation}
              />
            )}
            {memoizedPlaces.length > 0 && markerLib && (
              <DrawPlaces places={memoizedPlaces} markerLib={markerLib} />
            )}
            <DrawingOverlay
              drawing={drawing}
              onApply={handleApply}
              onCancel={handleCancel}
            />
            {path && path.length && (
              <Polyline
                path={path}
                onBoundsChange={handleBoundsChange}
                options={{
                  strokeColor: "blue",
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                }}
              />
            )}
            {radiusLocation && (
              <AdvancedMarker
                position={{
                  lat: radiusLocation?.latitude,
                  lng: radiusLocation?.longitude,
                }}
              >
                <div className="flex  flex-col items-center w-14 h-14 rounded-full">
                  <img
                    className="rounded-full"
                    width={"100%"}
                    height={"100%"}
                    src={radiusLocation?.image}
                    alt="locaton"
                  />
                </div>
              </AdvancedMarker>
            )}
          </Map>
        )}
        {isShowThreeDMap && (
          <div className="h-full w-full">
            <MapWith3DMarker
              lat={activeItem?.properties?.latitude}
              lng={activeItem?.properties?.longitude}
              defaultRange={range}
              radiusChange={birdEyeRadiusChange}
              tiltChange={() => console.log()}
            ></MapWith3DMarker>
          </div>
        )}

        {isFilter && (
          <div className="absolute top-[-10px] right-[145px] space-y-2">
            {filterOpt.map((opt) => (
              <div
                onClick={() => toggleDrawer(opt)}
                key={opt.filterKey}
                className="py-2 px-4 rounded-10 bg-[#fff] font-sans text-[16px] cursor-pointer"
              >
                {opt.label}
              </div>
            ))}
          </div>
        )}
        {isShowAddLocationModal && (
          <AddLocationModal
            map={map}
            open={isShowAddLocationModal}
            handleClose={toggleAddLocationModal}
          />
        )}
        <BottomSheetTabs
          open={open}
          isShowMapType={isShowMapType}
          onClose={handleClose}
          onPlacesUpdate={setSearchText}
          handleThreeDMap={handleThreeDMap}
          isShowThreeDMap={isShowThreeDMap}
          isListingScreen={isListingScreen}
        />
      </div>
    </React.Fragment>
  );
};

export default LMMap;

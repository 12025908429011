import React, { useState } from "react";
import {
  Close,
  Delete,
  FavoriteBorder,
  KeyboardDoubleArrowRight,
  LocationOn,
  Star,
} from "@mui/icons-material";
import { Favorite } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import CustomChip from "../../CustomChip";
import {
  getCurrentUser,
  getDistanceFromLatLonInKm,
} from "../../../common/Helpers";
import { addToFavoriteList } from "../../../store/User/userAction";
import dayjs from "dayjs";

const MapListInfo = ({ cityCenter, showDetailToggle, details, isMobile }) => {
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();
  const [selectedImage, setSelectedImage] = useState("");
  const listingDetail = useSelector((state) => state.hotels.listingDetail);
  const monthlyStay = useSelector((state) => state.filter.filters.monthlyStay);
  const listingMedia = useSelector((state) => state.hotels.listingMedia);
  const myLocation = useSelector((state) => state.user?.userStore?.myLocations);
  const majorLocation =
    myLocation?.length > 0
      ? myLocation.filter((loc) => loc.isMajor)?.[0]
      : null;
  const {
    id,
    price,
    propertyName,
    review_score,
    review_nr,
    priceInfoContent,
    listingKey,
    badge,
    latitude,
    longitude,
    priceBreakdown,
    checkinDate,
    checkoutDate,
    propertyClass,
  } = details?.properties || {};
  const daysBetween = dayjs(checkoutDate).diff(dayjs(checkinDate), "day");
  const totalPrice = priceBreakdown?.grossPrice?.value * daysBetween;

  function handleSelected(updatedIndex) {
    setSelectedImage(
      listingMedia[updatedIndex]?.url_1440 ||
        listingMedia[updatedIndex]?.url ||
        listingMedia[updatedIndex]?.baseUrl
    );
  }

  function toggleDetailView() {
    showDetailToggle(true);
  }

  const handleFavorite = () => {
    dispatch(
      addToFavoriteList({
        userId: currentUser?.id,
        itemData: {
          isPinned: false,
          listingId: id,
          propertyName,
          phoneNumber: listingDetail?.phoneNumber || "",
          address: listingDetail?.address || details?.properties?.address || "",
          price,
          thumbnail:
            listingMedia?.[0]?.url_1440 ||
            listingMedia?.[0]?.url ||
            listingMedia?.[0]?.baseUrl,
          distance_cc: listingDetail?.distance_to_cc || "",
          review_score: review_score,
          review_word: badge,
          total_review: review_nr,
          description: priceInfoContent,
          propertyType: listingKey,
          class: details?.properties?.class,
          lat: details?.properties?.latitude,
          lng: details?.properties?.longitude,
          url: details?.properties?.listing?.webURL || listingDetail?.url || "",
          country: details?.properties?.country,
          checkin_date:
            details?.properties?.checkinDate || monthlyStay?.startDate,
          checkout_date:
            details?.properties?.checkoutDate || monthlyStay?.endDate,
          latlng: [latitude, longitude],
        },
      })
    );
  };

  const chipClrs = [
    "#a1d4f2",
    "#f54291",
    "#5bc41b",
    "#ff7d45",
    "#7635ff",
    "#1f8c9a",
    "#e3f716",
    "#ad3f21",
    "#5e87a6",
    "#d450f3",
  ];
  return (
    <React.Fragment>
      <div
        className={` mx-auto border rounded-lg shadow-lg overflow-hidden z-[200] flex absolute ${
          isMobile
            ? "bottom-0 h-[60vh] overflow-y-auto w-full"
            : "bottom-3 max-w-[50%]"
        } bg-[#fff]`}
      >
        {/* Image Section */}
        <div className=" w-56 h-56">
          <img
            src={
              listingMedia?.[0]?.url_1440 ||
              listingMedia?.[0]?.url ||
              listingMedia?.[0]?.baseUrl
            }
            alt="Property"
            className="w-56 h-56 object-cover cursor-pointer"
          />
        </div>

        {/* Info Section */}
        <div className="px-4 py-2">
          <div
            className="flex justify-end cursor-pointer"
            onClick={() => showDetailToggle(false)}
          >
            <Close className="text-[#000] " sx={{ fontSize: 16 }} />
          </div>
          <div className="space-y-4">
            <div className="flex items-center">
              <div className="text-[20px] text-[#2D7EE3] font-semibold">
                {listingDetail?.propertyName}
              </div>
              {propertyClass && (
                <div className="flex items-center">
                  {[...Array(propertyClass)].map((_, i) => (
                    <Star
                      key={i}
                      fontSize="small"
                      style={{ color: "#FFD700" }}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className="flex items-center justify-center">
              <LocationOn className="text-[#f00]" />
              <p className="text-sm text-[#14488D]">{listingDetail?.address}</p>
            </div>
            <div className="flex items-center justify-center gap-2">
              <div className="text-[#061B45] text-[14px] font-sans font-light">
                {priceInfoContent}
              </div>
              <div className="text-[#2D7EE3] font-sans font-bold text-[20px]">
                Total ${Math.round(listingDetail?.price)}
              </div>
            </div>

            {/* Price Section */}
            <div className="flex items-center justify-between w-full">
              <div className="text-[red] ml-2">
                <Delete />
              </div>
              <div
                onClick={toggleDetailView}
                className="bg-[#01A3FE] flex items-center text-[#fff] cursor-pointer px-4 py-1 border border-solid border-[#fff] shadow rounded-[3px] font-medium text-[12px] font-sans"
              >
                See availability
                <KeyboardDoubleArrowRight />
              </div>

              <div
                className="text-[#10A922] cursor-pointer"
                onClick={handleFavorite}
              >
                <FavoriteBorder />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MapListInfo;

import { useState } from "react";

const IdealistaImageSlider = (props) => {
  const { toggleImageView, listingMedia, isMobile } = props;
  const [selectedImage, setSelectedImage] = useState("");

  const activeImg = selectedImage ? selectedImage : listingMedia?.[0]?.url;
  return (
    <div className="w-full">
      {/* Main Image */}
      <div className="flex gap-2 w-full">
        <div
          className={
            isMobile
              ? "w-full   h-[375px] relative"
              : "w-full  h-[500px] rounded-[6px] relative"
          }
          onClick={toggleImageView}
        >
          <img
            src={activeImg}
            alt="Main Thumbnail"
            className="w-full h-full rounded-[6px] object-cover"
          />
        </div>
        {!isMobile && (
          <div className="flex flex-col gap-[8px]">
            <div
              className={
                isMobile
                  ? "w-full   h-[375px] relative"
                  : "w-[328px]  h-[246px] rounded-[7px] relative"
              }
              onClick={isMobile ? toggleImageView : () => {}}
            >
              <img
                src={
                  listingMedia?.[1]?.url_1440 ||
                  listingMedia?.[1]?.url ||
                  listingMedia?.[1]?.baseUrl
                }
                alt="Main Thumbnail"
                className="w-full h-full rounded-[6px]"
              />
            </div>
            <div
              className={
                isMobile
                  ? "w-full   h-[375px] relative"
                  : "w-[328px]  h-[246px] rounded-[7px] relative"
              }
              onClick={isMobile ? toggleImageView : () => {}}
            >
              <img
                src={
                  listingMedia?.[2]?.url_1440 ||
                  listingMedia?.[2]?.url ||
                  listingMedia?.[2]?.baseUrl
                }
                alt="Main Thumbnail"
                className="w-full h-full rounded-[6px]"
              />
            </div>
          </div>
        )}
      </div>
      {!isMobile && (
        <div className={"w-full  h-full mt-2"}>
          <div className="w-full h-full flex gap-[6px] overflow-y-auto hide-scrollbar">
            {listingMedia?.length > 0 &&
              listingMedia.map((image, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedImage(
                      image?.url_1440 || image?.url || image?.baseUrl
                    );
                  }}
                  className="cursor-pointer  rounded-[4px]"
                >
                  <img
                    src={image?.url_1440 || image?.url || image?.baseUrl}
                    alt={`Thumbnail ${index + 1}`}
                    className="w-[120px] h-[90px]  object-cover rounded-lg"
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default IdealistaImageSlider;

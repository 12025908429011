import React, { useState } from "react";
import ImageSlider from "../../components/Listing/ImageSlider";
import HotelInfo from "../../components/Listing/HotelInfo";
import AirbnbInfo from "../../components/Listing/AirbnbInfo";
import IdealistaInfo from "../../components/Listing/IdealistaInfo";
import IdealistaImageSlider from "../../components/Listing/IdealistaImageSlider";
import { InsertPhoto, LocationOn, SmartDisplay } from "@mui/icons-material";
import StarView from "../Area/StarView/StarView";
import { useSelector } from "react-redux";
import VideoSlider from "../../components/Listing/VideoSlider";

const ListingOverview = ({
  property,
  majorLocation,
  myLocation,
  listingDetail,
  listingMedia,
  isFullScreen,
  isMobile,
  toggleImageView,
  toggleTypeSwitch,
  activeType,
}) => {
  const listingMediaVideo = useSelector(
    (state) => state.hotels.listingMediaVideo
  );

  return (
    <div className={`flex ${isMobile && "justify-center items-center w-full"}`}>
      <div className={` ${isMobile ? "w-full" : "w-full max-w-full"}`}>
        <div
          className={`relative w-full ${isMobile ? "h-[370px]" : "h-[60vh]"}`}
        >
          {activeType === "photo" &&
            (["rent", "buy"].includes(
              property?.properties?.listingKey ||
                property?.properties?.accommodationType
            ) ? (
              <IdealistaImageSlider
                listingMedia={listingMedia}
                toggleTypeSwitch={toggleTypeSwitch}
                isFullscreen={isFullScreen}
                activeType={activeType}
                isMobile={isMobile}
                toggleImageView={toggleImageView}
              />
            ) : (
              <ImageSlider
                listingMedia={listingMedia}
                toggleTypeSwitch={toggleTypeSwitch}
                isFullscreen={isFullScreen}
                activeType={activeType}
                isMobile={isMobile}
                toggleImageView={toggleImageView}
              />
            ))}
          {activeType === "video" && (
            <VideoSlider
              listingMedia={listingMediaVideo}
              toggleTypeSwitch={toggleTypeSwitch}
              isFullscreen={isFullScreen}
            />
          )}
          {activeType === "map" && (
            <div className="w-full h-full">
              <StarView />
            </div>
          )}
          <div
            className={`w-full absolute  flex ${
              isMobile ? "justify-center bottom-2" : "left-2 top-2"
            } gap-2`}
          >
            {listingMediaVideo?.length > 0 && (
              <div
                onClick={() => toggleTypeSwitch("video")}
                className={`cursor-pointer w-[119px] h-[33px] rounded-[10px] text-[18px]border border-solid font-medium font-sans ${
                  activeType === "video"
                    ? "border-[#fff] bg-[#1560D0] text-[#fff]"
                    : "border-[#fff] bg-[#fff] text-[#1560D0]"
                } flex gap-2 justify-center items-center`}
              >
                <SmartDisplay />
                Video
              </div>
            )}
            <div
              onClick={() => toggleTypeSwitch("photo")}
              className={`cursor-pointer w-[99px] h-[33px] rounded-[10px] text-[18px]border border-solid ${
                activeType === "photo"
                  ? "border-[#fff] bg-[#1560D0] text-[#fff]"
                  : "border-[#fff] bg-[#fff] text-[#1560D0]"
              }  font-medium font-sans flex gap-2 justify-center items-center`}
            >
              <InsertPhoto />
              Photos
            </div>
            <div
              onClick={() => toggleTypeSwitch("map")}
              className={`cursor-pointer w-[99px] h-[33px] rounded-[10px] text-[18px]border border-solid font-medium font-sans ${
                activeType === "map"
                  ? "border-[#fff] bg-[#1560D0] text-[#fff]"
                  : "border-[#fff] bg-[#fff] text-[#1560D0]"
              }  flex gap-2 justify-center items-center`}
            >
              <LocationOn />
              Map
            </div>
          </div>
        </div>
        {["rent", "buy"].includes(
          property?.properties?.listingKey ||
            property?.properties?.accommodationType
        ) ? (
          <IdealistaInfo
            property={property}
            majorLocation={majorLocation}
            myLocation={myLocation}
            listingDetail={listingDetail}
            listingMedia={listingMedia}
            isMobile={isMobile}
          />
        ) : property?.properties?.listingKey === "monthlyStay" ||
          property?.properties?.accommodationType === "monthlyStay" ? (
          <AirbnbInfo
            property={property}
            majorLocation={majorLocation}
            myLocation={myLocation}
            listingDetail={listingDetail}
            listingMedia={listingMedia}
            isMobile={isMobile}
          />
        ) : (
          <HotelInfo
            property={property}
            majorLocation={majorLocation}
            myLocation={myLocation}
            listingDetail={listingDetail}
            listingMedia={listingMedia}
            isMobile={isMobile}
          />
        )}
      </div>
    </div>
  );
};

export default ListingOverview;

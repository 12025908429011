import React from "react";
import { Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomDrawer = ({
  isShowCloseIcon = false,
  isMobile = false,
  open, // Controls whether the drawer is open
  onClose, // Function to handle drawer close
  title, // Title displayed at the top of the drawer
  children, // Content inside the drawer
  anchor = "right", // Drawer position: 'left', 'right', 'top', 'bottom'
}) => {
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose} // Ensures the drawer closes when clicking outside
      classes={{
        paper: `${isMobile ? "w-full" : "w-[410px] "} bg-white`, // Tailwind classes for width and background
      }}
      ModalProps={{
        keepMounted: true, // Improves performance by not unmounting the component
      }}
    >
      <div className="h-full flex flex-col">
        {/* Drawer Header */}
        {(title || isShowCloseIcon) && (
          <div className="flex justify-between items-center border-b pb-2 mb-4">
            {title && <h2 className="text-lg font-bold">{title}</h2>}
            {isShowCloseIcon && (
              <IconButton onClick={onClose} size="small">
                <CloseIcon />
              </IconButton>
            )}
          </div>
        )}

        {/* Drawer Content */}
        <div className="overflow-y-auto flex-grow">{children}</div>
      </div>
    </Drawer>
  );
};

export default CustomDrawer;
